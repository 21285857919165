/* eslint-disable react-hooks/exhaustive-deps */
// styles
import './userSettingsComponent.css';
// react states
import { useEffect, useState } from 'react';
// react router dom
import { useLocation } from 'react-router-dom';
// custom hooks
import { useAuthContext } from '../../Hooks/useAuthContext';
import { useEditUserProfile } from '../../Hooks/useEditUserProfile';
import { useCheckUsernameAvailibility } from '../../Hooks/useCheckUsernameAvailibility';
// import UsernameCreator from '../AuthenticationComponents/UsernameCreationComponent/usernameCreator';

// mui icons
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { CircularProgress } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

import Compressor from 'compressorjs';

export default function UserSettings(){

    
    const userLocation = useLocation();
    const { loggedInUserID } = userLocation.state;
    const { userValues } = useAuthContext();
    // To eventuaslly save the made changes by the user.
    const { editProgress, editUserName,
            profilePictureProgress, editUserProfilePicture, profilePictureStatus,
            editUserCoverImage, coverPictureStatus  
        } = useEditUserProfile();

    const [profilePicture, setProfilePicture] = useState(null);
    var [username, setUsername] = useState({
        'text':'',
        'status':'not-ready'
    });
    const [coverImage, setCoverImage] = useState(null);

    useEffect(()=>{
        setProfilePicture({
            'picture':userValues.profile_picture,
            'location':'web'
        })

        setUsername({
            'text':userValues.Name,
            'status':'no-change'
        })

        setCoverImage({
            'picture':userValues.user_cover_image,
            'location':'web'
        })
    },[userValues])

    // console.log('loadingUser => ',loadingUser);
    // console.log('user => ',userValues);
    // console.log('coverImage => ',coverImage);

    const handleProfilePictureChange = (e)=>{
        e.preventDefault();
        if(e.target.files.length !== 0){
            const selectedProfileImage = e.target.files[0];
            new Compressor(selectedProfileImage, {
                quality: 0.5, // 0.6 can also be used, but its not recommended to go below.
                success: (compressedProfileResult) => {
                  // compressedResult has the compressed file.
                  // Use the compressed file to upload the images to your server.        
                //   setSelectedCoverImage(compressedResult)
                    setProfilePicture({
                        'picture' : compressedProfileResult,
                        'location' : 'desktop'
                    });
                }}
            )
            // setProfilePicture({
            //     'picture' : e.target.files[0],
            //     'location' : 'desktop'
            // });
        }
    }

    const handleUsernameChange = (e)=>{
        e.preventDefault();
        setUsername({
            'text' : e.target.value,
            'status' : 'changed'
        });
    }

    useEffect(()=>{
        checkAvailibility(username.text);
    },[username])

    const handleCoverImageChange = (e)=>{
        e.preventDefault();
        if(e.target.files.length !== 0){
            const selectedCoverImage = e.target.files[0];
            new Compressor(selectedCoverImage, {
                quality: 0.6, // 0.6 can also be used, but its not recommended to go below.
                success: (compressedResult) => {
                  // compressedResult has the compressed file.
                  // Use the compressed file to upload the images to your server.        
                //   setSelectedCoverImage(compressedResult)
                    setCoverImage({
                        'picture' : compressedResult,
                        'location' : 'desktop'
                    });
                }}
            )
        }
    }

    // utility function to check username availibility
    const { isAvailable, checking, checkAvailibility } = useCheckUsernameAvailibility();

    const saveChanges = ()=>{
        if(username.status === 'changed'){
            editUserName(loggedInUserID.id, username.text);
        }

        if(profilePicture.location === 'desktop'){
            // console.log('loggedInUserID => ',loggedInUserID);
            editUserProfilePicture(loggedInUserID.id, profilePicture.picture);
            // console.log(profilePicture.picture.name.split('.').pop().toLowerCase());
        }

        if(coverImage.location === 'desktop'){
            editUserCoverImage(loggedInUserID.id, coverImage.picture);
        }
    }

    // console.log('Error during update = ',error);

    // For update feedbacks
    const [openProfileSnackbar, setOpenProfileSnackbar] = useState(false);
    const handleProfileSnackbarOpen = ()=>{
        setOpenProfileSnackbar(true);
    }
    const handleProfileSnackbarClose = ()=>{
        setOpenProfileSnackbar(false);
    }
    useEffect(()=>{
        handleProfileSnackbarOpen();
    },[profilePictureStatus]);

    const [openCoverImageSnackbar, setOpenCoverImageSnackbar] = useState(false);
    const handleCoverImageSnackbarOpen = ()=>{
        setOpenCoverImageSnackbar(true);
    }
    const handleCoverImageSnackbarClose = ()=>{
        setOpenCoverImageSnackbar(false);
    }
    useEffect(()=>{
        handleCoverImageSnackbarOpen();
    },[coverPictureStatus]);

    // To open username creator
    // const [openUsernameCreator, setOpenUsernameCreator] = useState(false);
    // const handleOpenFromSettingUsernameCreator = ()=>{
    //     setOpenUsernameCreator(true);
    // }
    // const handleCloseFromSettingUsernameCreator = ()=>{
    //     setOpenUsernameCreator(false);
    // }

    return(
        <div className='user-settings-container'>
            {/* <Dialog
                open={openUsernameCreator}
                maxWidth={'md'}
                fullWidth={true}
            >
                <UsernameCreator closeDialogPrompt={handleCloseFromSettingUsernameCreator} 
                                 userNamePromptFromSettings={setUsername}
                                 fromPage={'user_settings'}
                />
            </Dialog> */}
            {
                profilePictureStatus === 'success' && 
                <Snackbar open={openProfileSnackbar} autoHideDuration={5000} onClose={handleProfileSnackbarClose}>
                    <Alert onClose={handleProfileSnackbarClose} severity="success" sx={{ width: '100%' }}>
                        Profile picture updated
                    </Alert>
                </Snackbar>
            }
            {
                profilePictureStatus === 'fail' && 
                <Snackbar open={openProfileSnackbar} autoHideDuration={5000} onClose={handleProfileSnackbarClose}>
                    <Alert onClose={handleProfileSnackbarClose} severity="error" sx={{ width: '100%' }}>
                        Profile picture cannot be updated
                    </Alert>
                </Snackbar>
            }
            {
                coverPictureStatus === 'success' && 
                <Snackbar open={openCoverImageSnackbar} autoHideDuration={5000} onClose={handleCoverImageSnackbarClose}>
                    <Alert onClose={handleCoverImageSnackbarClose} severity="success" sx={{ width: '100%' }}>
                        Cover image updated
                    </Alert>
                </Snackbar>
            }
            {
                coverPictureStatus === 'fail' && 
                <Snackbar open={openCoverImageSnackbar} autoHideDuration={5000} onClose={handleCoverImageSnackbarClose}>
                    <Alert onClose={handleCoverImageSnackbarClose} severity="error" sx={{ width: '100%' }}>
                        Cover image could not be updated
                    </Alert>
                </Snackbar>
            }
            <div className="save-changes-cta-container">
                <div className="primary-button"
                     onClick={()=>saveChanges()}
                     style={{ 'pointerEvents' : editProgress===true ? 'none' : 'all' }}
                >
                    { editProgress === true ? `Saving...` : `Save`}
                </div>
            </div>
            <div className="user-profile-picture-settings-container">
                <h4>Profile picture</h4>
                <div className="profile-picture-box">
                    {
                        (profilePictureProgress === null || profilePictureProgress === false)
                        && 
                        <div className="profile-picture-container">
                        {
                            (profilePicture!==null && profilePicture.picture!==null) && 
                                <img src={(profilePicture.location === 'web') ? profilePicture.picture
                                            : window.URL.createObjectURL(profilePicture.picture)
                                        } alt=""/>
                        }
                        {
                            (profilePicture!==null && profilePicture.picture===null) && <p>No profile picture</p>
                        }
                        </div>
                    }
                    {
                        profilePictureProgress === true && 
                        <div className="profile-picture-container">
                            <CircularProgress size={24} style={{ 'color':'#FFE74C' }}/>
                        </div>
                    }
                    <div className="edit-picture-icon-container">
                        <label htmlFor='profile-picture-input-id'>
                            <EditRoundedIcon style={{ 'color':'#f8f8f8' }}/>
                        </label>
                        <input type='file'
                               accept='image/png, image/jpg, image/jpeg'
                               id='profile-picture-input-id'
                               onChange={(e)=>handleProfilePictureChange(e)}
                               disabled={editProgress === true ? true : false}       
                        />
                    </div>
                </div>
            </div>
            <div className="user-text-credential-container">
            {
                username.status !== 'not-ready' && 
                <>
                <h4>Username</h4>
                <div className="username-input-container">
                    <input type="text"
                        className='username-input-text'
                        defaultValue={username.text}
                        value={username.text}
                        onChange={(e)=>handleUsernameChange(e)}
                        disabled={editProgress === true ? true : false}
                    />
                    {/* {
                        usernameProgress === true && 
                            <CheckCircleIcon
                                style={{ 'fontSize':'24px', 'color':'green'}}
                            />
                    } */}
                    {
                        checking === true && <CircularProgress size={24} style={{ color:'#FFE74C' }}/>
                    }
                    {
                        isAvailable === true && 
                            <CheckCircleIcon style={{ 'fontSize':'24px', 'color':'green'}}/>
                    }
                    {
                        isAvailable === null && <></>
                    }
                    {
                        isAvailable === false && 
                            <p style={{ 'fontSize':'16px', 'color':'red'}}>Not available</p>
                    }
                </div>
                {/* <div className="check-settings-username-availibility-container"> */}
                    {/* <p className='username-settings-availibility-button'
                        onClick={()=>handleOpenFromSettingUsernameCreator()}
                    >
                        Try our senseless username creator!
                    </p> */}
                {/* </div> */}
                </>
            }
            </div>
            <div className="change-cover-image-container">
                <div className="change-cover-image-container-heading">
                    <h4>Cover image</h4>
                    {/* <div className="change-cover-image-edit-container"> */}
                        <label className='change-cover-image-edit-container'
                               htmlFor='cover-image-edit-id'
                        >
                            Edit
                            <EditRoundedIcon fontSize='14px'/>
                        </label>
                    {/* </div> */}
                    <input type="file"
                           accept='image/png, image/jpg, image/jpeg'
                           id='cover-image-edit-id'
                           disabled={editProgress === true ? true : false}
                           onChange={(e)=>handleCoverImageChange(e)}
                    />
                </div>
                {
                    coverImage !== null && coverImage.picture === null && 
                    <div className="no-cover-image-prompt">
                        You haven't set any cover image. Hurry up, do it now.
                    </div>
                }
                {
                    coverImage !== null && coverImage.picture !== null &&
                    <>
                    <div className="cover-image-preview-container">
                    {
                            (coverImage!==null) && 
                                <img src={(coverImage.location === 'web') ? coverImage.picture
                                        : window.URL.createObjectURL(coverImage.picture)
                                        } alt=""/>
                    }
                    <div className="cover-image-overlay">
                    </div>
                    <div className="preview-components-container">
                        <div className="preview-component-nav-container">
                            <div className="preview-component-profile"></div>
                            <div className="preview-component-nav-options">
                                <div className="preview-nav-option"></div>
                                <div className="preview-nav-option"></div>
                                <div className="preview-nav-option"></div>
                                <div className="preview-nav-option"></div>
                                <div className="preview-nav-option"></div>
                            </div>
                        </div>
                        <div className="preview-component-items-container">
                            <div className="preview-item-tile"></div>
                            <div className="preview-item-tile"></div>
                            <div className="preview-item-tile"></div>
                            <div className="preview-item-tile"></div>
                            <div className="preview-item-tile"></div>
                            <div className="preview-item-tile"></div>
                            <div className="preview-item-tile"></div>
                            <div className="preview-item-tile"></div>
                            <div className="preview-item-tile"></div>
                            <div className="preview-item-tile"></div>
                        </div>
                    </div>
                    </div>
                    </>
                }
            </div>
        </div>
    )
}