/* eslint-disable react-hooks/exhaustive-deps */
// styles
import './createdComments.css';
// react
import { useEffect } from 'react';
// react redux and slice(s)
// import { useSelector } from 'react-redux';
// import { selectUser } from '../../../Features/userSlice';
// custom hooks
// import { useFetchUserDiscussions } from '../../../Hooks/Forums/useFetchUserDiscussions';
// for generating random key ids
// import { v4 as uuid } from 'uuid';
// custom components
// import Comment from '../../Forums/Discussion/comment';
// import UserComment from './userComment';
// mui component
// import { CircularProgress } from '@mui/material';

// import { selectUserData } from '../../../Features/userPrivateDataSlice';
import { useGetUserProfile } from '../../../Hooks/useGetUserProfile';
import { useCommentsFetch } from '../../../Hooks/Forums/ForumComments/useCommentsFetch';
import ForumComment from '../../DiscussionAreaComponents/forumComment';
import CreatedCommentForumDetails from './createdCommentForum';
import { CircularProgress } from '@mui/material';
import { Helmet } from 'react-helmet';

export default function UserCreatedComments({ userid, signedInUser }){

    // const signedInUserData = useSelector(selectUser);
    // console.log('signed in user => ',signedInUser);

    // const signedInUserDataValues = useSelector(selectUserData);

    // const { isLoadingComments, getUserComments, userCreatedComments,
    //     setUserCreatedComments,fetchMoreUserComments,
    //     lastVisibleComment    
    // } = useFetchUserDiscussions();

    // fetch the profile users comments
    const { fetchingComments, fetchUserCommentsFirstBatch, fetchUserCommentsNextBatch, isUserCommentsEmpty, fetchedUserComments } = useCommentsFetch('forum');
    const { getProfileDetails, userData } = useGetUserProfile();

    useEffect(()=>{
        if(userid !== null){
            getProfileDetails(userid);
        }
    },[userid])
    // console.log('userData=> ',userData);

    useEffect(()=>{
        if(userid !== null){
            // getUserComments(userid);
            fetchUserCommentsFirstBatch(userid);
        }
    },[]);

    // console.log('User created comments are => ', fetchedUserComments);

    // const loadMoreComments = ()=>{
    //     fetchMoreUserComments(userid);
    // }

    return(
        <div className='user-created-comments'>
        {
            userData !== null &&
            <Helmet>
                <title>{`${userData.Name} - Forum Comments`}</title>
                <meta name='description' content={`${userData.Name} created forum comments or discussions comments and replies`}/>
                <meta name='keywords' content={`${userData.Name}, ${userData.Name} forums, ${userData.Name} discussions, ${userData.Name} games discussions, game, discussion, forum, ${userData.Name} forum comments, ${userData.Name} discussion comments, ${userData.Name} comments replies, ${userData.Name} games discussions replies, game comments, game replies, discussion comments, discussion replies, forum comments, forum replies`}/>
            </Helmet>
        }
        {
            fetchedUserComments && fetchedUserComments.length > 0
            && fetchedUserComments.map((userCommentObject)=>(
                <div className="user-comment-box-container" key={userCommentObject.id}>
                    <div className='user-comment-box-extra'>
                        <p className='user-comment-box-txt'>commented on </p>
                        <CreatedCommentForumDetails discID={userCommentObject.forumID}/>
                    </div>
                    <ForumComment commentObject={userCommentObject} section={'forum'}/>
                </div>
            ))
        }
        {
            fetchedUserComments && fetchedUserComments.length === 0
            && <div className="no-comment-prompt">
                <p>{
                    signedInUser === userid &&
                    `You haven't interacted with any discussions yet. Start doing it now. Head to the Forums sections from the navbar.` 
                }{
                    signedInUser !== userid &&
                    userData && `${userData.Name} has not started interacting yet.`
                }</p>
            </div>
        }
            <div className="user-comments-section-action-bar">
            {
                !isUserCommentsEmpty && !fetchingComments &&
                <button className='user-comments-cta-btn'
                        onClick={()=>fetchUserCommentsNextBatch(userid)}
                >
                    Load more
                </button>
            }
            {
                !isUserCommentsEmpty && fetchingComments &&
                <CircularProgress size={18} style={{ color:'#FFE74C' }}/>
            }
            </div>
        </div>
    )
}