// react states
import { useEffect, useState } from "react"
// firebase
import { db } from '../../Firebase/firebase';
import { collection, query, orderBy, limit, getDocs, where, startAfter } from "firebase/firestore";

export const useFetchAllDiscussions = ()=>{
    const [fetching, setFetching] = useState(false);
    const [error, setError] = useState(null);
    const [discussions, setDiscussions] = useState([]);

    // for firebase working
    const [lastVisibleSnapshot, setLastVisibleSnapshot] = useState(null);

    const fetchAllDiscussions = async()=>{
        setFetching(true);
        const firstBatch = query(collection(db, "Forums"), where("approved", "==", true), orderBy("createdAt"), limit(10));
        const discussionSnapshots = await getDocs(firstBatch)
        .catch((err)=>{
            setError(err.message);
            setFetching(false);
        });

        if(!discussionSnapshots.empty){
            const lastVisible = discussionSnapshots.docs[discussionSnapshots.docs.length-1];
            setLastVisibleSnapshot(lastVisible);

            var tempDiscussionArr = [];
            for(const disc of discussionSnapshots.docs){
                var discObj = {
                    'id':disc.id,
                    ...disc.data()
                }

                tempDiscussionArr.push(discObj);
            }

            setDiscussions(tempDiscussionArr);
            setError(null);
            setFetching(false);
        }else{
            setError(null);
            setFetching(false);
        }
    }

    useEffect(()=>{
        fetchAllDiscussions();
    },[]);

    const fetchNextBatchDiscussions = async()=>{
        setFetching(true);
        setError(null);
        const nextBatchDiscussion = query(collection(db, "Forums"),
                    where("approved", "==", true),
                    orderBy("createdAt"),
                    startAfter(lastVisibleSnapshot),
                    limit(10));
        const nextBatchDiscussionSnapshots = await getDocs(nextBatchDiscussion)
        .catch((err)=>{
            setError(err.message);
            setFetching(false);
        });

        if(!nextBatchDiscussionSnapshots.empty){
            const lastVisible = nextBatchDiscussionSnapshots.docs[nextBatchDiscussionSnapshots.docs.length-1];
            setLastVisibleSnapshot(lastVisible);

            var tempDiscussionArr = [];
            for(const disc of nextBatchDiscussionSnapshots.docs){
                var discObj = {
                    'id':disc.id,
                    ...disc.data()
                }

                tempDiscussionArr.push(discObj);
            }

            setDiscussions(discussions => [...discussions, ...tempDiscussionArr]);
            setError(null);
            setFetching(false);
        }else{
            setError(null);
            setFetching(false);
        }
    }

    return { fetching, error, discussions, lastVisibleSnapshot, fetchNextBatchDiscussions };
}