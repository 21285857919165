/* eslint-disable react-hooks/exhaustive-deps */
// react states
import { useState, useEffect } from 'react';
// database
import { collection, query, getDocs, limit, where } from 'firebase/firestore';
import { db } from '../../Firebase/firebase';

export const useGetConsoleGames = (acronym)=>{
    const [consoleGames, setConsoleGames] = useState([]);
    const [loadingConsoleGames, setLoadingConsoleGames] = useState(false);
    const [errorLoadingConsoleGames, setErrorLoadingConsoleGames] = useState(null);

    const consoleGameKey = `${acronym}Games`;

    const getConsoleGames = async()=>{
        const consoleGamesData = sessionStorage.getItem(consoleGameKey);
        if(consoleGamesData){
            setConsoleGames(JSON.parse(consoleGamesData));
        }else{
            setLoadingConsoleGames(true);
            setErrorLoadingConsoleGames(null);

            const consoleGameQuery = query(collection(db, 'Games')
                                    ,where('platforms','array-contains',`${acronym}`),
                                limit(25));
            const consoleGameSnapshot = await getDocs(consoleGameQuery)
            .catch((err)=>{
                setErrorLoadingConsoleGames(err.message);
                setLoadingConsoleGames(false);
                return;
            });

            const tempConsoleGameArr = [];
            for(const console_game of consoleGameSnapshot.docs){
                const consoleGameObj = {
                    'id': console_game.id,
                    ...console_game.data()
                }

                tempConsoleGameArr.push(consoleGameObj);
            }

            if(tempConsoleGameArr.length > 0){
                sessionStorage.setItem(consoleGameKey, JSON.stringify(tempConsoleGameArr));
                setConsoleGames(tempConsoleGameArr);
            }

            setLoadingConsoleGames(false);
            setErrorLoadingConsoleGames(null);
        }
    }

    useEffect(()=>{
        if(acronym !== undefined && acronym !== null){
            getConsoleGames();
        }
    },[acronym])



    return { consoleGames, loadingConsoleGames, errorLoadingConsoleGames };
}