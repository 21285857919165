import { db, fbFunctions, messaging, projectMessagingKey } from './firebase.js';
import { getDoc, doc, setDoc, updateDoc, arrayUnion, collection, addDoc, serverTimestamp } from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';

// messaging and for storing tokens in database
import { getToken } from 'firebase/messaging';

// logo
import companyIcon from '../assets/Logo/TGB-LOGO.png';

const getNotificationUsersDetails = async(userID)=>{
    // console.log('getNotificationUserDetails function is called');
    const userRef = doc(db, "Users", `${userID}`);
    const userSnap = await getDoc(userRef);

    if(userSnap.exists()){
        return {
            'id':userID,
            ...userSnap.data()
        };
    }else{
        return null;
    }
}

export const sendNotifications = async(senderID, receiverID, type, 
                                    relevantURL, action, actionOn, docID)=>{
            // senderID - Who did the action
            // receiveID - To whom the notification will be sent
            // type - whether it's a forum or a review
            // relevantURL - to redirect user to the exact place of action
            // action - what was sender's action
            // docID - Particular id of the review or discussion.

            // First and foremost get the receiver tokens
            const receiverTokenRef = doc(db ,'Tokens',`${receiverID}`);
            const receiverTokenSnap = await getDoc(receiverTokenRef);

            // If token's doesn't exist, exit from sending notification
            if(!receiverTokenSnap.exists()){
                // console.log("Didn't found any tokens");
                return;
            }

            const senderDetails = await getNotificationUsersDetails(senderID);
            // const receiverDetails = await getNotificationUsersDetails(receiverID);

            // console.log('senderDetails => ',senderDetails);
            // console.log('receiverDetails => ',receiverDetails);

            var message = {};

            if(type === 'discussion'){

                var titleText = '';
                var bodyText = '';

                if(action === 'comment'){
                    titleText = `${senderDetails.Name} commented on your ${actionOn}`;
                    bodyText = `${senderDetails.Name} commented on your ${actionOn}.`;
                }else if(action === 'like'){
                    titleText = `${senderDetails.Name} liked your ${actionOn}`;
                    bodyText = `${senderDetails.Name} liked your ${actionOn}.`;
                }else if(action === 'upvote'){
                    titleText = `${senderDetails.Name} upvoted your discussion`;
                    bodyText = `${senderDetails.Name} upvoted your discussion.`;
                }

                message = {
                    notification:{
                        title: titleText,
                        body: bodyText,
                        image: companyIcon
                    },
                    data:{
                        "click_action": relevantURL
                    },
                    webpush: {
                        fcm_options: {
                            link: relevantURL
                        }
                    },
                    tokens : receiverTokenSnap.data().tokens
                }
            }else if(type === 'review'){
                var titleRevText = '';
                var bodyRevText = '';

                if(action === 'comment'){
                    titleRevText = `${senderDetails.Name} commented on your ${actionOn}`;
                    bodyRevText = `${senderDetails.Name} commented on your ${actionOn}.`;
                }else if(action === 'like'){
                    titleRevText = `${senderDetails.Name} liked your ${actionOn}`;
                    bodyRevText = `${senderDetails.Name} liked your ${actionOn}.`;
                }

                message = {
                    notification:{
                        title: titleRevText,
                        body: bodyRevText,
                        image: companyIcon
                    },
                    data:{
                        "click_action": relevantURL
                    },
                    webpush: {
                        fcm_options: {
                            link: relevantURL
                        }
                    },
                    tokens : receiverTokenSnap.data().tokens
                }
            }else if(type === 'game_collection'){
                var titleGameCollText = '';
                var bodyGameCollText = '';

                if(action === 'comment'){
                    titleGameCollText = `${senderDetails.Name} commented on your ${actionOn}`;
                    bodyGameCollText = `${senderDetails.Name} commented on your ${actionOn}.`;
                }else if(action === 'like'){
                    titleGameCollText = `${senderDetails.Name} liked your ${actionOn}`;
                    bodyGameCollText = `${senderDetails.Name} liked your ${actionOn}.`;
                }

                message = {
                    notification:{
                        title: titleGameCollText,
                        body: bodyGameCollText,
                        image: companyIcon
                    },
                    data:{
                        "click_action": relevantURL
                    },
                    webpush: {
                        fcm_options: {
                            link: relevantURL
                        }
                    },
                    tokens : receiverTokenSnap.data().tokens
                }
            }

            const sendNotificationToUserDevices = httpsCallable(fbFunctions, 'sendNotificationToUserDevices');
                sendNotificationToUserDevices({
                    'message': message,
                })
                .then((result) => {
                    console.log(result.data);
            });

            // Code to add the notification to the user notification tab.
            var notificationObject = {};
            if(type === 'discussion'){
                notificationObject = {
                    'senderID':senderID,
                    'receiverID': receiverID,
                    'action':action,
                    'actionOn':actionOn,
                    'docID':docID,
                    'relevantURL':relevantURL,
                    'createdAt':serverTimestamp(),
                    'read':false,
                    'type':'discussion'
                    // action, actionOn, gameName, discussionTopic
                }
            }else if(type === 'review'){
                notificationObject = {
                    'senderID':senderID,
                    'receiverID': receiverID,
                    'action':action,
                    'actionOn':actionOn,
                    'docID':docID,
                    'relevantURL':relevantURL,
                    'createdAt':serverTimestamp(),
                    'read':false,
                    'type':'review'
                    // action, actionOn, gameName, discussionTopic
                }
            }else if(type === 'game_collection'){
                notificationObject = {
                    'senderID':senderID,
                    'receiverID': receiverID,
                    'action':action,
                    'actionOn':actionOn,
                    'docID':docID,
                    'relevantURL':relevantURL,
                    'createdAt':serverTimestamp(),
                    'read':false,
                    'type':'game_collection'
                    // action, actionOn, gameName, discussionTopic
                }
            }

            const notificationRef = collection(db,`Users/${receiverID}/Notifications`);
            await addDoc(notificationRef, notificationObject).catch((notification_err)=>{
                console.log(notification_err);
                return;
            });

}

export const initializeMessaging = ( user )=>{
    if(!("Notification" in window)){
        return;
    }
    else{
        Notification.requestPermission().then(async(permission)=>{
            if(permission === 'granted'){
                await getToken(messaging, {
                    vapidKey: projectMessagingKey
                }).then(async (currentToken)=>{
                    if(currentToken){
                        const userTokenRef = doc(db, "Tokens", `${user.uid}`);
                        const userTokenSnap = await getDoc(userTokenRef);
                        // console.log('Generated token => ',currentToken);
                        if(userTokenSnap.exists()){
                            await updateDoc(doc(db, 'Tokens', `${user.uid}`),{
                                tokens:arrayUnion(currentToken)
                            })
                        }else{
                        // console.log('Token not made for user, makig a new one.');
                            var tempTokenArray = [];
                            tempTokenArray.push(currentToken);
                            setDoc(doc(db,'Tokens',`${user.uid}`),{
                                'tokens': tempTokenArray
                            });
                        }
                    }
                })
            }else{
                console.log('permission denied');
            }
        })
    }
}