// styles
import './TGBtextInput.css';
// react states
import { useRef } from 'react';
// custom functionality
import useIncreaseHeightDynamically from './TextAreaUtility/useIncreaseHeightDynamically';

export default function TGBTextInput({ placeholderText, textInput, setTextInput }){

    const formTextAreaRef = useRef(null);

    useIncreaseHeightDynamically(formTextAreaRef.current, textInput);

    const handleTextInput = (textEvent) => {
        const val = textEvent.target.value;
        setTextInput(val);
    };
    
    return(
        <textarea 
            className='form-input-textarea'
            ref={formTextAreaRef}
            placeholder={placeholderText}
            value={textInput}
            rows={1}
            onChange={(e)=>handleTextInput(e)}
        ></textarea>
    )
}