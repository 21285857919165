/* eslint-disable react-hooks/exhaustive-deps */
// react states
import { useState, useEffect } from 'react';
// firebase
import { db } from '../../Firebase/firebase';
import { getDoc, doc } from 'firebase/firestore';

export const useGetGenre = ( genreID )=>{
    const [loadingGenre, setLoadingGenre] = useState(false);
    const [genreError, setGenreError] = useState(null);
    const [genreData, setGenreData] = useState(null);
    const [multipleGenreData, setMultipleGenreData] = useState([]);

    var genreKey = `${genreID}`;

    const getGenreData = async(genre_id) => {
        genreKey = genre_id;

        const genreCachedData = sessionStorage.getItem(genreKey);

        if(genreCachedData){
            setGenreData(JSON.parse(genreCachedData));
            return JSON.parse(genreCachedData);
        }else{
            setLoadingGenre(true);

            const genreRef = doc(db, "Genres", `${genre_id}`);
            const genreSnap = await getDoc(genreRef);

            if(genreSnap.exists()){
                const genreObject = {
                    'id':genreSnap.id,
                    ...genreSnap.data()
                }
                setGenreData(genreObject);
                sessionStorage.setItem(genreKey, JSON.stringify(genreObject));
                setLoadingGenre(false);
                return genreObject;
            }else{
                setLoadingGenre(false);
                setGenreError('We are working on it. No data found for this genre.');
                return;
            }
        }
    }

    const getMultipleGenreData = async(genreArr)=>{
        const genreDataArr = [];
        for(const g of genreArr){
            const data = await getGenreData(g);
            if(data){
                // console.log(data);
                genreDataArr.push(data);
            }
        }

        setMultipleGenreData(genreDataArr);
    }

    useEffect(()=>{
        if(genreID !== null && genreID !== undefined){
            getGenreData(genreID);
        }
    },[genreID]);

    return { loadingGenre, genreError, genreData, multipleGenreData, 
        getGenreData, getMultipleGenreData };
}