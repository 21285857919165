// styles
import './profileComponentLoader.css';

export default function ProfileComponentLoader(){
    return(
        <div className="all-games-loader-container">
            <div className="preview-loader"></div>
            <div className="preview-loader"></div>
            <div className="preview-loader"></div>
            <div className="preview-loader"></div>
            <div className="preview-loader"></div>
            <div className="preview-loader"></div>
            <div className="preview-loader"></div>
            <div className="preview-loader"></div>
        </div>
    )
}