// react states
import { useState, useEffect } from 'react';
// firebase
import { db } from '../Firebase/firebase';
import { collection, query, orderBy, limit, getDocs, where,
         doc, getDoc, startAfter
       } from "firebase/firestore";

export const useGetProfileUserReviews = (userID)=>{
    const [loadingProfileUserReviews, setLoadingProfileUserReviews] = useState(false);
    const [profileUserReview, setProfileUserReviews] = useState(null);
    const [lastVisibleReview, setLastVisibleReview] = useState(null);
    const [endPoint, setEndPoint] = useState(false);

    useEffect(()=>{
        const getFirstBatchReviews = async(user)=>{
            // console.log('Users profile from review extraction => ',user);
            if(user === null){
                return;
            }
            setLoadingProfileUserReviews(true);
            const firstBatch = query(collection(db, "GameReviews"), where('userID','==',`${user}`),
                                     orderBy('date','desc'), limit(10));
            const profileUserReviewSnapshots = await getDocs(firstBatch);
            // console.log('Docs fetched : ',profileUserReviewSnapshots.docs.length);
            // If there are no reviews by the user
            if(profileUserReviewSnapshots.docs.length === 0){
                setEndPoint(true);
                setLoadingProfileUserReviews(false);
                return;
            }
            // Get the last visible document
            const lastVisibleReview = profileUserReviewSnapshots.docs[profileUserReviewSnapshots.docs.length-1];
            setLastVisibleReview(lastVisibleReview);

            // Now start creating the objects to add the profile review array
            let tempProfileReviewArray = [];
            for(const rev of profileUserReviewSnapshots.docs){
                // use the gameID from rev to get the game name
                const gameDocRef = doc(db, "Games", `${rev.data().gameID}`);
                const gameDocSnap = await getDoc(gameDocRef);

                var reviewObj = {
                    'gameName':gameDocSnap.data().name,
                    'reviewID':rev.id,
                    'id':rev.id,
                    ...rev.data(),
                    'date':rev.data().date.toDate().toDateString()
                };

                // add this object to the array

                tempProfileReviewArray.push(reviewObj);
            }

            // update the state array with the temp array
            if(tempProfileReviewArray.length > 0){
                setProfileUserReviews(tempProfileReviewArray);
            }

            setLoadingProfileUserReviews(false);
        }

        getFirstBatchReviews(userID);
    },[userID]);

    const getNextBatchReviews = async(user_id)=>{
        // setLoadingProfileUserReviews(true);
        const nextBatch = query(collection(db, `GameReviews`),
                    where('userID','==',`${userID}`),
                    orderBy("date","desc"),
                    startAfter(lastVisibleReview),
                    limit(10));
        const nextBatchProfileUserReviewSnapshots = await getDocs(nextBatch);
        // console.log('fetched document length => ', nextBatchProfileUserReviewSnapshots.docs.length);
        if(nextBatchProfileUserReviewSnapshots.docs.length === 0){
            setEndPoint(true);
            // setLoadingProfileUserReviews(false);
            return;
        }else if(nextBatchProfileUserReviewSnapshots.docs.length > 0){
            const lastVisibleReview = nextBatchProfileUserReviewSnapshots.docs[nextBatchProfileUserReviewSnapshots.docs.length-1];
            setLastVisibleReview(lastVisibleReview);
            let tempProfileReviewArray = [];
            for(const nextBatchRev of nextBatchProfileUserReviewSnapshots.docs){
                // use the gameID from rev to get the game name
                const gameDocRef = doc(db, "Games", `${nextBatchRev.data().gameID}`);
                const gameDocSnap = await getDoc(gameDocRef);

                var reviewObj = {
                    'gameName':gameDocSnap.data().name,
                    'reviewID':nextBatchRev.id,
                    ...nextBatchRev.data()
                };

                // add this object to the array
                tempProfileReviewArray.push(reviewObj);
            }
            // Now add these docs to the existing array of documents being presented
            for(const revObject of tempProfileReviewArray){
                setProfileUserReviews(profileUserReview => [...profileUserReview, revObject]);
            }

            // setLoadingProfileUserReviews(false);

        }
    }

    return { loadingProfileUserReviews, profileUserReview, setProfileUserReviews, getNextBatchReviews, endPoint };
}