/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
// styles
import './userProfile.css';
// firebase
import { auth } from '../../Firebase/firebase';
// import { signOut } from 'firebase/auth';
// hooks
import { useGetUserProfile } from '../../Hooks/useGetUserProfile';
// react redux
// import { useDispatch } from 'react-redux';
// import { logout } from '../../Features/userSlice';
// react-router-dom
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
// react-router-hash-link
import { HashLink as HLink } from 'react-router-hash-link';
// loaders
import DisplayPictureLoader from '../../ProgressIndicators/LoaderScreens/userProfile/displayPicture';
// custom components
// import AllGames from '../../Components/UserProfileComponents/allGamesComponent';
import AllConsoles from '../../Components/UserProfileComponents/allConsolesComponent';
import UserWishlist from '../../Components/UserProfileComponents/userWishlistComponent';
import ProfileUserReviews from '../../Components/UserProfileComponents/profileUserReviews';
import UserForums from '../../Components/UserProfileComponents/userForums';
import LikingGameCollectionScreen from '../../Components/UserProfileComponents/ProfileActions/likingGameCollection';
import UserGamesCollection from '../../Components/UserProfileComponents/userGamesCollection';
import { useSelector } from 'react-redux';
import { selectUser } from '../../Features/userSlice';
// import { unsetPrivateData } from '../../Features/userPrivateDataSlice';

export default function UserProfile(){

    // Get the userid of the profile
    const { userid } = useParams();

    // Get the signed in user's userid
    const signedInUser = useSelector(selectUser);

    // Now we have to display the profile of the userid obtained
    // Get the user details from the profile's userid
    const { loadingUser, getProfileDetails, userData } = useGetUserProfile();

    // To select the tab user wants to navigate to, by default it'll be 0
    const[ selectedTab, setSelectedTab ] = useState(0);
    useEffect(()=>{
        if(userid !== null && userid !== undefined){
            getProfileDetails(userid, 'any')
        }

        // We also monitor at which tab the user wants to go to..
        const profileLinkQuery = window.location.href;
        // console.log(' profileLinkQuery => ',profileLinkQuery);
        const getQueryPart = profileLinkQuery.split('#')[1];
        // Now search for which tab to visit
        // And if the query part is either null or undefined, do nothing just show the default tab.
        if(getQueryPart !== null && getQueryPart !== undefined){
            if(getQueryPart.includes('user_games')){
                setSelectedTab(0);
            }
            else if(getQueryPart.includes('user_consoles')){
                setSelectedTab(1);
            }else if(getQueryPart.includes('user_reviews')){
                setSelectedTab(2);
            }else if(getQueryPart.includes('user_forums')){
                setSelectedTab(3);
            }else if(getQueryPart.includes('user_wishlist')){
                setSelectedTab(4);
            }
        }
    },[userid])

    // For testing variables
    const[totalGamesCounter, setTotalGamesCounter] = useState(0);
    const[totalWishlistGamesCounter, setTotalWishlistGamesCounter] = useState(0);
    const[likedGameCollection, setLikedGameCollection] = useState(false);
    const [showLikedScreen, setShowLikedScreen] = useState(false);

    useEffect(()=>{
        // console.log('Game collection liked => ',likedGameCollection);
        if(likedGameCollection === true){
            setShowLikedScreen(true);
            const timeId = setTimeout(() => {
                // After 3 seconds set the show value to false
                setShowLikedScreen(false)
            }, 3000)
            return () => {
                clearTimeout(timeId)
            }
        }
    },[likedGameCollection])


    return(
        <div className="user-profile-area"
            //  style={{ 'backgroundImage':
            //     (userData && userData.user_cover_image!==null) ? 
            //  `url(${userData.user_cover_image})` : 'transparent',
            //  'backgroundRepeat':'no-repeat',
            //  'backgroundSize':'contain', }}
        >
            {/* For showing the liked screen */}
            { showLikedScreen === true && <LikingGameCollectionScreen/> }
        {/* Area for displaying user information such as its pfp and username */}
            <div className="user-info-area"
                 style={{
                     'backgroundImage': (userData && userData.user_cover_image!==null) ? `url(${userData.user_cover_image})` : 'transparent',
                     'backgroundPosition':'center',
                     'backgroundRepeat':'no-repeat',
                     'backgroundSize':'cover'
                 }}
            >
                
                <div className="user-info-box">
                    <div className="user-profile-pfp-box">
                    {
                        userData && userData.profile_picture !== null && userData.profile_picture !== undefined &&
                        <img src={userData.profile_picture} alt={userData.Name}/>
                    }
                    {
                        userData && (userData.profile_picture === null || userData.profile_picture === undefined) &&
                        <p className='user-profile-pfp-box-txt'>{userData.Name.substring(0,1).toUpperCase()}</p>
                    }
                    </div>
                    { userData && <p className='user-profile-username'>{userData.Name}</p> }
                </div>
                {/* Messaging box to be made later */}
            </div>
            {/* This are is to be made for the tabs options */}
            <div className="user-profile-tab-area">
                <HLink to={'#user_games'} style={{ textDecoration:'none' }} smooth>
                    <button className={selectedTab === 0 ? "user-profile-tab active" : "user-profile-tab"}
                            onClick={()=>setSelectedTab(0)}
                    >
                        Games
                    </button>
                </HLink>
                <HLink to={'#user_consoles'} style={{ textDecoration:'none' }} smooth>
                    <button className={selectedTab === 1 ? "user-profile-tab active" : "user-profile-tab"}
                            onClick={()=>setSelectedTab(1)}
                    >
                        Consoles
                    </button>
                </HLink>
                <HLink to={'#user_reviews'} style={{ textDecoration:'none' }} smooth>
                    <button className={selectedTab === 2 ? "user-profile-tab active" : "user-profile-tab"}
                            onClick={()=>setSelectedTab(2)}
                    >
                        Reviews
                    </button>
                </HLink>
                <HLink to={'#user_forums'} style={{ textDecoration:'none' }} smooth>
                    <button className={selectedTab === 3 ? "user-profile-tab active" : "user-profile-tab"}
                            onClick={()=>setSelectedTab(3)}
                    >
                        Forums
                    </button>
                </HLink>
                <HLink to={'#user_wishlist'} style={{ textDecoration:'none' }} smooth>
                    <button className={selectedTab === 4 ? "user-profile-tab active" : "user-profile-tab"}
                            onClick={()=>setSelectedTab(4)}
                    >
                        Wishlist
                    </button>
                </HLink>
            </div>
            {/* Content of the tab chosen */}
            <div className="chosen-tab-area">
            {
                selectedTab === 0 && userData &&
                <UserGamesCollection
                    userProfileID={userid}
                    loggedInUserID={signedInUser !== null ? signedInUser.uid : null}
                    userProfileData={userData}
                    setTotalGamesCounter={setTotalGamesCounter}
                    setLikedGameCollection={setLikedGameCollection}
                />
            }
            {
                selectedTab === 1 && userData &&
                <AllConsoles
                    userProfileID={userid}
                    loggedInUserID={signedInUser !== null ? signedInUser.uid : null}
                />
            }
            {
                selectedTab === 2 && userData &&
                <ProfileUserReviews
                    userProfileID={userid}
                    loggedInUserID={signedInUser !== null ? signedInUser.uid : null}
                />
            }
            {
                selectedTab === 3 && userData &&
                <UserForums
                    userProfileID={userid}
                    loggedInUserID={signedInUser !== null ? signedInUser.uid : null}
                />
            }
            {
                selectedTab === 4 && userData &&
                <UserWishlist
                    userProfileID={userid}
                    loggedInUserID={signedInUser.uid !== null ? signedInUser.uid : null}
                    setTotalWishlistGamesCounter={setTotalWishlistGamesCounter}
                    userProfileData={userData}
                />
            }
            </div>
        </div>
    )

}