// styles
import './createDiscussion.css';
import { useState, useEffect, useRef } from 'react';
// custom components
// import SearchDiscussionGame from '../CreateDiscussion/SearchDiscussionGame/search_discuss_game';
// import SearchInput from '../../ListComponents/searchInput';

// mui components
import ClearIcon from '@mui/icons-material/Clear';
import AddIcon from '@mui/icons-material/Add';

// text-editor
// import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import 'react-quill/dist/quill.bubble.css';

// custom hooks
import { usePostDiscussion } from '../../../Hooks/Forums/usePostDiscussion';
import { CircularProgress, Dialog, DialogActions, DialogContent } from '@mui/material';

// SEO
import { Helmet } from 'react-helmet';
import GameSearchList from '../../ListComponents/SearchGamesList/gameSearchList';

export const CreateDiscussion = ({ signedInUser })=>{

    // console.log('signedInUser => ',signedInUser);
    const { error, pending, postDiscussion } = usePostDiscussion();

    const [forumError, setForumError] = useState(null);
    const [isNSFW, setIsNSFW] = useState(false);

    const [searchedGame, setSearchedGame] = useState(null);

    // console.log('searchedGame => ',searchedGame);

    const [forumDiscussionTitle, setForumDiscussionTitle] = useState('');
    
    const handleTitleTextChange = (e)=>{
        e.preventDefault();
        setForumDiscussionTitle(e.target.value);
    }
    
    const [forumDescText, setForumDescText] = useState('');
    const handleDescTextChange = (e)=>{
        setForumDescText(e.target.value);
    }

    const discussionAreaRef = useRef(null);

    useEffect(()=>{
        if(discussionAreaRef.current){
            discussionAreaRef.current.style.height = "0px";
            const scrollHeight = discussionAreaRef.current.scrollHeight;
            discussionAreaRef.current.style.height = scrollHeight + "px";
        }
    },[setForumDescText, forumDescText]);

    const [forumSpoiler, setForumSpoiler] = useState(false);
    const handleSpoilerWarning = ()=>{
        if(forumSpoiler)
            setForumSpoiler(false);
        else if(!forumSpoiler)
            setForumSpoiler(true);
    }

    const handleNSFWWarning = ()=>{
        if(isNSFW)
            setIsNSFW(false);
        else if(!isNSFW)
            setIsNSFW(true);
    }

    // Utility function to remove error warnings.
    useEffect(()=>{
        if(searchedGame !== null){
            setForumError(null);
        }
    },[searchedGame, setSearchedGame])

    // To finally add the discussion post.
    // Use the hook to post the discussion to database.
    const handlePostDiscussion = ()=>{
        // if(searchedGame === null){
        //     setForumError('Please select a game');
        //     return;
        // }
        if(taggedGamesID.length === 0){
            setForumError('Add atleast one relevant game');
            return;
        }

        if(forumDiscussionTitle.length === 0){
            setForumError('Please give a title for your discussion');
            return;
        }

        else{
            setForumError(null);
            const forumText = `<p>${forumDescText}</p>`
            // console.log(
            //     searchedGame.name,
            //     forumDiscussionTitle,
            //     forumDescText,
            //     forumSpoiler,
            //     isNSFW
            // )
            postDiscussion('T', forumDiscussionTitle, forumText,forumSpoiler, 
                            isNSFW, signedInUser.uid, taggedGamesID);
        }
    }

    // Modules for text editor - React quill
    // const modules = {
    //     toolbar:[
    //         [{ header:[ 1,2,3,4,5,6,false ] }],
    //         [{ font:[] }],
    //         // [{ size:[] }],
    //         ["bold","italic","underline", "strike", "blockquote"],
    //         ["link","image"]
    //     ]
    // }

    // To get the discussion topic
    const [taggedGames, setTaggedGames] = useState([]);
    const [taggedGamesID, setTaggedGamesID] = useState([]);
    const [searchGameList, setSearchGameList] = useState([]);
    const [openSearchGamesDialog, setOpenSearchGamesDialog] = useState(false);
    const handleOpenSearchGamesDialog = ()=>{
        setOpenSearchGamesDialog(true);
    }
    const handleCloseSearchGamesDialog = ()=>{
        setOpenSearchGamesDialog(false);
    }

    const handleAddToTaggedGames = (tag_game)=>{
        setTaggedGames(taggedGames => [tag_game, ...taggedGames])
        setTaggedGamesID(taggedGamesID => [tag_game.id, ...taggedGamesID])
    }

    const removeFromTaggedGames = (gameID)=>{
        const dupArr = [...taggedGames]
        const dupIndexArr = [...taggedGamesID]
        const element_index = dupArr.findIndex(gameObj => gameObj.id === gameID);
        const element_index_index = dupIndexArr.findIndex(game_id => game_id === gameID);
        if(element_index !== -1){
            dupArr.splice(element_index, 1);   
        }
        if(element_index_index !== -1){
            dupIndexArr.splice(element_index_index, 1);   
        }
        setTaggedGames(dupArr);
        setTaggedGamesID(dupIndexArr);
    }

    const toggleTagSelection = (gameObject)=>{
        if(taggedGamesID.includes(gameObject.id)){
            removeFromTaggedGames(gameObject.id);
        }else{
            handleAddToTaggedGames(gameObject);
        }
    }

    

    return(
        <div className='create-discussion-container'>
            <Helmet>
                <title>Create - Game Forums</title>
                <meta name='description' content={'Create Gaming Forums'}/>
                <meta name='keywords' content={'Create, creating, posting, publishing, Gamers, Gaming, Gaming forums, forums, discussion, game discussions'}/>
            </Helmet>
            <Dialog
                open={openSearchGamesDialog}
                onClose={handleCloseSearchGamesDialog}
                fullWidth
                maxWidth={'sm'}
            >
                <DialogContent className='game-tag-dialog'>
                    <div className="search-input-div">
                       <GameSearchList resultList={taggedGames} setResultList={setTaggedGames} setSearchGames={setSearchGameList}/>
                    </div>
                    {
                        searchGameList && searchGameList.length > 0 &&
                        searchGameList.map((tag_game)=>(
                            <div key={tag_game.id}
                                 className={taggedGamesID.includes(tag_game.id) ? "tag-game-tile selected" : "tag-game-tile"}
                                 onClick={()=>toggleTagSelection(tag_game)}
                                 onKeyDown={()=>toggleTagSelection(tag_game)}
                            >
                                {tag_game.name}
                            </div>
                        ))
                    }
                </DialogContent>
                <DialogActions className='game-tag-dialog-actions'>
                <div className="game-tag-selected-container">
                {
                    taggedGames && taggedGames.length > 0 &&
                    taggedGames.map((current_tag_game)=>(
                        <div key={current_tag_game.id} className="tagged-game-list-tile">
                            <ClearIcon style={{ marginRight:'5px', color:'#f8f8f8' }}
                                       onClick = { ()=> removeFromTaggedGames(current_tag_game.id) }
                            />
                            {current_tag_game.name}
                        </div>
                    ))
                }
                </div> 
                <div className="game-tag-cta-container">
                    <div className="game-tag-cta"
                         onClick={()=>handleCloseSearchGamesDialog()}
                         onKeyDown={()=>handleCloseSearchGamesDialog()}
                    >
                        DONE
                    </div> 
                </div>      
                </DialogActions>
            </Dialog>
            {/* <SearchDiscussionGame setGamePrompt={setSearchedGame}/> */}
            {/* <div className='new-discussion-form'> */}
                <input type="text" 
                       className='forum-title-area'
                       placeholder='Give a title to your discussion'
                       value={forumDiscussionTitle}
                       onChange={(e)=>{
                            setForumError(null);
                            handleTitleTextChange(e)
                        }}
                       required
                />
                {/* <ReactQuill theme="snow"
                            modules={modules}
                            value={forumDescText}
                            onChange={setForumDescText}
                            placeholder={'Write description here...(Optional)'}
                            className='forum-discussion-description'
                /> */}
                <textarea
                    className='forum-description-area' 
                    onChange={(e)=>handleDescTextChange(e)} 
                    id="forum-desc-text" 
                    placeholder='Write description here...(OPTIONAL)' 
                    rows="10"
                    ref={discussionAreaRef} 
                    value={forumDescText}
                >
                </textarea>
                <div className="tag-games-area">
                    <div className="add-rel-game-cta"
                         onClick={()=>handleOpenSearchGamesDialog()}
                         onKeyDown={()=>handleOpenSearchGamesDialog()}
                    >
                        <AddIcon style={{ color:'#f8f8f8' }}/>
                        Add relevant game(s)
                    </div>
                    {
                        taggedGames && taggedGames.length > 0 &&
                        taggedGames.map((tag_game)=>(
                            <div key={tag_game.id} className="tagged-game-list-tile">
                                <ClearIcon style={{ marginRight:'5px', color:'#f8f8f8' }}
                                           onClick = { ()=> removeFromTaggedGames(tag_game.id) }
                                />
                                {tag_game.name}
                            </div>
                        ))
                    }
                </div>
                <div className="forum-spoiler-check-area">
                    <div onClick={()=>handleSpoilerWarning()} 
                         onKeyDown={()=>handleSpoilerWarning()}
                         className={forumSpoiler===false ? "forum-spoiler-check" : "forum-spoiler-check active"}>
                    </div>
                    <p style={{ textAlign:'left' }}>Contains spoilers</p>
                </div>
                <div className="forum-spoiler-check-area">
                    <div onClick={()=>handleNSFWWarning()} 
                         onKeyDown={()=>handleNSFWWarning()}
                         className={isNSFW===false ? "forum-spoiler-check" : "forum-spoiler-check active"}>
                    </div>
                    <p style={{ textAlign:'left' }}>NSFW</p>
                </div>
                <div className="forum-post-area">
                {
                    pending === false && 
                    <button className="add-forum-discussion-cta"
                            onClick={()=>handlePostDiscussion()}
                    >
                        Post
                    </button>
                }
                {
                    pending === true &&
                    <CircularProgress size={24} style={{ color:'#FFE74C' }} />
                }
                </div>
                {
                    forumError && <p style={{ 'color':'red' }}>{forumError}</p>
                }
                {
                    error && <p style={{ 'color':'red' }}>{error}</p>
                }
            {/* </div> */}
        </div>
    )
}