/* eslint-disable no-unused-vars */
// react states
import { useState } from 'react';
// firebase
import { db } from '../Firebase/firebase';
import { doc, deleteDoc, updateDoc, arrayRemove, collection, getDocs,
        query, where
} from "firebase/firestore";

// update locally
import { removeGameFromReviewed } from '../Features/userPrivateDataSlice';
// react redux
import { useDispatch } from 'react-redux';

export const useDeleteReview = ()=>{
    const [error, setError] = useState(null);
    const [deleting, setDeleting] = useState(false);
    const [success, setSuccess] = useState(null);

    // To dispatch changes locally
    const deleteReviewDispatch = useDispatch();

    const deleteReview = async(review_id, game_id, user_id)=>{
        setDeleting(true);
        setSuccess(null);
        // Firstly, remove any like, dislike or other reactions
        const allLikesSnapshot = await getDocs(collection(db, `GameReviews/${review_id}/Liked`));
        if(!allLikesSnapshot.empty){
            for(const likedDocument of allLikesSnapshot.docs){
                await deleteDoc(doc(db, `GameReviews/${review_id}/Liked`, `${likedDocument.id}`))
                .catch((err)=>{
                    setSuccess('fail');
                    setError(err.message);
                    setDeleting(false);
                    return;
                })
            }
        }
        const allDislikeSnapshot = await getDocs(collection(db, `GameReviews/${review_id}/Disliked`));
        if(!allDislikeSnapshot.empty){
            for(const dislikedDocument of allDislikeSnapshot.docs){
                await deleteDoc(doc(db, `GameReviews/${review_id}/Disliked`, `${dislikedDocument.id}`))
                .catch((err)=>{
                    setSuccess('fail');
                    setError(err.message);
                    setDeleting(false);
                    return;
                })
            }
        }

        // Now remove all the comments if any.
        const reviewCommentsQuery = query(collection(db, "ReviewComments"), where("reviewID", "==", `${review_id}`));
        const reviewCommentSnapshots = await getDocs(reviewCommentsQuery)
        .catch((err)=>{
            setError(err.message);
            setDeleting(false);
            setSuccess('fail');
            return;
        });

        // now go to each reviewCommentSnapshot and delete that doc.
        if(reviewCommentSnapshots && reviewCommentSnapshots.docs.length > 0){
            for(const revCommentSnap of reviewCommentSnapshots.docs){
                await deleteDoc(doc(db, "ReviewComments", `${revCommentSnap.id}`))
                .catch((err)=>{
                    setError(err.message);
                    setDeleting(false);
                    setSuccess(`fail`);
                    return;
                });
            }
        }
        // Comments deletion of the forum is done.

        await deleteDoc(doc(db, `GameReviews`, `${review_id}`))
        .catch((err)=>{
            setSuccess('fail');
            setError(err.message);
            setDeleting(false);
            return;
        })

        await updateDoc(doc(db,`Users/${user_id}/PrivateInfo`, 'userInfo'),{
            'games_reviewed':arrayRemove(`${game_id}`)
        }).catch((err)=>{
            setSuccess('fail');
            setError(err.message);
            setDeleting(false);
            return;
        }).then(()=>{
            deleteReviewDispatch(removeGameFromReviewed({
                'userID':user_id,
                'gameID':game_id
            }))
        });

        setSuccess('success');
        setDeleting(false);
    }

    return {error, success, deleting, deleteReview};
}