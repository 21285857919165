/* eslint-disable react-hooks/exhaustive-deps */
// react states
import { useState, useEffect } from 'react';
// firebase
import { db } from '../../Firebase/firebase';
import { collection, getDocs, query, where, limit } from 'firebase/firestore';

export const useGetGenreGames = ( genreID )=>{
    const [genreGames, setGenreGames] = useState([]);
    const [loadingGenreGames, setLoadingGenreGames] = useState(false);
    const [genreGameError, setGenreGameError] = useState(null);

    const genreGamesKey = genreID+'gamesList';

    const getGenreGames = async(genre_id) => {
        const cachedGamesList = sessionStorage.getItem(genreGamesKey);
        if(cachedGamesList){
            setGenreGames(JSON.parse(cachedGamesList));
        }else{
            setLoadingGenreGames(true);
            const genreGamesQuery = query(collection(db, 'Games'), 
                where("genres", "array-contains", `${genre_id}`), limit(25));
            const genreGamesDocSnapshot = await getDocs(genreGamesQuery)
                                            .catch((err)=>{
                                                setGenreGameError(err.message);
                                                setLoadingGenreGames(false);
                                                return;
                                            });

            var genreGamesArr = [];
            for(const game of genreGamesDocSnapshot.docs){
                var genreGameObj = {
                    'id':game.id,
                    ...game.data()
                }

                genreGamesArr.push(genreGameObj);
            }

            // Add in session storage to reduce the number of reads,
            // only if the arr is not empty.
            if(genreGamesArr.length > 0){
                sessionStorage.setItem(genreGamesKey, JSON.stringify(genreGamesArr));
            }
            
            setGenreGames(genreGamesArr);
            setLoadingGenreGames(false);
            setGenreGameError(null);
        }
    }

    useEffect(()=>{
        if(genreID !== null && genreID !== undefined){
            getGenreGames(genreID);
        }
    },[genreID])

    return { genreGames, loadingGenreGames, genreGameError, getGenreGames };
}