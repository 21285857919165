/* eslint-disable react-hooks/exhaustive-deps */
// styles
import './editors_pick.css';
// // hooks
// import { useEditorPick } from '../../../Hooks/editorPicks';
// import { CircularProgress } from '@mui/material';
import { useGetEditorsPick } from '../../../Hooks/Homepage/useGetEditorsPick';
import EditorPickTile from './EditorPickTile/editorPickTile';

export default function EditorsPick(){

    const { loadingEditorsPick, editorPicks } = useGetEditorsPick();

    return(
        <div className="editor-pick-container">
            <p className='editor-pick-container-heading'>Editor Picks</p>
            <div className="editor-picks-area">
            {
                loadingEditorsPick === false &&
                editorPicks && editorPicks.length > 0 
                && editorPicks.map((ep)=>(
                    <EditorPickTile key={ep.game_id} editorPickGame={ep}/>
                ))
            }
            </div>
        </div>
    )
}