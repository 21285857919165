// react states
import { useEffect, useState } from "react"
// firebase
import { db } from '../../Firebase/firebase';
import { doc, getDoc } from "firebase/firestore";

export const useDiscussionFetch = (discussionID)=>{
    const [fetchError, setFetchError] = useState(false);
    const [fetching, setFetching] = useState(false);
    const [discussionTopic, setDiscussionTopic] = useState(null);
    
    const fetchDiscussionData = async (discussion_id)=>{
        setFetching(true);
        if(discussion_id !== null){
            const discussionRef = doc(db, "Forums", `${discussion_id}`);
            const discussionSnap = await getDoc(discussionRef)
            .catch((err)=>{
                setFetchError(err.message);
                setFetching(null);
                return;
            });

            if(discussionSnap.exists()){
                setDiscussionTopic({
                    'id':discussion_id,
                    ...discussionSnap.data()
                })
                setFetchError(null);
                setFetching(false);
            }

        }
        setFetching(false);
    }

    useEffect(()=>{
        fetchDiscussionData(discussionID);
    },[discussionID]);

    return { fetchDiscussionData, fetchError, fetching, discussionTopic };
}