// redux toolkit
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
// database
import { db } from '../Firebase/firebase';
import { query, getDocs, collection, orderBy, limit, startAfter } from 'firebase/firestore';

// utility
const convertToDate = (timestamp) => {
    const date = new Date(timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000);
    const options = { day: '2-digit', month: 'long', year: 'numeric' };
    const formattedDate = date.toLocaleDateString('en-US', options);
    return formattedDate;
};
export const fetchArticles = createAsyncThunk(
    'articles/fetchArticles',
    async({ batchLimit, startAfterVisible })=>{
        
        try{
            var articlesQuery = query(collection(db,'Articles'), orderBy('createdAt','desc'),limit(batchLimit));
        
            if(startAfterVisible){
                articlesQuery = query(collection(db,'Articles'), orderBy('createdAt','desc'), startAfter(startAfterVisible),limit(limit))
            }

            const articleDocs = await getDocs(articlesQuery);
            const articlesArr = [];
            for(const article of articleDocs.docs){
                const articleObject = {
                    'id':article.id,
                    ...article.data(),
                    'createdAt': convertToDate(article.data().createdAt)
                }
                articlesArr.push(articleObject);
            }

            const lastVisible = articleDocs.docs[articleDocs.docs.length - 1];

            return { articlesArr, lastVisible };

        }catch(articleErr){
            throw Error(articleErr);
        }
    }
)

const articleSlice = createSlice({
    name:'articles',
    initialState:{
        articles: [],
        lastVisible: null,
        loading: false,
        error: null
    },
    reducers:{},
    extraReducers:( builder )=>{
        builder.addCase(fetchArticles.pending, (state)=>{
            state.loading = true;
            state.error = null;
        })
        .addCase(fetchArticles.rejected, (state, action)=>{
            state.loading = false;
            state.error = action.error.message;
        })
        .addCase(fetchArticles.fulfilled, (state, action)=>{
            state.loading = false;
            state.articles = [...state.articles, ...action.payload.articlesArr];
            state.lastVisible = action.payload.lastVisible;
        })
    }
});

export const selectAllArticles = (state) => state.articles;
export default articleSlice.reducer;