// styles
import './genreDropdown.css';
// react router dom
import { useNavigate } from 'react-router-dom';
// custom hooks
import { useGetAllGenres } from '../../../Hooks/Genres/useGetAllGenres';

export default function GenreDropdown(){
    // custom hook to get all the consoles
    const { allGenres, loadingAllGenres } = useGetAllGenres();

    // To navigate to a particular console
    const genreDropdownNav = useNavigate();
    const handleNavigateToGenre = (genreID)=>{
        genreDropdownNav(`/genres/${genreID}`);
    }
 
    return(
        <div className="navbar-genre-dropdown">
        {
            loadingAllGenres === false &&
            allGenres && allGenres.length > 0 
            && allGenres.map((navGenre)=>(
                <button key={navGenre.id}
                        className="navbar-genre-dropdown-tile"
                        onClick={()=>handleNavigateToGenre(navGenre.id)}
                >
                    {navGenre.name}
                </button>
            ))
        }
        </div>
    );
}