// styles
import './footer.css';
// routing
import { useNavigate } from 'react-router-dom';
// icons
import instaLogo from '../assets/Logo/Instagram-Logo/02_White_Glyph/Instagram_Glyph_White.png';
import youtubeLogo from '../assets/Logo/Youtube-Logo/icons8-youtube-logo-ios-17-filled/icons8-youtube-logo-100.png';
// mui icons
import CopyrightIcon from '@mui/icons-material/Copyright';

export default function Footer(){

    const footerNav = useNavigate();

    const handleAboutUsNav = ()=>{
        footerNav('/about_us');
    }

    const handlePrivacyPolicyNav = ()=>{
        footerNav('/privacy_policy');
    }

    return(
        <footer className='footer-container'>
            <div className="company-copyright-info">
                <CopyrightIcon style={{ color:'#f8f8f8', fontSize:'0.9rem' }}/>
                <p>2024 The Gamer Bros. All rights reserved.</p>
            </div>
            <button className='footer-txt'
                    onClick={()=>handleAboutUsNav()}
            >
                About us
            </button>
            <button className='footer-txt'
                    onClick={()=>handlePrivacyPolicyNav()}
            >
                Privacy policy
            </button>
            <div className="company-social-handles">
                <p className='footer-txt'>Follow us : </p>
                <div className="social-handle">
                    <a href='https://www.instagram.com/officialgamerbroz/' target="_blank" rel="noreferrer">
                        <img src={instaLogo} alt="@officialgamerbroz"/>
                    </a>
                </div>
                <div className="social-handle">
                    <a href='https://www.youtube.com/@TheGamerBros786/' target="_blank" rel="noreferrer">
                        <img src={youtubeLogo} alt="@TheGamerBros786"/>
                    </a>
                </div>
            </div>
        </footer>
    )
}