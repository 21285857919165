/* eslint-disable no-restricted-globals */
/* eslint-disable react-hooks/exhaustive-deps */
// styles
import './App.css';
// components
import Navbar from './Navbar/navbar';
// pages
import Homepage from './Pages/Homepage/homepage';
// react-router
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
// import GamePage from './Pages/Gamepage/full_game_page';
import SearchPage from './Pages/SearchPage/search_page';
// authentication state management
import { login, logout, selectUser } from './Features/userSlice';
import { setPrivateData, selectUserData } from './Features/userPrivateDataSlice';
import { useDispatch, useSelector } from 'react-redux';
import { auth } from './Firebase/firebase';
import { onAuthStateChanged } from 'firebase/auth';
import { useEffect, useState } from 'react';
import { getUserDetails } from './utility/userDetails';
import UserProfile from './Pages/UserProfilePage/userProfile';
import UserSettings from './Components/UserProfileComponents/userSettingsComponent';
import PasswordForget from './Components/AuthenticationComponents/PasswordForgetComponent/password_forget';
import PasswordReset from './Components/AuthenticationComponents/PasswordReset/password_reset';
import Report from './Pages/Report/report';
import ForumHomePage from './Pages/Forums/forum_home_page';
import DiscussionPage from './Pages/Forums/discussion_page';
import EmailVerification from './Components/AuthenticationComponents/EmailVerificationComponent/emailVerification';
import UserOnboarding from './Components/AuthenticationComponents/UserOnboardingComponent/userOnboarding';
import EmailVerifyingComponent from './Components/AuthenticationComponents/EmailVerifyingComponent/emailVerifyingComponent';
import UserLogin from './Components/AuthenticationComponents/LoginComponent/user_login';
import UserSignUp from './Components/AuthenticationComponents/SignupComponent/user_signup';
import GameRequestPage from './Pages/GameRequest/gameRequestPage';
import PrivacyPolicyPage from './Pages/PrivacyPolicy/privacy_policy';

// For os checking
import { mobileCheck, getMobileOperatingSystem } from './OperatingSystems/osCheck';
// for push notification messaging
import { initializeMessaging } from './Firebase/fb-messaging';
import { 
  // allUserWishlistGames, 
  useGetUserCollections, 
  // allUserCollectionGames 
} from './Hooks/GameUserInteraction/useGetUserCollections';
import AboutUs from './Pages/AboutUs/aboutus';
import Footer from './Footer/footer';
import FullReviewPage from './Components/GamepageComponents/GameReviewComponent/fullReviewPage';
// import SignUpTile from './Components/AuthenticationComponents/SignUpTile/signUpTile';
import WriteReviewPage from './Pages/WriteReviewPage/write_review_page';
import ConsolePage from './Pages/ConsolePage/console_page';
import GenrePage from './Pages/Genres/genrePage';
import ArticleHomepage from './Pages/Articles/articleHomepage';
import ArticleReadPage from './Pages/Articles/articleReadPage';
import MainGamePage from './Pages/MainGamePage/mainGamePage';
import MainGameReviewPage from './Pages/MainGameReviewPage/mainGameReviewPage';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';


function App() {

  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const userPrivateData = useSelector(selectUserData);

  // console.log("user => ",user);

  // console.log('user data => ',userPrivateData);
  // console.log('allUserGames => ',allUserCollectionGames);
  // console.log('all user wishlist => ',allUserWishlistGames)

  const { getUserWishlistCollection, 
          getUserGameCollection
  } = useGetUserCollections();
  useEffect(()=>{
    if(JSON.stringify(userPrivateData)!=='{}'){
      getUserGameCollection();
      getUserWishlistCollection();
    }
  },[userPrivateData])

  const [userData, setUserData] = useState({});
  // const [fetchingUserData, setFetchingUserData] = useState(false);

  // onMessageListener().then(payload => {
  //   console.log(payload.notification);
  // }).catch(err => console.log('failed: ', err));

  useEffect(()=>{
    onAuthStateChanged(auth, (userAuth)=>{
      // console.log('The user its email verified => ',userAuth.emailVerified);
      if(userAuth){
        // userAuth.emailVerified = true;
        dispatch(login({
          uid: userAuth.uid,
          email:userAuth.email,
          isEmailVerified:userAuth.emailVerified
        }))
      }else{
        dispatch(logout())
      }
    })
  },[]);

  useEffect(()=>{
    const res = mobileCheck();
    var mobileRes = null;
    if(res === true){
      mobileRes = getMobileOperatingSystem();
    }

    // console.log(mobileRes);
    // var iOSVersion = 0.0;
    // if(mobileRes === 'iOS'){
    //   iOSVersion = getIOSVersion();
    // }

    // console.log('vers ', iOSVersion)

    if(user!==null){
      // console.log('user before sending to fn is ',user.uid);
      // setFetchingUserData(true);
      // if(mobileRes === 'iOS'){
      //   initializeMessaging(user);
      // }
      if(mobileRes !== 'iOS'){
        initializeMessaging(user);
      }
      getUserDetails(user.uid, setUserData);
      // initializationProcess();
    }
  },[user]);

  useEffect(()=>{
    dispatch(setPrivateData(userData));
    // setFetchingUserData(false);
  },[userData, setUserData]);


  // console.log('currently the user is ',user);
  // console.log('users private data is ', userPrivateData );
  // console.log('userData => ',userData);

  // To show the sign up tile if user is not signed up
  // const [showSignUpTile, setShowSignUpTile] = useState(true);

  // useEffect(()=>{
  //   if(user !== null){
  //     setShowSignUpTile(false);
  //   }else{
  //     setShowSignUpTile(true);
  //   }
  // },[user])

  // For efficient querying of data
  const queryClient = new QueryClient();
  
  return (
    <QueryClientProvider client={queryClient}>
      <div className="App">
        <Router>
          <Navbar/>
          {/* {
            showSignUpTile === true && 
            <SignUpTile/>
          } */}
          <Routes>
            <Route element={<Homepage/>} path="/" exact/>
            {/* Authentication and onboarding routes */}

            <Route element={ user === null ? <UserLogin/> : <Navigate to={`/email_verification`} replace/> } path='/user_auth'/>

            {/* Auth components */}
            <Route element={<UserSignUp/>} path='/signup'/>
            {/* Auth components */}

            {/* Routing for email verification and user onboarding */}
            
            {
              auth!==null && auth.currentUser!==null &&
              <>
                <Route element={ auth.currentUser.emailVerified === true ? <Navigate to='/user_onboarding' replace/> :
                                    <EmailVerification userObject={auth.currentUser}/>
                                } path='/email_verification'/>
                {
                  userData && <Route element={ (userData.isInDatabase) ? <Navigate to={`/user_profile/${user ? user.uid : null}`} replace/> :
                                                  <UserOnboarding data={Object.keys(userData).length > 0 ? userData : null}/>
                                              } path='/user_onboarding'/>
                                              // : <Route element={<GeneralLoadingPage/>} path='/loading_page'/>
                              // <Navigate to={'/loading_page'} replace/>
                }
                
              </>
            }

            <Route element={<EmailVerifyingComponent/>} path='/verify-user'/>
            
            {/* Routing for email verification and user onboarding */}

            {/* Authentication and onbooarding routes  */}
            <Route element={<MainGamePage/>} path='/game_page/:gameid'/>
            <Route element={<MainGameReviewPage/>} path="/game_reviews/:gameid"/>
            <Route element={<SearchPage/>} path="/search_page" />
            {/* ORIGINAL */}
            <Route element={<UserProfile/>} path="/user_profile/:userid"/>
            {/* ORIGINAL */}
            <Route element={<UserSettings/>} path='/user_settings'/>
            <Route element={<PasswordForget/>} path='/password_forget'/>
            <Route element={<PasswordReset/>} path='/password_reset'/>
            <Route element={<Report/>} path='/report'/>
            <Route element={<ForumHomePage/>} path='/forums'/>
            <Route element={<DiscussionPage/>} path='/discussion/:discussion_id' />
            <Route element={<FullReviewPage/>} path='/full_review/:review_id'/>
            {/* GAME REQUEST PAGE */}
            <Route element={<GameRequestPage/>} path='/game_request' />
            <Route element={<AboutUs/>} path='/about_us'/>
            <Route element={<WriteReviewPage/>} path='/write_game_review/:game_id'/>
            <Route element={<PrivacyPolicyPage/>} path='/privacy_policy'/>
            <Route element={<ConsolePage/>} path='/consoles/:console_id'/>
            <Route element={<GenrePage/>} path='/genres/:genre_id'/>
            <Route element={<ArticleHomepage/>} path='/articles'/>
            <Route element={<ArticleReadPage/>} path='/articles/:article_id' />
          </Routes>
          <Footer/>
        </Router>
      </div>
    </QueryClientProvider>
  );
}

export default App;

