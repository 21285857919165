/* eslint-disable no-loop-func */
import { useState } from 'react';

// firebase
import { db } from '../../Firebase/firebase';
import { doc, getDoc } from 'firebase/firestore';


export const useFetchMultipleGameData = ()=>{
    const [fetchingMultipleGames, setFetchingMultipleGames] = useState(false);
    const [multipleGameData, setMultipleGameData] = useState([]);

    const fetchMultipleGameData = async(gameIDs)=>{
        setFetchingMultipleGames(true);
        setMultipleGameData([]);
        for(const id of gameIDs){
            const gameDocRef = doc(db,'Games',`${id}`);
            const gameDocSnap = await getDoc(gameDocRef)
            .catch((err)=>{
                console.log(err);
                setFetchingMultipleGames(false);
                return;
            });

            if(gameDocSnap.exists()){
                var gameObj = {
                    'id':gameDocSnap.id,
                    ...gameDocSnap.data()
                }
                setMultipleGameData(multipleGameData => [gameObj, ...multipleGameData]);
                // gameDataMap[`${gameDocSnap.id}`] = gameObj;
            }
        }

        setFetchingMultipleGames(false);
    }

    // console.log('gameData => ',gameData);

    return { fetchingMultipleGames, multipleGameData, fetchMultipleGameData };
}