// react states
import { useEffect, useState } from 'react';
// firebase
import { db } from '../../Firebase/firebase';
import { query, collection, doc, getDocs, getDoc, setDoc, where
         , deleteDoc, updateDoc
       } from 'firebase/firestore';

export const useDiscussionOperations = (forum_id, user_id)=>{
    // const [discussionError, setDiscussionError] = useState(null);
    const [processing, setProcessing] = useState(false);
    const [currentLikes, setCurrentLikes] = useState(0);
    const [currentDislikes, setCurrentDislikes] = useState(0);
    const [userForumReaction, setUserForumReaction] = useState([]);

    useEffect(()=>{
        // if(user_id !== null)
            setUserReaction(forum_id, user_id);
    },[forum_id, user_id]);

    const setUserReaction = async(forumID, userID)=>{
        const likeQuery = query(collection(db, `Forums/${forumID}/Likes`), where('state','==',1));
        const likeQuerySnapshot = await getDocs(likeQuery);
        setCurrentLikes(likeQuerySnapshot.docs.length);

        const dislikeQuery = query(collection(db, `Forums/${forumID}/Likes`), where('state','==',-1));
        const dislikeQuerySnapshot = await getDocs(dislikeQuery);
        setCurrentDislikes(dislikeQuerySnapshot.docs.length);

        if(userID !== null){
            const userReactionRef = doc(db, `Forums/${forumID}/Likes`, `${userID}`);
            const userReactionSnap = await getDoc(userReactionRef);

            let tempReactions = [];
            if( userReactionSnap.exists()){
                if(userReactionSnap.data().state === 1){
                    tempReactions.push('L');
                    setUserForumReaction(tempReactions);
                }else if(userReactionSnap.data().state === -1){
                    tempReactions.push('D');
                    setUserForumReaction(tempReactions);
                }
            }else{
                setUserForumReaction(tempReactions);
            }
        }
    }
    
    const likeDiscussion = async(forumID, userID)=>{
        setProcessing(true);
        const likeRef = doc(db, `Forums/${forumID}/Likes`, `${userID}`);
        const likeSnap = await getDoc(likeRef);

        if(likeSnap.exists()){
            if(likeSnap.data().state === 1){
                // Delete this doc
                await deleteDoc(doc(db, `Forums/${forumID}/Likes`, `${userID}`))
                .catch(()=>{
                    setProcessing(false);
                    return;
                });
            }else if(likeSnap.data().state === -1){
                //update state as 1
                await updateDoc(likeRef, {
                    'state': 1
                }).catch(()=>{
                    setProcessing(false);
                    return;
                });
            }
        }else{
            await setDoc(doc(db, `Forums/${forumID}/Likes`, `${userID}`), {
                'state':1
            }).catch(()=>{
                setProcessing(false);
                return;
            });;
        }

        setProcessing(false);
    }

    const dislikeDiscussion = async(forumID, userID)=>{
        setProcessing(true);
        const dislikeRef = doc(db, `Forums/${forumID}/Likes`, `${userID}`);
        const dislikeSnap = await getDoc(dislikeRef);

        if(dislikeSnap.exists()){
            if(dislikeSnap.data().state === -1){
                // Delete this doc
                await deleteDoc(doc(db, `Forums/${forumID}/Likes`, `${userID}`))
                .catch(()=>{
                    setProcessing(false);
                    return;
                });
            }else if(dislikeSnap.data().state === 1){
                //update state as 1
                await updateDoc(dislikeRef, {
                    'state': -1
                }).catch(()=>{
                    setProcessing(false);
                    return;
                });
            }
        }else{
            await setDoc(doc(db, `Forums/${forumID}/Likes`, `${userID}`), {
                'state':-1
            }).catch(()=>{
                setProcessing(false);
                return;
            });
        }

        setProcessing(false);
    }

    return { processing, currentLikes, currentDislikes, 
             userForumReaction, likeDiscussion, dislikeDiscussion
           };
}
