export function getReadableTime( datObject ){
    const { _seconds, _nanoseconds } = datObject;
    const date = new Date(_seconds * 1000 + Math.floor(_nanoseconds / 1000000))
    const now = Date.now();

    const seconds = Math.floor((now - date) / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    if (days > 0) {
        return `${days} days ago`;
    } else if (hours > 0) {
        return `${hours} hours ago`;
    } else if (minutes > 0) {
        return `${minutes} minutes ago`;
    } else {
        return `${seconds} seconds ago`;
    }
}