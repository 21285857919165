/* eslint-disable react-hooks/exhaustive-deps */
// styles
import './prev_discussion_tile.css';
// react states
import { useEffect, useState } from 'react';
// react router
import { Link } from 'react-router-dom';
// utility function(s)
// import { getReadableTime } from '../../TimeVarianceUtilityComponent/timingFunction';
// custom hooks
import { useGetUserProfile } from '../../../Hooks/useGetUserProfile';
// import { useGameDetailsFetch } from '../../../Hooks/useGameDetailsFetch';
import { useFetchMultipleGameData } from '../../../Hooks/GameData/useFetchMultipleGameData';
import { useFetchUserDiscussions } from '../../../Hooks/Forums/useFetchUserDiscussions';
// mui components
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { CircularProgress, Dialog, DialogContent, DialogActions } from '@mui/material';
// for creating unique ids
import { v4 as uuid } from 'uuid';

// To get and give reactions
import { useGiveReactions } from '../../../Hooks/Reactions/useGiveReactions';
import Sharing from '../../Sharing/sharing';

export default function PrevDiscussionTile({ discussion, currentUser }){

    // To see how much time passed
    // const [discussionCreationTime, setDiscussionCreationTime] = useState('');
    // console.log('user in PrevDiscussionTile => ',currentUser);
    // To get the user details of the one who started the discussion
    const { loadingUser, userData ,getProfileDetails } = useGetUserProfile();
    const forViewingUser = useGetUserProfile();
    // To get the game details
    // const { fetchingGame, gameDetails } = useGameDetailsFetch(discussion.gameID);
    const { fetchingMultipleGames, fetchMultipleGameData, multipleGameData } = useFetchMultipleGameData();
     
    const { deletingStatus, isDeleting,
        deleteUserDiscussion    
    } = useFetchUserDiscussions();

    useEffect(()=>{
        getProfileDetails(discussion.userID);
        forViewingUser.getProfileDetails(currentUser!==null ? currentUser.uid : null);
    
        // To fetch all the game details
        if(discussion.taggedGames !== undefined){
            const gameids = discussion.taggedGames;
            fetchMultipleGameData(gameids);
            // getTaggedGamesData(gameids);
        }

        // const creationTime = getReadableTime(discussion.createdAt);
        // setDiscussionCreationTime(creationTime);

    },[discussion]);


    // console.log('TG => ',multipleGameData);

    // console.log('loadingUser => ',loadingUser);
    // console.log('Loaded profile => ', userData);

    // To give reactions & to get reactions
    const { prevReaction, tempLikes, tempDislikes,
        handleClickLike, handleClickDislike } = useGiveReactions(
            'discussion',
            discussion.id,
            currentUser !== null ? currentUser.uid : null
        );

    const [startSharing, setStartSharing] = useState(false);
    const openSharingDialog = ()=>{
        setStartSharing(true);
    }
    const closeSharingDialog = ()=>{
        setStartSharing(false);
    }

    // To delete a forum.
    // Show a box for confirmation
    const [forumIdToDelete, setForumIdToDelete] = useState(null);
    const [openDeletionBox, setOpenDeletionBox] = useState(false);
    const handleOpenDeletionBox = (deletionid)=>{
        setForumIdToDelete(deletionid);
        setOpenDeletionBox(true);
    }
    const handleCloseDeletionBox = ()=>{
        setOpenDeletionBox(false);
    }

    const startForumDeletion = ()=>{
        // console.log('Forum to delete is : ',forumIdToDelete);
        deleteUserDiscussion(forumIdToDelete);
    }

    useEffect(()=>{
        if(deletingStatus === 'success'){
            handleCloseDeletionBox();
            window.location.reload(true);
        }
    },[deletingStatus]);

    return(
        <div className="discussion-tile">
            <Dialog
                open={openDeletionBox}
                scroll={'paper'}
                maxWidth={'sm'}
                fullWidth={true}
            >
                <DialogContent className='user-forum-deletion-container'>
                {
                    isDeleting && 
                    <>
                        <CircularProgress size={24} style={{ 'color':'#FFE74C' }}/>
                        { deletingStatus === 'comment_delete' && <p>Deleting comments related to this discussion</p> }
                        { deletingStatus === 'reaction_delete' && <p>Deleting reaction(s) on this discussion</p> }
                        { deletingStatus === 'forum_delete' && <p>Deleting the discussion</p> }
                        { deletingStatus === 'success' && <p>Discussion deleted successfully</p> }
                    </>
                }
                {
                    !isDeleting && 'Are you sure you want to delete this discussion?'
                }
                </DialogContent>
                {
                    !isDeleting && 
                    <DialogActions className='user-forum-deletion-actions'>
                        <button className='user-forum-deletion-button'
                                onClick={()=>startForumDeletion()}
                        >
                            Yes, I am sure
                        </button>
                        <button className='user-forum-deletion-cancel-button'
                                onClick={()=>handleCloseDeletionBox()}
                        >
                            No
                        </button>
                    </DialogActions>
                }
                
            </Dialog>
            {
                startSharing === true &&
                <Sharing
                    // type, sharingLink, extraText, startSharing, closeSharingDialog
                    type={'discussion'}
                    sharingLink = {`https://thegamerbroz.com/discussion/${discussion.id}`}
                    extraText={discussion.title}
                    startSharing={startSharing}
                    closeSharingDialog={closeSharingDialog}
                />
            }
                
            <div className="discussion-tile-header-row">
                <div className="discussion-tile-user">
                    <Link to={`/user_profile/${discussion.userID}`} style={{ textDecoration:'none', display:'flex', flexDirection:'row' }}>
                    {
                        !loadingUser && userData &&
                        <>
                            <div className="discussion-tile-user-pfp">
                            {
                                userData.profile_picture !== null &&
                                <img src={userData.profile_picture} alt=""/>
                            }
                            {
                                userData.profile_picture === null &&
                                <p>{userData.Name.substring(0,1).toUpperCase()}</p>
                            }  
                            </div>
                            <div className="discussion-tile-user-detail">
                                <p>{userData.Name}</p>
                                {/* { discussionCreationTime !== '' && discussionCreationTime.length > 0 &&
                                    <p>{discussionCreationTime}</p> 
                                } */}
                            </div>
                        </>
                    }
                    </Link>
                </div>
                
                {
                    discussion.spoilers &&
                    <div className="discussion-tile-spoiler-alert">
                        Spoiler alert
                    </div>
                }
            </div>

            <div className="discussion-tile-info-row">
               <div className="info-row-approval-section">
                    <div 
                        //  onClick={()=>handleClickLikeDiscussion()}
                        //  onKeyDown={()=>handleClickLikeDiscussion()}
                        onClick={()=>handleClickLike(discussion)}
                    >
                        <KeyboardArrowUpIcon sx={{ fontSize:'50px', margin:'0', 
                            color:(prevReaction.length > 0 && prevReaction[0]==='L') ? '#FFE74C' : '#F8F8F8' }}/>
                    </div>
                    <p>{ (tempLikes - tempDislikes) }</p>
                    <div 
                        onClick={()=>handleClickDislike()}
                        //  onClick={()=>handleClickDislikeDiscussion()}
                        //  onKeyDown={()=>handleClickDislikeDiscussion()}
                    >
                        <KeyboardArrowDownIcon sx={{ fontSize:'50px', margin:'0', 
                            color:(prevReaction.length > 0 && prevReaction[0]==='D') ? '#FFE74C' : '#F8F8F8' }}/>
                    </div>
                </div>
                <div className="info-row-title-section">
                    <Link to={`/discussion/${discussion.id}`} style={{ textDecoration:'none', color:'inherit'}}>
                        <>
                        {/* { gameDetails && <p>{gameDetails.name}</p>} */}
                        { discussion.title }
                        { discussion.taggedGames !== undefined &&
                            fetchingMultipleGames === false &&
                            <div className="info-row-tagged-games-section">
                            {
                                multipleGameData && multipleGameData.length > 0 &&
                                multipleGameData.map((game_info)=>(
                                    <div key={uuid()}
                                         className='info-row-tagged-game-tile'
                                    >
                                        { game_info.name }
                                    </div>
                                ))
                            }
                            </div>
                        }
                        </>
                    </Link>
                </div>
            </div>

            <div className="discussion-tile-action-row">
                <div className="action-row-cta"
                     onClick={()=>openSharingDialog()}
                     onKeyDown={()=>openSharingDialog()}
                >
                    Share
                </div>
                {/* <div className="action-row-cta">
                    Report
                </div> */}
                {
                        currentUser !== null && 
                        <Link to={'/report'} style={{ textDecoration:'none' }}
                            state={{
                                'item': discussion,
                                'reporter': currentUser.uid,
                                'type':'forum_discussion'
                            }}
                        >
                            <div className="action-row-cta">
                                Report
                            </div>
                        </Link> 
                    }
                {
                    currentUser && currentUser.uid!==null &&
                    discussion.userID === currentUser.uid &&
                    <div className="action-row-cta"
                         onClick={()=>handleOpenDeletionBox(discussion.id)}
                         onKeyDown={()=>handleOpenDeletionBox(discussion.id)}
                    >
                        Delete
                    </div>
                }
            </div>

        </div>
    )
}