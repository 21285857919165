// react states
import { useState } from 'react';
// firebase
// import { auth } from '../Firebase/firebase';
import { sendEmailVerification } from "firebase/auth";

export const useSendEmailVerification = () => {
    const [sendingMail, setSendingMail] = useState(false);
    const [sentStatus, setSentStatus] = useState(null);
    const [mailError, setMailError] = useState(null);

    const sendVerificationMail = async(current_user_object)=>{
        setSendingMail(true);

        sendEmailVerification(current_user_object)
        .then(() => {
            setSendingMail(false);
            setSentStatus('success');
            setMailError(null);
        }).catch((err)=>{
            setSendingMail(false);
            setSentStatus('fail');
            setMailError(err.message);
        });

        
    }

    return { sendingMail, sentStatus, mailError, sendVerificationMail };
}