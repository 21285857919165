// react states
import { useState } from 'react';
// firebase
import { db } from '../../../Firebase/firebase';
import { onSnapshot, collection, where, query, getDocs, limit, orderBy, startAfter } from 'firebase/firestore';


export const useCommentsFetch = (section)=>{
    const [fetchingComments, setFetchingComments] = useState(false);
    const [fetchError, setFetchError] = useState(null);
    const [fetchedComments, setFetchedComments] = useState([]);
    const [fetchedCommentsCount, setFetchedCommentsCount] = useState(0);

    const fetchComments = async(forumID, parentID)=>{
        var commentCollectionRef = null;
        if(section === 'forum'){
            commentCollectionRef = query(
                collection(db, 'ForumComments'),
                where('forumID','==',`${forumID}`),
                where('parent','==',parentID)
            );
        }else if(section === 'review'){
            commentCollectionRef = query(
                collection(db, 'ReviewComments'),
                where('reviewID','==',`${forumID}`),
                where('parent','==',parentID)
            );
        }
        const unsubComments = onSnapshot(commentCollectionRef, (comments) => {
            // console.log("Current data: ", comments.docs.length);
            setFetchingComments(true);
            var newComments = [];
            for(const currentComment of comments.docs){
                var tempCommentObject = {
                    'id': currentComment.id,
                    ...currentComment.data()
                }
                newComments.push(tempCommentObject);
            }

            setFetchedComments(newComments);
            setFetchedCommentsCount(newComments.length);
            setFetchingComments(false);
        },(commentError)=>{
            setFetchingComments(false);
            setFetchError(commentError.message);
            return;
        });

        return ()=>unsubComments()
    }

    // For a particular user comment
    const [fetchedUserComments, setFetchedUserComments] = useState([]);
    const [lastVisibleUserComment, setLastVisibleUserComment] = useState(null);
    const [isUserCommentsEmpty, setIsUserCommentsEmpty] = useState(true);

    const fetchUserCommentsFirstBatch = async(userid)=>{
        if(userid === null || userid === undefined){
            return;
        }else{
            setFetchingComments(true);
            var userCommentCollectionRef = null;
            if(section === 'forum'){
                userCommentCollectionRef = query(
                    collection(db, 'ForumComments'),
                    where('user_id','==',`${userid}`),
                    orderBy("createdAt","desc"),
                    limit(5)
                );
            }else if(section === 'review'){
                userCommentCollectionRef = query(
                    collection(db, 'ReviewComments'),
                    where('user_id','==',`${userid}`),
                    orderBy("createdAt","desc"),
                    limit(5)
                );
            }
            const userCommentsDocs = await getDocs(userCommentCollectionRef)
                                        .catch((err)=>{
                                            setFetchError(err.message);
                                            setFetchingComments(false);
                                            return;
                                        });
            
            const isCommentsPresent = userCommentsDocs.size === 0;
            if(!isCommentsPresent){
                setLastVisibleUserComment(userCommentsDocs.docs[userCommentsDocs.docs.length - 1]);
                setIsUserCommentsEmpty(false);
            }

            var newUserComments = [];
            for(const currentComment of userCommentsDocs.docs){
                    var tempCommentObject = {
                        'id': currentComment.id,
                        ...currentComment.data(),
                    }
                    newUserComments.push(tempCommentObject);
            }
            setFetchedUserComments(newUserComments);
            setFetchingComments(false);
        }
    }

    const fetchUserCommentsNextBatch = async(userid)=>{
        if(userid === null || userid === undefined){
            return;
        }else{
            setFetchingComments(true);
            var userCommentCollectionRef = null;
            if(section === 'forum'){
                userCommentCollectionRef = query(
                    collection(db, 'ForumComments'),
                    where('user_id','==',`${userid}`),
                    orderBy("createdAt","desc"),
                    startAfter(lastVisibleUserComment),
                    limit(10)
                );
            }
            else if(section === 'review'){
                userCommentCollectionRef = query(
                    collection(db, 'ReviewComments'),
                    where('user_id','==',`${userid}`),
                    orderBy("createdAt","desc"),
                    startAfter(lastVisibleUserComment),
                    limit(10)
                );
            }

            const userCommentsDocs = await getDocs(userCommentCollectionRef);
            
            const isCommentsPresent = userCommentsDocs.size === 0;
            
            if(!isCommentsPresent){
                setLastVisibleUserComment(userCommentsDocs.docs[userCommentsDocs.docs.length - 1]);
                setIsUserCommentsEmpty(false);
            }else{
                setLastVisibleUserComment(null);
                setIsUserCommentsEmpty(true);
            }

            var newUserComments = [];
            for(const currentComment of userCommentsDocs.docs){
                var tempCommentObject = {
                    'id': currentComment.id,
                    ...currentComment.data()
                }
                newUserComments.push(tempCommentObject);
            }


            setFetchedUserComments(fetchedUserComments => [...fetchedUserComments, ...newUserComments]);
            setFetchingComments(false);
        }
    }

    return { fetchComments, fetchUserCommentsFirstBatch, fetchUserCommentsNextBatch, fetchingComments, fetchError, 
             fetchedComments, fetchedCommentsCount, fetchedUserComments, isUserCommentsEmpty };
}