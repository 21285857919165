// react states
import { useState, useEffect } from 'react';
// firebase/database
import { httpsCallable } from 'firebase/functions';
import { fbFunctions } from '../../Firebase/firebase';

export const useGetTrendingReviews = ()=>{
    const [trendingReview, setTrendingReviews] = useState([]);
    const [loadingTrendingRevs, setLoadingTrendingRevs] = useState(false);
    const [trendingReviewsError, setTrendingReviewsError] = useState(null);

    const cacheKey = 'trendingReviews';

    const fetchTopReviews = async () => {

        // Checking if the reviews are already fetched, if yes,
        // then retreive then from the local source. If not,
        // Call the function to retreive them.
        const cachedData = sessionStorage.getItem(cacheKey);
        if(cachedData){
            setTrendingReviews(JSON.parse(cachedData));
        }
        else{
            setLoadingTrendingRevs(true);
            setTrendingReviewsError(null);
            
            const getTopGameReviews = httpsCallable(fbFunctions, "fetchTopReviews");
            try {
                const result = await getTopGameReviews();
                setTrendingReviews(result.data.topReviews);
                sessionStorage.setItem(cacheKey, JSON.stringify(result.data.topReviews));
            } catch (error) {
                setLoadingTrendingRevs(false);
                setTrendingReviewsError(error);
                console.error("Error fetching top reviews: ", error);
                return;
            }

            setLoadingTrendingRevs(false);
            setTrendingReviewsError(null);
        }
    };

    useEffect(()=>{
        fetchTopReviews();
    },[]);

    return { trendingReview, loadingTrendingRevs, trendingReviewsError };
}