/* eslint-disable react-hooks/exhaustive-deps */
// react states
import { useState, useEffect } from 'react';
// firebase
import { db } from '../../Firebase/firebase';
import { doc, getDoc } from 'firebase/firestore';

export const useGetUserReview = (reviewID)=>{
    
    const [fetchingReview, setFetchingReview] = useState(false);
    const [reviewDetails, setReviewDetails] = useState(null);
    const [reviewFetchError, setReviewFetchError] = useState(null);

    const getUserReview = async()=>{
        setFetchingReview(true);
        const reviewRef = doc(db, "GameReviews", `${reviewID}`);
        const reviewSnap = await getDoc(reviewRef);

        if(reviewSnap.exists()){
            const tempRevObj = {
                'id':reviewID,
                ...reviewSnap.data()
            }
            setReviewDetails(tempRevObj);
            setFetchingReview(false);
            setReviewFetchError(null);
            return;
        }else{
            setFetchingReview(false);
            setReviewFetchError("Can't find the review");
            setReviewDetails(null);
            return;
        }
    }

    useEffect(()=>{
        getUserReview();
    },[reviewID]);

    return { fetchingReview, reviewDetails, reviewFetchError };

}