// styles
import './user_review_write_scoring.css';

export default function UserReviewScoring({ userRevScore, setUserRevScore }){
    
    const handleScoringChange = (score)=>{
        setUserRevScore(score);
    }
    
    return(
        <div className="user-review-write-scoring-box">
            <p className='user-review-write-scoring-heading'>YOUR SCORE</p>
            <div className="user-review-scoring-row">
                <button className={userRevScore === 1 ? "user-rev-score-box active"
                                    : "user-rev-score-box"
                        }
                        onClick={()=>handleScoringChange(1)}
                >
                    1
                </button>

                <button className={userRevScore === 2 ? "user-rev-score-box active"
                                    : "user-rev-score-box"
                        }
                        onClick={()=>handleScoringChange(2)}
                >
                    2
                </button>

                <button className={userRevScore === 3 ? "user-rev-score-box active"
                                    : "user-rev-score-box"
                        }
                        onClick={()=>handleScoringChange(3)}
                >
                    3
                </button>

                <button className={userRevScore === 4 ? "user-rev-score-box active"
                                    : "user-rev-score-box"
                        }
                        onClick={()=>handleScoringChange(4)}
                >
                    4
                </button>

                <button className={userRevScore === 5 ? "user-rev-score-box active"
                                    : "user-rev-score-box"
                        }
                        onClick={()=>handleScoringChange(5)}
                >
                    5
                </button>

                <button className={userRevScore === 6 ? "user-rev-score-box active"
                                    : "user-rev-score-box"
                        }
                        onClick={()=>handleScoringChange(6)}
                >
                    6
                </button>

                <button className={userRevScore === 7 ? "user-rev-score-box active"
                                    : "user-rev-score-box"
                        }
                        onClick={()=>handleScoringChange(7)}
                >
                    7
                </button>

                <button className={userRevScore === 8 ? "user-rev-score-box active"
                                    : "user-rev-score-box"
                        }
                        onClick={()=>handleScoringChange(8)}
                >
                    8
                </button>

                <button className={userRevScore === 9 ? "user-rev-score-box active"
                                    : "user-rev-score-box"
                        }
                        onClick={()=>handleScoringChange(9)}
                >
                    9
                </button>

                <button className={userRevScore === 10 ? "user-rev-score-box active"
                                    : "user-rev-score-box"
                        }
                        onClick={()=>handleScoringChange(10)}
                >
                    10
                </button>
                
            </div>
        </div>
    );
}