// react states
import { useState, useEffect } from 'react';
// firebase
import { db } from '../../Firebase/firebase';
import { collection, getDocs, orderBy, query } from 'firebase/firestore';


export const useGetAllGenres = ()=>{
    const [loadingAllGenres, setLoadingAllGenres] = useState(false);
    const [allGenres, setAllGenres] = useState([]);
    const [allGenreError, setAllGenreError] = useState(null);

    const allGenreKey = 'all_genres_list';

    const getAllGeneres = async()=>{
        const cachedGenreList = sessionStorage.getItem(allGenreKey);
        if(cachedGenreList){
            setAllGenres(JSON.parse(cachedGenreList));
        }else{
            setLoadingAllGenres(true);
            const genreCollectionQuery = query(collection(db, 'Genres'),orderBy('name','asc'));
            const genresDocSnaps = await getDocs(genreCollectionQuery)
                                   .catch((err)=>{
                                        setAllGenreError(err.message);
                                        setLoadingAllGenres(false);
                                        return;
                                   });

            const genreArr = [];
            for(const genre of genresDocSnaps.docs){
                var genreObj = {
                    'id':genre.id,
                    ...genre.data()
                }
                genreArr.push(genreObj);
            }

            if(genreArr.length > 0){
                sessionStorage.setItem(allGenreKey, JSON.stringify(genreArr));
            }

            setAllGenres(genreArr);
            setLoadingAllGenres(false);
            setAllGenreError(null);
        }
    }

    useEffect(()=>{
        getAllGeneres();
    },[])

    return { allGenres, allGenreError, loadingAllGenres };
}