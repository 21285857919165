/* eslint-disable react-hooks/exhaustive-deps */
// styles
import { useEffect, useState } from 'react';
import './gameProfile.css';
import { Timestamp } from 'firebase/firestore';
import GameProfilePlatforms from './gameProfilePlatforms';
import { useGetGenre } from '../../../../Hooks/Genres/useGetGenre';
import GameProfileGenre from './gameProfileGenres';

export default function GameProfileArea({ gameDetails }){

    // To set the date of the game
    const [gameDate, setGameDate] = useState('');

    const { multipleGenreData, getMultipleGenreData } = useGetGenre();
    // const { getMultipleGenreData } = useGetMultipleGenreData();

    useEffect(()=>{
        if(gameDetails !== null && gameDetails !== undefined){
            const dateObj = gameDetails.year_of_release;
            if(dateObj instanceof Timestamp || 
                (dateObj && typeof obj === 'object' && 
                    typeof dateObj === 'function' && 
                    typeof dateObj === 'function')
            ){
                setGameDate(gameDetails.year_of_release.toDate().toDateString());
            }else{
                setGameDate(gameDetails.year_of_release);
            }

            getMultipleGenreData(gameDetails.genres);
        }        
    },[gameDetails])
    

    return(
        <>
            <p className='game-heading'>{gameDetails.name}</p>
            <p className='game-sub-heading'>{gameDetails.studio_name}</p>
            <p className='game-sub-sub-heading'>{gameDetails.publisher_name}</p>
            <p className='game-sub-sub-heading'>{gameDate}</p>
            <div className="game-profile-sub-heading">Genres</div>
            <div className="game-platforms-container">
            {
                multipleGenreData && multipleGenreData.length > 0
                && multipleGenreData.map((genre, index)=>(
                    <div key={index} className='game-platform'>
                    {
                        (index < multipleGenreData.length - 1) &&
                        <>
                        <GameProfileGenre key={index} gameGenreDetails={genre}/>
                        <p className='game-platform-comma'>,</p>
                        </>
                    }
                    {
                        (index === multipleGenreData.length - 1) &&
                        <GameProfileGenre key={index} gameGenreDetails={genre}/>
                    }
                    </div>
                ))
            }
            </div>
            <p className='game-profile-sub-heading'>Platforms</p>
            <div className="game-platforms-container">
            {
                gameDetails.platforms.map((platform, index)=>(
                    <div key={index} className='game-platform'>
                    {
                        (index < gameDetails.platforms.length - 1) &&
                        <>
                        <GameProfilePlatforms key={index} platformAcronym={platform}/>
                        <p className='game-platform-comma'>,</p>
                        </>
                    }
                    {
                        (index === gameDetails.platforms.length - 1) &&
                        <GameProfilePlatforms key={index} platformAcronym={platform}/>
                    }
                    </div>
                ))
            }
            </div>
        </>
    )
}