// styles
import './trendingForumCarousel.css';
// react states
import { useState } from 'react';
// mui components
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
// custom hooks
import { useGetTrendingForums } from '../../../../Hooks/Homepage/useGetTrendingForums';
// custom components
import { useSelector } from 'react-redux';
import { selectUser } from '../../../../Features/userSlice';
import PrevDiscussionTile from '../../../Forums/AllForums/prev_discussion_tile';

export default function TrendingForumCarousel(){

    // To get the logged in user data and id
    const loggedInUserID = useSelector(selectUser);
    
    const[currentIndex, setCurrentIndex] = useState(0);

    // custom hook to obtain trending forums
    const { trendingForums, loadingTrendingForums } = useGetTrendingForums();

    // console.log(trendingForums);

    const goToPreviousSlide = ()=>{
        if(trendingForums && trendingForums.length > 0){
            setCurrentIndex((prevIndex) => (prevIndex - 1 + trendingForums.length) % trendingForums.length);
        }
    }
    // To go to the next review
    const goToNextSlide = ()=>{
        if(trendingForums && trendingForums.length > 0){
            setCurrentIndex((prevIndex) => (prevIndex + 1) % trendingForums.length);
        }
    }

    return(
        <div className="trending-forum-carousel-container">
            <div className="trending-forum-carousel">
                <button className='trending-forum-carousel-nav-btns left'
                        onClick={()=>goToPreviousSlide()}
                >
                    <ArrowBackIosIcon style={{ 'color':'#FFE74C', fontSize:'3rem' }}/>
                </button>

                <div className="forum-carousel-content">
                {
                    loadingTrendingForums === false &&
                    trendingForums && trendingForums.length > 0 &&
                    trendingForums.map((forum, index)=>(
                        <div key={forum.id} className={(currentIndex === index) ? "review-carousel-item active":"review-carousel-item"}>
                            <PrevDiscussionTile discussion={{'id': forum.id,...forum.forumData}} currentUser={loggedInUserID}/>
                        </div>
                    ))
                }
                </div>

                <button className='trending-forum-carousel-nav-btns right'
                        onClick={()=>goToNextSlide()}
                >
                    <ArrowForwardIosIcon style={{ 'color':'#FFE74C', fontSize:'3rem' }}/>
                </button>
            </div>
            <div className="trending-forum-container-mobile-navigations">
                <button className='trending-forum-mobile-nav-btns'
                        onClick={()=>goToPreviousSlide()}
                >
                    <ArrowBackIosIcon style={{ 'color':'#FFE74C', fontSize:'3rem' }}/>
                </button>
                <button className='trending-forum-mobile-nav-btns'
                        onClick={()=>goToNextSlide()}
                >
                    <ArrowForwardIosIcon style={{ 'color':'#FFE74C', fontSize:'3rem' }}/>
                </button>
            </div>
        </div>
    )
}