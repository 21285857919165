// styles
import './editorPickTile.css';
// react router dom
import { useNavigate } from 'react-router-dom';

export default function EditorPickTile({ editorPickGame }){
    
    const epNav = useNavigate();

    const handleGoToWriteReview = ()=>{
        if(editorPickGame !== null && editorPickGame !== undefined){
            epNav(`/write_game_review/${editorPickGame.game_id}`);
        }else{
            return;
        }
    }
    
    const handleGoToGamepage = ()=>{
        if(editorPickGame !== null && editorPickGame !== undefined){
            epNav(`game_page/${editorPickGame.game_id}`);
        }else{
            return;
        }
    }

    return(
        <div className='editor-pick-tile'>
            <div className="editor-pick-tile-bg">
            {   
                editorPickGame && (editorPickGame.cover_image_url !== null || editorPickGame.cover_image_url !== undefined) &&
                <img src={editorPickGame.cover_image_url} alt={editorPickGame.name}/>
            }
            </div>
            <div className="editor-pick-tile-overlay"></div>
            <div className="editor-pick-reason-area">
                <div className="editor-pick-text-container">
                    <p className='ep-reason-heading'>{editorPickGame.name}</p>
                    <p className='ep-reason-text'>{editorPickGame.reason}</p>
                </div>
                <div className="ep-tile-action-btns">
                    <button className="ep-tile-cta"
                            onClick={()=>handleGoToWriteReview()}
                    >
                        Write a review
                    </button>
                    <button className="ep-tile-secondary-cta"
                            onClick={()=>handleGoToGamepage()}
                    >
                        Go to gamepage
                    </button>
                </div>
            </div>
        </div>
    )
}