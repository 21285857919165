// styles
import { useNavigate } from 'react-router-dom';
import { useGetAllConsoles } from '../../../Hooks/Consoles/useGetAllConsoles';
import './consoleDropdownMenu.css';

export default function ConsoleDropdownMenu(){

    // custom hook to get all the consoles
    const { allConsoleData, loadingAllConsoles } = useGetAllConsoles();

    // To navigate to a particular console
    const consoleDropdownNav = useNavigate();
    const handleNavigateToConsole = (consoleID)=>{
        consoleDropdownNav(`/consoles/${consoleID}`);
    }

    return(
        <div className="navbar-console-dropdown">
        {
            loadingAllConsoles === false &&
            allConsoleData && allConsoleData.length > 0 
            && allConsoleData.map((navConsole)=>(
                <button key={navConsole.id}
                        className="navbar-console-dropdown-tile"
                        onClick={()=>handleNavigateToConsole(navConsole.id)}
                >
                    {navConsole.name}
                </button>
            ))
        }
        </div>
    );
}