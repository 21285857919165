// react states
import { useState } from 'react';
// firebase
import { db } from '../../../Firebase/firebase';
import { addDoc, collection, serverTimestamp } from 'firebase/firestore';
import { sendNotifications } from '../../../Firebase/fb-messaging';
// react-redux
import { useSelector } from 'react-redux';
import { selectUser } from '../../../Features/userSlice';
// react router dom
import { useNavigate } from 'react-router-dom';

export const useCommentsPost = (section)=>{
    const [posting, setPosting] = useState(false);
    const [postingError, setPostingError] = useState(null);
    const [postingStatus, setPostingStatus] = useState(false);

    const currentSignedInUser = useSelector(selectUser);

    // to navigate user to signup if not signed in.
    const authNav = useNavigate();

    const postComment = async(commentObject)=>{
        setPostingStatus(false);
        setPostingError(null);
        if(currentSignedInUser === null ||
            JSON.stringify(currentSignedInUser) === '{}'   
        ){
            authNav('/user_auth');
            return;
        }
        else{
            setPosting(true);
            var collectionRef = '';
            var finalCommentObj = {};
            if(section === 'forum'){
                collectionRef = collection(db, `ForumComments`);
                finalCommentObj = {
                    'approved':true,
                    'comment':commentObject.commentText,
                    'commentType':commentObject.commentType,
                    'createdAt':serverTimestamp(),
                    'deleted':false,
                    'forumID':commentObject.forumID,
                    'user_id':currentSignedInUser.uid,
                    // 'game_id':commentObject.gameID,
                    'taggedGames': commentObject.taggedGames,
                    'parent': commentObject.parent,
                    'likes':0,
                    'dislikes':0
                }
            }
            else if(section === 'review'){
                collectionRef = collection(db, `ReviewComments`);
                finalCommentObj = {
                    'approved':true,
                    'comment':commentObject.commentText,
                    'commentType':commentObject.commentType,
                    'createdAt':serverTimestamp(),
                    'deleted':false,
                    'reviewID':commentObject.reviewID,
                    'user_id':currentSignedInUser.uid,
                    // 'game_id':commentObject.gameID,
                    'taggedGames': commentObject.taggedGames,
                    'parent': commentObject.parent,
                    'likes':0,
                    'dislikes':0
                }
            }
            
            await addDoc(collectionRef, finalCommentObj).then(()=>{
                setPostingError(null);
                setPosting(false);
                setPostingStatus(true);
            }).catch((err)=>{
                setPostingError(err.message);
                setPosting(false);
                return;
            });

            sendNotifications(
                currentSignedInUser.uid, 
                commentObject.parentCommentUserID,
                (section === 'forum')?'discussion':'review',
                (section === 'forum')?`https://thegamerbroz.com/discussion/${commentObject.forumID}`:`https://thegamerbroz.com/full_review/${commentObject.reviewID}`,
                'comment', 
                (commentObject.parent === null)?((section === 'forum')?'discussion':'review'):'comment', 
                (section === 'forum')?commentObject.forumID : commentObject.reviewID    
            );

        }
        
    }

    return { posting, postingStatus, postingError, setPostingError, postComment };
}
