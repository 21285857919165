import { createSlice } from '@reduxjs/toolkit';

const databaseGameDataSlice = createSlice({
    'name':'allGamesData',
    initialState : {
        all_games:null
    },

    reducers:{
        setDatabaseGamesData:(state, action)=>{
            state.all_games = action.payload;
        },
        unsetDatabaseGamesData:(state)=>{
            state.all_games = [];
        }
    }
})

export const { setDatabaseGamesData, unsetDatabaseGamesData } = databaseGameDataSlice.actions;
export const selectDatabaseGamesData = (state) => state.allGamesData.all_games;

export default databaseGameDataSlice.reducer;