/* eslint-disable react-hooks/exhaustive-deps */
// react states
import { useState, useEffect } from 'react';
// database/firebase
import { getDoc, doc } from 'firebase/firestore';
import { db } from '../../Firebase/firebase';

export const useGetArticleData = (articleID) => {
    const [articleData, setArticleData] = useState(null);
    const [loadingArticle, setLoadingArticle] = useState(false);
    const [articleError, setArticleError] = useState(null);

    var articleKey = `${articleID}`;

    const getArticleData = async(article_id)=>{
        articleKey = article_id;

        const articleCachedData = sessionStorage.getItem(articleKey);
        if(articleCachedData){
            setArticleData(JSON.parse(articleCachedData));
            return JSON.parse(articleCachedData);
        }else{
            setLoadingArticle(true);
            setArticleError(null);

            const articleDocRef = doc(db, 'Articles', `${article_id}`);
            const articleDocSnap = await getDoc(articleDocRef);

            if(articleDocSnap.exists()){
                const articleObject = {
                    'id':articleDocSnap.id,
                    ...articleDocSnap.data()
                }
                setArticleData(articleObject);
                setLoadingArticle(false);
                setArticleError(null);
                sessionStorage.setItem(articleKey, JSON.stringify(articleObject));
                return articleObject;
            }else{
                setLoadingArticle(false);
                setArticleError("Article not found. It might've been deleted.");
                return;
            }
        }
    }

    useEffect(()=>{
        if(articleID !== null && articleID !== undefined){
            getArticleData(articleID);
        }
    },[articleID])

    return { articleData, loadingArticle, articleError, getArticleData };
}