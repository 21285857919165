// database
import { db } from '../../Firebase/firebase';
import { doc, getDoc } from "firebase/firestore";
// React query
import { useQuery } from '@tanstack/react-query';

const fetchGame = async(game_id)=>{
    if (!game_id){
        throw new Error("Game does not exist.");
    }

    const gameRef = doc(db, "Games", `${game_id}`);
    const gameSnap = await getDoc(gameRef);
    
    if(!gameSnap.exists()){
        throw new Error('Game does not exist.');
    }
    
    return {'id':gameSnap.id, ...gameSnap.data()};
}

export const useFetchGameDetails = (gameid)=>{
    return useQuery({
        queryKey: ['game', gameid],
        queryFn: () => fetchGame(gameid),
        enabled: !!gameid,
    });
}