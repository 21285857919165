// react states
import { useState, useEffect } from 'react';
// database
import { db } from '../../Firebase/firebase';
import { collection, getDocs, getDoc, doc } from 'firebase/firestore';

export const useGetEditorsPick = ()=>{
    const [editorPicks, setEditorPicks] = useState([]);
    const [loadingEditorsPick, setLoadingEditorPicks] = useState(false);
    const [editorPickError, setEditorPickError] = useState(null);

    const editorPickCacheKey = 'eP';

    const fetchEditorPicks = async()=>{
        const cachedData = sessionStorage.getItem(editorPickCacheKey);
        if(cachedData){
            setEditorPicks(JSON.parse(cachedData));
        }else{
            setLoadingEditorPicks(true);
            setEditorPickError(null);

            const editorPickSnapshots = await getDocs(collection(db,'EditorsPick'));

            const tempEditorPickArr = [];
            for(const ep of editorPickSnapshots.docs){
                const gameID = ep.data().game_id;
                // Fetch game data
                const epGameData = await getDoc(doc(db, 'Games', `${gameID}`));

                // Make the object and add it to the array
                const epObject = {
                    ...ep.data(),
                    // 'reason':ep.data().reason,
                    ...epGameData.data()
                }

                tempEditorPickArr.push(epObject);
            }

            setEditorPicks(tempEditorPickArr);
            sessionStorage.setItem(editorPickCacheKey, JSON.stringify(tempEditorPickArr));
            setLoadingEditorPicks(false);
        }
    }

    useEffect(()=>{
        fetchEditorPicks();
    },[])

    return { editorPickError, editorPicks, loadingEditorsPick };
}