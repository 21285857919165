import { useState } from 'react';
import { useSelector } from 'react-redux';
import { selectUserData } from '../../Features/userPrivateDataSlice';

export var allUserCollectionGames = [];
export var allUserWishlistGames = [];

export const useGetUserCollections = ()=>{
    const [gettingGames, setGettingGames] = useState(false);

    // The state of user.
    const currentUserData = useSelector(selectUserData);

    const getUserGameCollection = ()=>{
        setGettingGames(true);
        if(currentUserData !== null || currentUserData !== undefined){
            allUserCollectionGames = currentUserData.game_collection;
        }
        setGettingGames(false);
    }

    const getUserWishlistCollection = ()=>{
        setGettingGames(true);
        if(currentUserData !== null && currentUserData !== undefined){
            allUserWishlistGames = currentUserData.wishlist;
        }
        setGettingGames(false);
    }

    return { gettingGames, getUserGameCollection, getUserWishlistCollection };
}