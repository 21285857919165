/* eslint-disable react-hooks/exhaustive-deps */
// styles
import './discussionTile.css';
// react states
import { useEffect, useState } from 'react';
// react routing
import { Link, useNavigate } from 'react-router-dom';
// custom hooks
import { useGetUserProfile } from '../../../Hooks/useGetUserProfile';
import { useSendEmailNotifications } from '../../../Hooks/EmailNotifications/useSendEmailNotifications';
// utility functions
import { getReadableTime } from '../../TimeVarianceUtilityComponent/timingFunction';
// import { useGameDetailsFetch } from '../../../Hooks/useGameDetailsFetch';
import { useFetchMultipleGameData } from '../../../Hooks/GameData/useFetchMultipleGameData';
import { useDiscussionOperations } from '../../../Hooks/Forums/useDiscussionOperations';
import { sendNotifications } from '../../../Firebase/fb-messaging';
import { selectUserData } from '../../../Features/userPrivateDataSlice';
// mui icons
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';


export default function DiscussionTile({ discussionTopic, currentUser }){

    // console.log('current user in discussion tile => ',currentUser);
    // console.log('Discussion topic in discussion ttile => ',discussionTopic)

    // For EMAIL NOTIFICATIONS
    const { makeEmailHTML } = useSendEmailNotifications();

    // const { gameDetails } = useGameDetailsFetch(discussionTopic.gameID);
    const { fetchingMultipleGames, fetchMultipleGameData, multipleGameData } = useFetchMultipleGameData();
    const { loadingUser, userData, getProfileDetails } = useGetUserProfile();
    const currentUserData = useSelector(selectUserData);

    // console.log('gameDetails => ',gameDetails);

    // to get the time
    const [forumCreationTime, setForumCreationTime] = useState('');

    useEffect(()=>{
        if(discussionTopic !== null){
            // console.log('discussion topic => ',discussionTopic);
            getProfileDetails(discussionTopic.userID,null);
            if(discussionTopic.taggedGames !== undefined){
                fetchMultipleGameData(discussionTopic.taggedGames)
            }
            const createdAt = getReadableTime(discussionTopic.createdAt);
            setForumCreationTime(createdAt);
        }
    },[discussionTopic]);

    // console.log('MG => ',multipleGameData);

    // To get the discussion reactions
    const { currentLikes, currentDislikes, userForumReaction
        , likeDiscussion, dislikeDiscussion
      } = useDiscussionOperations(discussionTopic.id, currentUser!==null ? currentUser.uid : null);
    // A stack to mantain the last reactions of the user
    const [prevReaction, setPrevReaction] = useState([]);
    const [tempLikes, setTempLikes] = useState(currentLikes);
    const [tempDislikes, setTempDislikes] = useState(currentDislikes);

    useEffect(()=>{
        setPrevReaction(userForumReaction);
        setTempLikes(currentLikes);
        setTempDislikes(currentDislikes);
    },[userForumReaction, currentLikes, currentDislikes]);

    // To like the discussion
    const handleClickLikeDiscussion = ()=>{
        if(currentUser === null || currentUserData === null)
            return;
        else{
            likeDiscussion(discussionTopic.id, currentUser.uid);
            var tempStack = prevReaction;
            if (prevReaction.length === 0) {
            let currentLike = tempLikes;
            currentLike += 1;
            setTempLikes(currentLike);
            tempStack.unshift("L");
            setPrevReaction(tempStack);

            sendNotifications(
                currentUser.uid,
                discussionTopic.userID,
                'discussion',
                `https://thegamerbroz.com/discussion/${discussionTopic.id}`,
                'upvote','discussion',
                discussionTopic.id
            );
            makeEmailHTML('like','discussion',discussionTopic.taggedGames[0],
                `https://thegamerbroz.com/discussion/${discussionTopic.id}`,
                discussionTopic
            );
            } else if (prevReaction.length > 0 && prevReaction[0] === "L") {
                // console.log("You have already liked");
                tempStack.shift();
                let currentLikes = tempLikes;
                currentLikes -= 1;
                setTempLikes(currentLikes);
                setPrevReaction(tempStack);
            } else if (prevReaction.length > 0 && prevReaction[0] === "D") {
                // console.log("You previously disliked");
                tempStack.shift();
                let currentLikes = tempLikes;
                let currentDislikes = tempDislikes;
                currentDislikes -= 1;
                currentLikes += 1;
                setTempLikes(currentLikes);
                setTempDislikes(currentDislikes);
                tempStack.push("L");
                setPrevReaction(tempStack);

                sendNotifications(
                    currentUser.uid,
                    discussionTopic.userID,
                    'discussion',
                    `https://thegamerbroz.com/discussion/${discussionTopic.id}`,
                    'upvote','discussion',
                    discussionTopic.id
                );
                makeEmailHTML('like','discussion',discussionTopic.taggedGames[0],
                    `https://thegamerbroz.com/discussion/${discussionTopic.id}`,
                    discussionTopic
                );
            }
        }
    }

    const handleClickDislikeDiscussion = ()=>{
        if(currentUser === null || currentUserData === null)
            return;
        else{
            dislikeDiscussion(discussionTopic.id, currentUser.uid);
            var tempStack = prevReaction;
            if (prevReaction.length === 0) {
                let currentDislike = tempDislikes;
                currentDislike += 1;
                setTempDislikes(currentDislike);
                tempStack.unshift("D");
                setPrevReaction(tempStack);
            } else if (prevReaction.length > 0 && prevReaction[0] === "L") {
                // console.log("You previously liked");
                tempStack.shift();
                let currentLikes = tempLikes;
                let currentDislikes = tempDislikes;
                currentLikes -= 1;
                currentDislikes += 1;
                setTempDislikes(currentDislikes);
                setTempLikes(currentLikes);
                tempStack.push("D");
                setPrevReaction(tempStack);
            } else if (prevReaction.length > 0 && prevReaction[0] === "D") {
                // console.log("You have already disliked");
                tempStack.shift();
                let currentDislikes = tempDislikes;
                currentDislikes -= 1;
                setTempDislikes(currentDislikes);
                setPrevReaction(tempStack);
            }
        }
    }
    
    const gameNav = useNavigate();
    const navigateToGamePage = (game_id)=>{
        gameNav(`/game_page/${game_id}`,{
            gameid : game_id
        })
    }

    return(
        <div className='forum-discussion-tile'>
            {
                // gameDetails !== null &&
                <Helmet>
                    <title>
                        {`${discussionTopic.title}`}
                    </title>
                    <meta name='description' content={`${discussionTopic.description}`}/>
                    <meta name='keywords' content={`forum, game, gaming, ${discussionTopic.title}`}/>
                </Helmet>
            }
            
            <div className="discussion-user">
            {
                loadingUser && <div className="user-loader"></div>
            }
            {
                !loadingUser && userData &&
                <div className="discussion-user-profile">
                    <Link to={`/user_profile/${discussionTopic.userID}`} style={{ textDecoration:'none', display:'flex', justifyContent:'flex-start' }}>
                        <div className="user-image">
                        {
                            userData.profile_picture !== null &&
                            <img src={userData.profile_picture} alt=""/>
                        }
                        {
                            userData.profile_picture === null &&
                            <p>{userData.Name.substring(0,1).toUpperCase()}</p>
                        } 
                        </div>
                        <div className="discussion-user-details">
                            <p>{userData.Name}</p>
                            {/* <p>{discussionTopic.createdAt.toDate().toDateString()}</p> */}
                            { forumCreationTime !== '' && forumCreationTime.length > 0 && <p>{forumCreationTime}</p>}
                        </div>
                    </Link>
                </div>
                
            }
            {
                discussionTopic && discussionTopic.spoilers===true &&
                <div className="discussion-spoiler-prompt">
                    Spoiler alert
                </div>
            }
            </div>

            <div className="discussion-main-content">
                <div className="discussion-voting-area">
                    <KeyboardArrowUpIcon style={{ fontSize:'48px',
                                        color:(prevReaction.length > 0 && prevReaction[0]==='L') ? '#FFE74C' : '#F8F8F8' }}
                                         onClick={()=>handleClickLikeDiscussion()}
                    />
                    <p>{(tempLikes - tempDislikes)}</p>
                    <KeyboardArrowDownIcon style={{ fontSize:'48px', 
                                                color:(prevReaction.length > 0 && prevReaction[0]==='D') ? '#FFE74C' : '#F8F8F8' }}
                                           onClick={()=>handleClickDislikeDiscussion()}
                    />
                </div>
                <div className="discussion-texts-area">
                    <p className='discussion-title'>
                        { discussionTopic.title }
                    </p>
                    <div className="title-separator"></div>
                    <div
                        className='discussion-desc'
                        dangerouslySetInnerHTML={{ __html:discussionTopic.description }}
                    >
                    </div>
                    {
                        fetchingMultipleGames === false &&
                        <div className="discussion-tagged-games">
                        {
                            multipleGameData && multipleGameData.length > 0 &&
                            multipleGameData.map((disc_game)=>(
                                <div className="discussion-tagged-game-tile"
                                    key={disc_game.id}
                                    onClick={()=>navigateToGamePage(disc_game.id)}
                                    onKeyDown={()=>navigateToGamePage(disc_game.id)}
                                >
                                    { disc_game.name }
                                </div>
                            ))
                        }
                        </div>
                    }
                    
                </div>
            </div>
            
        </div>
    )
}