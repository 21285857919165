/* eslint-disable react-hooks/exhaustive-deps */
// react states
import { useState, useEffect } from 'react';
// utility
import { matchStrings } from '../../Components/SearchpageComponents/SearchingFunction/searchFunctionality';

export const useGameSearching = (searchTerm, list=[])=>{
    const [searching, setSearching] = useState(false);
    const [finalSearchList, setFinalSearchList] = useState([]);
    

    useEffect(()=>{
        setFinalSearchList([]);
        const searchInList = ()=>{
            setSearching(true);
            if(list.length === 0)
                return;

            for(const game of list){
                if(matchStrings(game.name.toUpperCase(), searchTerm.trim().toUpperCase())){
                    setFinalSearchList(finalSearchList => [...finalSearchList, game]);
                }    
            }
        }

        if(searchTerm !== '' && searchTerm.trim() !== ''){
            searchInList();
        }
    },[searchTerm])

    return { finalSearchList, searching };
}