// styles
import './gameProfilePlatforms.css';
// react router dom
import { useNavigate } from 'react-router-dom';
// custom hook(s)
import { useGetConsoleFromAcronym } from '../../../../Hooks/Consoles/useGetConsoleFromAcronym';


export default function GameProfilePlatforms({ platformAcronym }){
    
    // To get the console id from the acronym displayed
    const { consoleID } = useGetConsoleFromAcronym(platformAcronym);

    // To navigate to the console page
    const gamePlatformNavigate = useNavigate();
    const handleConsoleNavigation = ()=>{
        if(consoleID !== null && consoleID !== undefined){
            gamePlatformNavigate(`/consoles/${consoleID}`);
        }
    }

    return(
        <p className='game-platform-cta'
                onClick={()=>handleConsoleNavigation()}
        >
            {platformAcronym}
        </p>
    )
}