/* eslint-disable react-hooks/exhaustive-deps */
// styles
import './trendingGameBanner.css';
// react states
import { useState, useRef } from 'react'; 
// import { useRef } from 'react';
// custom components
import { useRecentReleases } from '../../../Hooks/useRecentReleases';
// import RatingButton from '../../CTAButtons/Ratings/ratingsBtn';
// react router dom
import { useNavigate } from 'react-router-dom';
// mui icon
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';

export default function HottestGameBanner(){

    const { loadingReleases, hottestGame } = useRecentReleases();

    // For the hovering part
    const hottestGameTextRef = useRef(null);

    const handleIsHovering = ()=>{
        hottestGameTextRef.current.classList.add('show');
    }
    const handleNotHovering = ()=>{
        hottestGameTextRef.current.classList.remove('show');
    }
    
    const hottestGameNav = useNavigate();
    const navigateToHottestGamePage = ()=>{
        hottestGameNav(`/game_page/${hottestGame.game_id}`);
    }

    const [muted, setMuted] = useState(true);
    const handleVolumeOn = ()=>{
        setMuted(false);
    }
    const handleVolumeOff = ()=>{
        setMuted(true);
    }

    const toggleBannerVolume = ()=>{
        if(muted === true){
            handleVolumeOn();
        }
        if(muted === false){
            handleVolumeOff();
        }
    }

    return(
        <div className='hottest-game-container'>
            <p className='hottest-game-heading'>Hottest Game In Town</p>
            {
                loadingReleases === false && hottestGame && 
                <div className="hottest-game-area"
                     onMouseOver={handleIsHovering}
                     onMouseLeave={handleNotHovering}
                     onFocus={()=>handleIsHovering()}
                >
                    <div className="hottest-game-media-part">
                    {
                        hottestGame.trailer !== '' &&
                        <video src={hottestGame.trailer} autoPlay muted={muted} loop>
                            <track></track>
                            <meta itemProp="name" content={hottestGame.name}></meta>
                            <meta itemProp="description" content={`${hottestGame.name} 6 trailer, Rockstar Games, official trailer`}></meta>
                        </video>
                    }
                    {
                        hottestGame.trailer === '' &&
                        <img src={hottestGame.cover_image_url} alt={hottestGame.cover_image_url}></img>
                    }
                    </div>
                    <div className="hottest-game-text-part"
                         ref={hottestGameTextRef}
                    >
                        <p className='hottest-game-text-part-heading'>{hottestGame.name}</p>
                        <p className='hottest-game-subheading'>{hottestGame.publisher_name}</p>
                        <div className="hottest-game-gamepage-cta"
                             onClick={()=>navigateToHottestGamePage()}
                             onKeyDown={()=>navigateToHottestGamePage()}
                        >
                            <p className='hottest-game-txt-btn'>GO TO GAMEPAGE</p>
                            <ArrowForwardIosIcon style={{ color:'#FFE74C' }}/>
                        </div>

                        {/* Video volume toggle */}
                        <div className="banner-volume-control-container">
                        {
                            muted === true &&
                            <VolumeOffIcon className='banner-volume-btn'
                                   onClick={()=>toggleBannerVolume()}
                            />
                        }
                        {
                            muted === false &&
                            <VolumeUpIcon className='banner-volume-btn'
                                   onClick={()=>toggleBannerVolume()}
                            />
                        }
                        </div>

                    </div>
                    {/* <div className="hottest-game-area-actions">
                        <RatingButton gameID={hottestGame.game_id} filled={true}/>
                    </div> */}
                </div>
            }
            {
                loadingReleases === true && 
                <div className="hottest-game-loading"></div>
            }
        </div>
    );
}