/* eslint-disable react-hooks/exhaustive-deps */
// styles
import './navbar.css';

// react states
import React, { useState, useEffect, useRef } from 'react';

// react-router
import { useNavigate, Link } from 'react-router-dom';

// firebase
import { auth } from '../Firebase/firebase';
import { signOut } from 'firebase/auth';

// icons
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SearchIcon from '@mui/icons-material/Search';
import LogoutIcon from '@mui/icons-material/Logout';
import SettingsIcon from '@mui/icons-material/Settings';
import MenuIcon from '@mui/icons-material/Menu';
import NotificationsIcon from '@mui/icons-material/Notifications';
import CloseIcon from '@mui/icons-material/Close';
// import CircleIcon from '@mui/icons-material/Circle';

// THE GAMER BROS ICON
import mainLogo from '../assets/Logo/TGB-LOGO.png';

// react slices and redux
import { selectUserData, unsetPrivateData } from '../Features/userPrivateDataSlice';
import { selectUser, logout } from '../Features/userSlice';
import { useSelector, useDispatch } from 'react-redux';
import NotificationSidebar from '../Components/Notifications/notificationSidebar';
import { useNotifications } from '../Hooks/Notifications/useNotifications';
import ConsoleDropdownMenu from '../Components/ConsolesPage/ConsoleDropdown/consoleDropdownMenu';
import { idvar } from '../Components/ConsolesPage/csid';
import { gidvar } from '../Components/GenreComponents/gsid';
import GenreDropdown from '../Components/GenreComponents/GenreDropdown/genreDropdown';

export default function Navbar(){

    const current_user = useSelector(selectUserData);
    const current_user_id = useSelector(selectUser);
    const [logInUser, setLogInUser] = useState(null);

    const profileNavigate = useNavigate();
    const userDispatch = useDispatch();

    useEffect(()=>{
        if(Object.keys(current_user).length > 0){
            setLogInUser(current_user);
        }else if(Object.keys(current_user).length === 0){
            setLogInUser(null);
        }
    },[current_user])

    // console.log('current user in navbar => ',current_user_id);
    const [openProfileOptions, setOpenProfileOptions] = useState(false);
    const handleOpenPO = ()=>{
        setOpenProfileOptions(true);
    }
    const handleClosePO = ()=>{
        setOpenProfileOptions(false);
    }

    const togglePOBox = ()=>{
        if(openProfileOptions === true){
            handleClosePO();
        }
        if(openProfileOptions === false){
            handleOpenPO();
        }
    }

    const profileOptionBoxRef = useRef(null);
    const handleClickOutsidePOBox = (event)=>{
        if(profileOptionBoxRef.current && !profileOptionBoxRef.current.contains(event.target)){
            handleClosePO();
        }
    }

    useEffect(()=>{
        if (openProfileOptions) {
            document.addEventListener('click', handleClickOutsidePOBox);
        } else {
            document.removeEventListener('click', handleClickOutsidePOBox);
        }
        return () => {
            document.removeEventListener('click', handleClickOutsidePOBox);
        };
    },[openProfileOptions])

    const onAuthPrompt = ()=>{
        profileNavigate('/user_auth');
        handleClosePO();
    }

    // To handle user logout
    const handleUserLogout = async()=>{
        signOut(auth).then(()=>{
            userDispatch(logout());
            userDispatch(unsetPrivateData());
            profileNavigate('/');
            handleClosePO();
        });
    }

    // to send user to settings page
    const sendToUserSettings = ()=>{
        handleCloseMobileMenu();
        if(current_user_id){
            profileNavigate('/user_settings',
                {
                    state:{ 'loggedInUserID':{'id': current_user_id.uid ,...logInUser} } 
                }
            );
            handleClosePO();
        }
    }

    // To open notification panel
    const [openNotifications, setOpenNotifications] = useState(false);
    const handleOpenNotification = ()=>{
        setOpenNotifications(true);
    }
    const handleCloseNotification = ()=>{
        setOpenNotifications(false);
    }

    const handleNotificationToggle = ()=>{
        if(openNotifications === true){
            handleCloseNotification();
        }else if(openNotifications === false){
            handleOpenNotification();
        }
    }

    const { unreadNotificationCount } = useNotifications();

    const navigateToSearch = ()=>{
        handleCloseMobileMenu();
        profileNavigate('/search_page');
    }

    const navigateToForums =()=>{
        handleCloseMobileMenu();
        profileNavigate('/forums')
    }

    // To open mobile menu
    const [openMobileMenu, setOpenMobileMenu] = useState(false);
    const handleOpenMobileMenu = ()=>{
        setOpenMobileMenu(true);
    }
    const handleCloseMobileMenu = ()=>{
        setOpenMobileMenu(false);
    }
    const handleToggleMobileMenu = ()=>{
        if(openMobileMenu === true)
            handleCloseMobileMenu();
        if(openMobileMenu === false)
            handleOpenMobileMenu();
    }

    // To open the console options div
    const consoleDropdownRef = useRef(null);
    const [isConsoleDropdownOpen, setIsConsoleDropdownOpen] = useState(false);
    const handleConsoleDropdownOpen = ()=>{
        setIsConsoleDropdownOpen(true);
    }

    const handleConsoleDropdownClose = ()=>{
        setIsConsoleDropdownOpen(false);
    }

    const toggleConsoleDropdown = ()=>{
        if(isConsoleDropdownOpen === true)
            handleConsoleDropdownClose();
        else
            handleConsoleDropdownOpen();
    }

    useEffect(() => {
        if (isConsoleDropdownOpen) {
            document.addEventListener('click', handleClickOutside);
        } else {
            document.removeEventListener('click', handleClickOutside);
        }
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [isConsoleDropdownOpen]);

    // To open the genre options div
    const genreDropdownRef = useRef(null);
    const [isGenreDropdownOpen, setIsGenreDropdownOpen] = useState(false);
    const handleGenreDropdownOpen = ()=>{
        setIsGenreDropdownOpen(true);
    }

    const handleGenreDropdownClose = ()=>{
        setIsGenreDropdownOpen(false);
    }

    const toggleGenreDropdown = ()=>{
        if(isGenreDropdownOpen === true)
            handleGenreDropdownClose();
        else
            handleGenreDropdownOpen();
    }

    useEffect(() => {
        if (isGenreDropdownOpen) {
            document.addEventListener('click', handleClickOutside);
        } else {
            document.removeEventListener('click', handleClickOutside);
        }
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [isGenreDropdownOpen]);

    // To handle closing the dialog if someone clicks outside the dropdown box
    const handleClickOutside = (event) => {
        // To close console dropdown
        if (consoleDropdownRef.current && !consoleDropdownRef.current.contains(event.target)) {
            handleConsoleDropdownClose();
        }
        // To close genre dropdown
        if (genreDropdownRef.current && !genreDropdownRef.current.contains(event.target)) {
            handleGenreDropdownClose();
        }

    };


    return(
        <nav className='navbar'>
            { openNotifications && <NotificationSidebar 
                                sidebarState={openNotifications}
                                onCloseSidebar={()=>{setOpenNotifications(false)}}
                            /> 
            }
            {
                isConsoleDropdownOpen &&
                <ConsoleDropdownMenu/>
            }
            {
                isGenreDropdownOpen &&
                <GenreDropdown/>
            }
            <div className='navbar-logo'>
                <Link to={'/'}>
                    <img src={mainLogo} alt="" />
                </Link>
            </div>
            <div className='navbar-links'>
                <div className="navbar-item"
                     onClick={()=>navigateToSearch()}
                >
                    <SearchIcon style={{ color:'#f8f8f8' }}/>
                    <div className="nav-search-prompt">
                        Search games
                    </div>
                </div>
                {/* <button className='nav-review-button'>
                    Write a review
                </button> */}
                <Link className='nav-forum-link'
                      to={'/articles'}
                >
                    Articles
                </Link>
                <Link className='nav-forum-link'
                      ref={genreDropdownRef}
                      onClick={()=>toggleGenreDropdown()}
                >
                    Genres
                </Link>
                <Link className='nav-forum-link'
                      ref={consoleDropdownRef}
                      onClick={()=>toggleConsoleDropdown()}
                >
                    Consoles
                </Link>
                <Link className='nav-forum-link' to={'/forums'}>
                    Forums
                </Link>
                <div className="nav-notification-box"
                     onClick={()=>handleNotificationToggle()}
                >
                    {
                        unreadNotificationCount > 0 &&
                        <div className="nav-notification-count-box">
                            {unreadNotificationCount}
                        </div>
                    }
                    <NotificationsIcon style={{ color:'#f8f8f8', fontSize:'1.1rem' }}/>
                </div>
                <div className="nav-user-profile-container"
                     onClick={()=>onAuthPrompt()}
                >
                {
                    (current_user_id === null || current_user_id === undefined)
                    && 
                    <>
                        <AccountCircleIcon style={{ color:'#f8f8f8', fontSize:'1.1rem' }}/>
                        <p>Log in</p>   
                    </>
                }
                {
                    current_user_id && current_user &&
                    <>
                        <div className="nav-user-profile">
                        {
                            current_user.profile_picture !== null && current_user.profile_picture !== undefined &&
                            <img src={current_user.profile_picture} alt={current_user.Name} />
                        }
                        {
                            (current_user.profile_picture === null || current_user.profile_picture === undefined) &&
                            current_user && current_user.Name !== undefined && <p>{current_user.Name.substring(0,1)}</p>
                        } 
                        </div>
                        <p className='nav-user-profile-username'>{`Hi! ${current_user.Name}`}</p>
                    </>
                }
                </div>
                {
                    current_user_id !== null && current_user !== null &&
                    <div className="nav-setting-icon-div">
                        <SettingsIcon style={{ color:'#f8f8f8', fontSize:'1.1rem' }}
                                      onClick={()=>togglePOBox()}
                                      ref={profileOptionBoxRef}
                        />
                    </div> 
                }
                <div className="nav-mobile-menu-items"
                     onClick={()=>handleToggleMobileMenu()}
                >
                {
                    openMobileMenu === false && 
                    <MenuIcon style={{ color:'#f8f8f8', fontSize:'1.1rem' }}/> 
                }
                {
                    openMobileMenu === true && 
                    <CloseIcon style={{ color:'#f8f8f8', fontSize:'1.1rem' }}/>
                }
                </div>
                {
                        openProfileOptions === true && 
                        <div className="profile-options-box">
                        {
                            current_user_id !== null && 
                            <div className="user-navbar-tile"
                                onClick={()=>sendToUserSettings()}
                            >
                                <SettingsIcon style={{ marginRight:'5px' }}/>
                                Edit profile
                            </div>
                        }
                        {
                            current_user_id !== null && 
                            <div className="user-navbar-tile"
                                onClick={()=>handleUserLogout()}
                            >
                                <LogoutIcon style={{ marginRight:'5px' }}/>
                                Sign out
                            </div>
                        }
                        </div>
                }
                {
                    openMobileMenu === true &&
                    <div className="nav-mobile-menu-box">
                        <div className='user-navbar-tile'
                             onClick={()=>{
                                profileNavigate(`/articles`)
                             }}
                        >
                            Articles
                        </div>
                        <div className='user-navbar-tile'
                             onClick={()=>{
                                profileNavigate(`/genres/${gidvar}`)
                             }}
                        >
                            Genres
                        </div>
                        <div className='user-navbar-tile'
                             onClick={()=>{
                                profileNavigate(`/consoles/${idvar}`)
                             }}
                        >
                            Consoles
                        </div>
                        <div className='user-navbar-tile'
                            onClick={()=>navigateToForums()}
                        >
                            Forums
                        </div>
                        {
                            current_user_id !== null && 
                            <div className="user-navbar-tile"
                                onClick={()=>sendToUserSettings()}
                            >
                                <SettingsIcon style={{ marginRight:'5px' }}/>
                                Edit profile
                            </div>
                        }
                        {
                            current_user_id !== null && 
                            <div className="user-navbar-tile"
                                onClick={()=>handleUserLogout()}
                            >
                                <LogoutIcon style={{ marginRight:'5px' }}/>
                                Sign out
                            </div>
                        }
                    </div>
                }
            </div>
        </nav>
    )
}