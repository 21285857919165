// styles
import React from 'react';
import { useSelector } from 'react-redux';
import { useGetGameReviews } from '../../../Hooks/GameReviews/useGetGameReviews';
import GameReviewBox from '../../GamepageComponents/GameReviewComponent/gameReviewBox';
import './mainGameReview.css';
import { selectUser } from '../../../Features/userSlice';

const MainGameReviews = React.memo(({ gameID }) => {

    // To get the current user
    const currentUser = useSelector(selectUser);

    const { reviews, isFetching, error } = useGetGameReviews(gameID);
    
    if(isFetching){
        return <div>...Loading review</div>
    }

    if(reviews.length === 0){
        return <p>No reviews yet. Be the first one to post for this game.</p>;
    }

    if(error){
        return <p>Some error displaying this review.</p>
    }

    return(
        <div className="main-game-review-container">
        {
            reviews.slice(0,2).map((rev)=>(
                <GameReviewBox key={rev.id} review={rev} 
                    currentUser={(currentUser !== undefined && currentUser !== null) ? currentUser.uid : undefined}
                />
            ))
        }
        </div>
    )
});

export default MainGameReviews;