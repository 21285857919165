import { useState } from 'react';

// firebase
import { db } from '../../Firebase/firebase';
import { doc, getDoc } from 'firebase/firestore';

export var gameDataMap = {};

export const useFetchGameData = ()=>{
    const [fetching, setFetching] = useState(false);
    const [gameData, setGameData] = useState(null);

    const fetchGameData = async(gameID)=>{
        setFetching(true);
        // console.log('game to fetch => ',gameID);
        if(JSON.stringify(gameDataMap)!=='{}'
            && gameDataMap[`${gameID}`] !== undefined
        ){
            // console.log('I am in if statement');
            setGameData(gameDataMap[`${gameID}`]);
            setFetching(false);
            return;
        }
        
        // console.log('I am in else part');
        
        setGameData(null);
        const gameDocRef = doc(db,'Games',`${gameID}`);
        const gameDocSnap = await getDoc(gameDocRef)
        .catch((err)=>{
            console.log(err);
            setFetching(false);
            return;
        });

        if(gameDocSnap.exists()){
            var gameObj = {
                'id':gameDocSnap.id,
                ...gameDocSnap.data()
            }
            setGameData(gameObj);
            gameDataMap[`${gameDocSnap.id}`] = gameObj;
            setFetching(false);
            return;
        }
    }

    // console.log('gameData => ',gameData);

    return { fetching, gameData, fetchGameData };
}