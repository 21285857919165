/* eslint-disable react-hooks/exhaustive-deps */
// styles
import './notificationTile.css';
// custom hooks
import { useGetUserProfile } from '../../../Hooks/useGetUserProfile';
import { useEffect } from 'react';
import { useNotifications } from '../../../Hooks/Notifications/useNotifications';
import { CircularProgress } from '@mui/material';

export default function NotificationTile({ notificationObj }){

    const { loadingUser, userData, getProfileDetails } = useGetUserProfile();

    const { marking, markNotificationAsRead } = useNotifications();

    useEffect(()=>{
        if(notificationObj){
            getProfileDetails(notificationObj.senderID);
        }
    },[notificationObj])

    var notificationDate = notificationObj.createdAt.toDate();
    notificationDate = new Date(notificationDate).getTime();
    const currentDate = new Date().getTime();
    // console.log(notificationDate);
    // console.log('currentDate => ',currentDate);
    const timePassed = Math.ceil((currentDate - notificationDate)/86400000);
    // console.log('Time passed since the notification => ',timePassed);


    const navigateToRelevantURL = ()=>{
        markNotificationAsRead(notificationObj.id);
        window.open(notificationObj.relevantURL,'_self');
    }

    const handleReadPrompt = ()=>{
        markNotificationAsRead(notificationObj.id);
    }

    return(
        <div className='notification-tile'>
            {
                loadingUser === true && <p>Loading notifications</p>
            }
            {
                loadingUser === false && userData &&
                <>
                    <div className="notification-sender-pfp">
                    {
                        loadingUser === false && userData!== null &&
                        userData.profile_picture!==null &&
                        <img src={userData.profile_picture} alt=""/>
                    }
                    {
                        loadingUser === false && userData !== null && userData.profile_picture === null
                        && <p>{userData.Name.substring(0,1).toUpperCase()}</p> 
                    }    
                    </div>
                    <div className="notification-content">
                    {
                        notificationObj.action === 'like' &&
                        notificationObj.actionOn === 'game_collection' &&
                        <>
                            <div className='notification-title'>
                                <span className='sender-name'>{userData.Name}</span> {` liked your game collection.`}
                                <p className="notification-date">
                                    {timePassed}d.
                                </p>
                            </div>
                            <div className='notification-body'>
                                {`${userData.Name} liked your `}
                                <span className='action-on'>game collection. </span>
                            </div>
                            <div className="notification-actions">
                                {
                                    notificationObj.relevantURL !== null &&
                                    <button className='read-btn-cta'
                                            onClick={()=>navigateToRelevantURL()}
                                    >
                                        Open
                                    </button>
                                }
                                <button className='read-btn-cta'
                                        onClick={()=>handleReadPrompt()}
                                >
                                    Mark as read
                                </button>
                            </div>
                        </>
                    }
                    {
                        notificationObj.action === 'like' &&
                        notificationObj.actionOn === 'review' &&
                        <>
                            <div className='notification-title'>
                                <span className='sender-name'>{userData.Name}</span> {` liked your review.`}
                                <p className="notification-date">
                                    {timePassed}d.
                                </p>
                            </div>
                            <div className='notification-body'>
                                {`${userData.Name} liked your `}
                                <span className='action-on'>review</span>
                                {` on the game `}
                                <span className='sender-name'>{notificationObj.gameName}</span>
                            </div>
                            <div className="notification-actions">
                                {
                                    notificationObj.relevantURL !== null &&
                                    <button className='read-btn-cta'
                                            onClick={()=>navigateToRelevantURL()}
                                    >
                                        Open
                                    </button>
                                }
                                <button className='read-btn-cta'
                                        onClick={()=>handleReadPrompt()}
                                >
                                    Mark as read
                                </button>
                            </div>
                        </>
                    }
                    {
                        notificationObj.action === 'like' &&
                        notificationObj.actionOn === 'comment' &&
                        <>
                            <div className='notification-title'>
                                <span className='sender-name'>{userData.Name}</span> {` liked your comment.`}
                                <p className="notification-date">
                                    {timePassed}d.
                                </p>
                            </div>
                            <div className='notification-body'>
                                {`${userData.Name} liked your `}
                                <span className='action-on'>comment</span>
                                {` on the discussion `}
                                <span className='sender-name'>{notificationObj.discussionTopic}</span>
                            </div>
                            <div className="notification-actions">
                                {
                                    notificationObj.relevantURL !== null &&
                                    <button className='read-btn-cta'
                                            onClick={()=>navigateToRelevantURL()}
                                    >
                                        Open
                                    </button>
                                }
                                {
                                    marking === false &&
                                        <button className='read-btn-cta'
                                                onClick={()=>handleReadPrompt()}
                                        >
                                            Mark as read
                                        </button>
                                }
                                {
                                    marking === true &&
                                    <CircularProgress size={16} style={{ color:'rgba(255,255,255,0.5)' }}/>
                                }
                            </div>
                        </>
                    }
                    {
                        notificationObj.action === 'like' &&
                        notificationObj.actionOn === 'gameCollection' &&
                        <>
                            <div className='notification-title'>
                                <span className='sender-name'>{userData.Name}</span> {` liked your game collection.`}
                                <p className="notification-date">
                                    {timePassed}d.
                                </p>
                            </div>
                            <div className='notification-body'>
                                {`${userData.Name} liked your `}
                                <span className='sender-name'>game collection.</span>
                            </div>
                            <div className="notification-actions">
                                {
                                    notificationObj.relevantURL !== null &&
                                    <button className='read-btn-cta'
                                            onClick={()=>navigateToRelevantURL()}
                                    >
                                        Open
                                    </button>
                                }
                                {
                                    marking === false &&
                                        <button className='read-btn-cta'
                                                onClick={()=>handleReadPrompt()}
                                        >
                                            Mark as read
                                        </button>
                                }
                                {
                                    marking === true &&
                                    <CircularProgress size={16} style={{ color:'rgba(255,255,255,0.5)' }}/>
                                }
                            </div>
                        </>
                    }
                    {
                        (notificationObj.action === 'like' || notificationObj.action === 'upvote') &&
                        notificationObj.actionOn === 'discussion' &&
                        <>
                            <div className='notification-title'>
                                <span className='sender-name'>{userData.Name}</span> {` upvoted your discussion.`}
                                <p className="notification-date">
                                    {timePassed}d.
                                </p>
                            </div>
                            <div className='notification-body'>
                                {`${userData.Name} upvoted your discussion.`}
                            </div>
                            <div className="notification-actions">
                                {
                                    notificationObj.relevantURL !== null &&
                                    <button className='read-btn-cta'
                                            onClick={()=>navigateToRelevantURL()}
                                    >
                                        Open
                                    </button>
                                }
                                {
                                    marking === false &&
                                        <button className='read-btn-cta'
                                                onClick={()=>handleReadPrompt()}
                                        >
                                            Mark as read
                                        </button>
                                }
                                {
                                    marking === true &&
                                    <CircularProgress size={16} style={{ color:'rgba(255,255,255,0.5)' }}/>
                                }
                            </div>
                        </>
                    }
                    {
                        notificationObj.action === 'comment' &&
                        notificationObj.actionOn === 'discussion' &&
                        <>
                            <div className='notification-title'>
                                <span className='sender-name'>{userData.Name}</span> {` commented.`}
                                <p className="notification-date">
                                    {timePassed}d.
                                </p>
                            </div>
                            <div className='notification-body'>
                                {`${userData.Name} commented your on the discussion `}
                                <span className='sender-name'>{notificationObj.discussionTopic}</span>
                            </div>
                            <div className="notification-actions">
                                {
                                    notificationObj.relevantURL !== null &&
                                    <button className='read-btn-cta'
                                            onClick={()=>navigateToRelevantURL()}
                                    >
                                        Open
                                    </button>
                                }
                                {
                                    marking === false &&
                                        <button className='read-btn-cta'
                                                onClick={()=>handleReadPrompt()}
                                        >
                                            Mark as read
                                        </button>
                                }
                                {
                                    marking === true &&
                                    <CircularProgress size={16} style={{ color:'rgba(255,255,255,0.5)' }}/>
                                }
                            </div>
                        </>
                    }
                    {
                        notificationObj.action === 'comment' &&
                        notificationObj.actionOn === 'review' &&
                        <>
                            <div className='notification-title'>
                                <span className='sender-name'>{userData.Name}</span> {` commented.`}
                                <p className="notification-date">
                                    {timePassed}d.
                                </p>
                            </div>
                            <div className='notification-body'>
                                {`${userData.Name} commented on your review `}
                                {/* <span className='sender-name'>{notificationObj.discussionTopic}</span> */}
                            </div>
                            <div className="notification-actions">
                                {
                                    notificationObj.relevantURL !== null &&
                                    <button className='read-btn-cta'
                                            onClick={()=>navigateToRelevantURL()}
                                    >
                                        Open
                                    </button>
                                }
                                {
                                    marking === false &&
                                        <button className='read-btn-cta'
                                                onClick={()=>handleReadPrompt()}
                                        >
                                            Mark as read
                                        </button>
                                }
                                {
                                    marking === true &&
                                    <CircularProgress size={16} style={{ color:'rgba(255,255,255,0.5)' }}/>
                                }
                            </div>
                        </>
                    }
                    {
                        notificationObj.action === 'comment' &&
                        notificationObj.actionOn === 'comment' &&
                        <>
                            <div className='notification-title'>
                                <span className='sender-name'>{userData.Name}</span> {` commented.`}
                                <p className="notification-date">
                                    {timePassed}d.
                                </p>
                            </div>
                            <div className='notification-body'>
                                {`${userData.Name} commented on your comment in a discussion.`}
                                {/* <span className='sender-name'>{notificationObj.discussionTopic}</span> */}
                            </div>
                            <div className="notification-actions">
                                {
                                    notificationObj.relevantURL !== null &&
                                    <button className='read-btn-cta'
                                            onClick={()=>navigateToRelevantURL()}
                                    >
                                        Open
                                    </button>
                                }
                                {
                                    marking === false &&
                                        <button className='read-btn-cta'
                                                onClick={()=>handleReadPrompt()}
                                        >
                                            Mark as read
                                        </button>
                                }
                                {
                                    marking === true &&
                                    <CircularProgress size={16} style={{ color:'rgba(255,255,255,0.5)' }}/>
                                }
                            </div>
                        </>
                    }    
                    </div>
                    
                </>
            }
            
        </div>
    )
}