// style
import './genreAbout.css';
// react states
import { useState, useEffect, useRef } from 'react';

export default function GenreAbout({ genreDetails }){

    // To handle the read more and read less of the game details div
    const [paraCollapse, setParaCollapse] = useState(false);
    const [paraCollapseHeight, setParaCollapseHeight] = useState('0px');
    const [paraShowCollapseBtn, setParaShowCollapseBtn] = useState(false);

    const defaultHeight = 250;

    const toggleParaCollapse = ()=>{
        if(paraCollapse)
            setParaCollapse(false);
        else
            setParaCollapse(true);
    }

    const paraCollapseDivRef = useRef(null);
    useEffect(()=>{
        if(paraCollapseDivRef.current){
            setParaCollapseHeight(paraCollapse ? `${paraCollapseDivRef.current.scrollHeight}px` : `${defaultHeight}px`);
            setParaShowCollapseBtn(paraCollapseDivRef.current.scrollHeight > defaultHeight);
        }
    },[paraCollapse])

    useEffect(() => {
        if (paraCollapseDivRef.current) {
            const scrollHeight = paraCollapseDivRef.current.scrollHeight;
            setParaCollapseHeight(`${defaultHeight}px`);
            setParaShowCollapseBtn(scrollHeight > defaultHeight);
        }
    }, [genreDetails.details]);


    return(
        <div className="genre-about-component">
            <div className='genre-about-para-div' 
                ref={paraCollapseDivRef} 
                style={{ 'maxHeight': paraCollapseHeight }}
                dangerouslySetInnerHTML={{
                    __html: genreDetails.details
                }}
            >
            </div>
            {
                paraShowCollapseBtn === true &&
                <button className='genre-about-read-more-cta'
                    onClick={()=>toggleParaCollapse()}
                >
                {
                    paraCollapse === false ? 'Read more':'Show less'
                }
                </button>
            }
        </div>
    )
}