// react states
import { useState } from 'react';
// firebase
import { auth } from '../Firebase/firebase';
import { sendPasswordResetEmail } from 'firebase/auth';


export const usePasswordForgetMailSend = () => {

    const [error, setError] = useState(null);
    const [status, setStatus] = useState(null);
    const [sending, setSending] = useState(false);

    const sendUserPasswordResetMail = (userMail)=>{
        setSending(true);
        sendPasswordResetEmail(auth, userMail)
        .then(()=>{
            setStatus('success');
            setError(null);
            setSending(false);
        }).catch((err)=>{
            setError(err.message);
            setStatus('error');
            setSending(false);
        })
    }

    return { error, status, sending, sendUserPasswordResetMail };
}