/* eslint-disable no-unused-vars */
import { useState } from "react";
// firebase
import { db } from '../Firebase/firebase';
import { collection, addDoc, serverTimestamp, doc, updateDoc, arrayUnion } from "firebase/firestore"; 

// react slices
import { addGameToReviewed } from '../Features/userPrivateDataSlice';
import { useDispatch } from "react-redux";

export const useSetUserReview = ()=>{

    const [uploadingProgress, setUploadingProgress] = useState(false);
    const [uploadStatus, setUploadStatus] = useState(null);
    const [reviewUploadError, setReviewUploadError] = useState(null);
    const [finalReviewId, setFinalReviewId] = useState(null);
    // const current_user_data = useSelector(selectUserData);
    const gamesReviewedChangedDispatch = useDispatch();
    
    const uploadReview = async(userID, gameID, reviewText, reviewScore, reviewSpoiler)=>{
        setUploadStatus(null);
        setUploadingProgress(true);
        setReviewUploadError(null);

        // Upload to database here...
        var tempRevObject = {
            'date':serverTimestamp(),
            'likes':0,
            'dislikes':0,
            'gameID':gameID,
            'userID':userID,
            'review_text':reviewText,
            'score':reviewScore,
            'spoilers':reviewSpoiler
        }

        await addDoc(collection(db,'GameReviews'),tempRevObject)
        .then((reviewDocId)=>{
            setFinalReviewId(reviewDocId.id);
        })
        .catch((error)=>{
            setUploadStatus(false);
            setUploadingProgress(false);
            setReviewUploadError(error.message);
            return;
        });

        // The add the game id to user's reviewed games collection
        const currentUserRef = doc(db, `Users/${userID}/PrivateInfo`, `userInfo`);
        await updateDoc(currentUserRef, {
            'games_reviewed': arrayUnion(gameID)
        }).then(()=>{
            gamesReviewedChangedDispatch(addGameToReviewed({
                'userID':userID,
                'gameID':gameID
            }))
            // gamesReviewedChangedDispatch(setPrivateData(tempCurrentUserDataObject));
        }).catch((error)=>{
            setUploadStatus(false);
            setUploadingProgress(false);
            setReviewUploadError(error.message)
            return;
        });

        setUploadStatus(true);
        setUploadingProgress(false);
    }

    return { uploadingProgress, uploadStatus, finalReviewId,
        reviewUploadError, setReviewUploadError, uploadReview };
}