/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
// routing
import { useNavigate } from 'react-router-dom';
// firebase
import { db } from '../../Firebase/firebase';
import { doc, getDoc, collection, getDocs, setDoc, deleteDoc } from 'firebase/firestore';
import { useSelector } from 'react-redux';
import { selectUser } from '../../Features/userSlice';
// for notification sending
import { sendNotifications } from '../../Firebase/fb-messaging';
// import { selectUserData } from '../../Features/userPrivateDataSlice';

export const useGetUserGameCollection = (userProfileID)=>{
    const [fetchingCollection, setFetchingCollection] = useState(false);
    const [userGameCollection, setUserGameCollection] = useState([]);
    const [currentlyPlayingCollection, setCurrentlyPlaying] = useState([]);
    const [userWishlistCollection, setUserWishlistCollection] = useState([]);

    const [likingStatus, setLikingStatus] = useState(null);
    const [likeBtnClicked, setLikeBtnClicked] = useState(false);
    const [liking, setLiking] = useState(false);
    const [gameCollectionLikesCount, setGameCollectionLikesCount] = useState(0);

    const signedInUser = useSelector(selectUser);
    // const signedInUserData = useSelector(selectUserData);
    const gameCollectionNav = useNavigate();
    // console.log('signedInUser => ',signedInUser);
    const getGameCollectionLikesCount = async()=>{
        // console.log('I am called');
        const userlikesCollectionRef = collection(db,`Users/${userProfileID}/GameCollectionLikes`);
        const userLikesCollectionSnap = await getDocs(userlikesCollectionRef);
        if(userLikesCollectionSnap.empty){
            setGameCollectionLikesCount(0);
        }else{
            setGameCollectionLikesCount(userLikesCollectionSnap.size);
        }
    }

    useEffect(()=>{
        getGameCollectionLikesCount();
    },[])

    async function getCurrentUserLikeStatus(){
        setLiking(true);
        const userlikeDocRef = doc(db,`Users/${userProfileID}/GameCollectionLikes`,`${signedInUser.uid}`);
        const userlikeDocSnap = await getDoc(userlikeDocRef).catch((like_err)=>{
            console.log(like_err);
            setLiking(false);
            // setLikingStatus(null);
            return false;
        });

        if(userlikeDocSnap.exists()){
            // setLikingStatus(true);
            setLiking(false);
            return true;
        }else if(userlikeDocSnap.exists() === false){
            // setLikingStatus(false);
            setLiking(false);
            return false;
        }
    }

    useEffect(()=>{
        if(signedInUser !== null){
            getCurrentUserLikeStatus().then((value)=>{
                // console.log(value);
                setLikingStatus(value);
            });
        }
    },[signedInUser])

    const getGameCollection = async(userid) => {
        setUserGameCollection([]);
        setCurrentlyPlaying([]);
        setUserWishlistCollection([]);
        setFetchingCollection(true);

        const userDocRef = doc(db,`Users/${userid}/PrivateInfo`,'userInfo');
        const userDocSnap = await getDoc(userDocRef).catch((err)=>{
            console.log(err);
            setFetchingCollection(false);
            return;
        });

        if(userDocSnap.exists()){
            var game_collection = userDocSnap.data().game_collection;
            var currently_playing = userDocSnap.data().currently_playing;
            var wishlist = userDocSnap.data().wishlist;

            setUserGameCollection(game_collection);
            setCurrentlyPlaying(currently_playing);
            setUserWishlistCollection(wishlist);
        }

        setFetchingCollection(false);
        return;

    }

    const reactToUserGameCollection = async(userid)=>{
        // console.log('userid => ',userid);
        // console.log('signed => ',likingID);
        if(signedInUser === null){
            gameCollectionNav('/user_auth');
            return;
        }

        setLiking(true);
        setLikeBtnClicked(true);
        const userlikesCollectionRef = collection(db,`Users/${userid}/GameCollectionLikes`);
        const userLikesCollectionSnap = await getDocs(userlikesCollectionRef);
        if(userLikesCollectionSnap.empty){
            // console.log('0');
            // Add user to the collection
            await setDoc(doc(db, `Users/${userid}/GameCollectionLikes`, `${signedInUser.uid}`),{
                'liked':true
            }).catch((err)=>{
                setLikingStatus(false);
                setLiking(false);
                console.log(err);
                return;
            }).then(async()=>{

                sendNotifications(
                    (signedInUser !== null) ? signedInUser.uid : 'A Gamer',
                    userProfileID,
                    'game_collection',
                    `https://thegamerbroz.com/user_profile/${userProfileID}`,
                    'like','game_collection',
                    `https://thegamerbroz.com/user_profile/${userProfileID}`
                );

                // make changes locally.
                setLikingStatus(true);
                setLiking(false);
                await getGameCollectionLikesCount();
                return;
            })

        }else{
            // console.log(userLikesCollectionSnap.size);
            getCurrentUserLikeStatus().then(async(likeStatusValue)=>{
                if(likeStatusValue === false){
                    // Add the document to collection
                    await setDoc(doc(db, `Users/${userid}/GameCollectionLikes`, `${signedInUser.uid}`),{
                        'liked':true
                    }).catch((err)=>{
                        setLikingStatus(false);
                        setLiking(false);
                        console.log(err);
                        return;
                    }).then(async()=>{
                        // make changes locally.
                        setLikingStatus(true);
                        setLiking(false);
                        await getGameCollectionLikesCount();

                        sendNotifications(
                            (signedInUser !== null) ? signedInUser.uid : 'A Gamer',
                            userProfileID,
                            'game_collection',
                            `https://thegamerbroz.com/user_profile/${userProfileID}`,
                            'like','game_collection',
                            `https://thegamerbroz.com/user_profile/${userProfileID}`
                        );

                        return;
                    })
                }else if(likeStatusValue === true){
                    // Remove the document from collection
                    await deleteDoc(doc(db, `Users/${userid}/GameCollectionLikes`, `${signedInUser.uid}`))
                    .then(async ()=>{
                        setLikingStatus(false);
                        setLiking(false);
                        await getGameCollectionLikesCount();
                        return;
                    }).catch((err)=>{
                        setLiking(false);
                        console.log(err);
                        return;
                    })
                }
            })
        }
    }

    return { fetchingCollection, userGameCollection, userWishlistCollection,
        liking, likingStatus, gameCollectionLikesCount, likeBtnClicked,
        getGameCollection, currentlyPlayingCollection, reactToUserGameCollection 
    };
}