/* eslint-disable react-hooks/exhaustive-deps */
// react states
import { useState, useEffect } from 'react';

// database
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../../Firebase/firebase';

export const useGetUserConsoles = (userID)=>{
    const [userConsoles, setUserConsoles] = useState([]);
    const [loadingUserConsoles, setLoadingUserConsoles] = useState(false);
    const [userConsoleError, setUserConsoleError] = useState(null);

    const getUserConsoleCollection = async()=>{
        setLoadingUserConsoles(true);
        setUserConsoleError(null);

        const userConsolesRef = doc(db, "Users", `${userID}`);
        const userConsolesSnap = await getDoc(userConsolesRef)
        .catch((err)=>{
            setUserConsoleError(err.message);
            setLoadingUserConsoles(false);
            return;
        });

        var tempUserConsoleList = [];
        if(userConsolesSnap.exists){
            if(userConsolesSnap.data().all_consoles 
                && userConsolesSnap.data().all_consoles.length > 0
            ){
                for(const current_console of userConsolesSnap.data().all_consoles){
                    const consoleRef = doc(db, "Consoles", `${current_console}`);
                    const consoleSnap = await getDoc(consoleRef);
                    tempUserConsoleList.push({'id':current_console, ...consoleSnap.data()});
                    // tempConsoleIdList.push(current_console);
                    
                }
            }
            setUserConsoles(tempUserConsoleList);
        }else{
            setUserConsoleError('No consoles found');
            setLoadingUserConsoles(false);
            return;
        }

        setLoadingUserConsoles(false);

    }

    useEffect(()=>{
        if(userID !== null && userID !== undefined){
            getUserConsoleCollection();
        }
    },[userID]);

    return { userConsoles, loadingUserConsoles, userConsoleError };
}