/* eslint-disable default-case */
import { useState } from 'react';
// slice (react redux)
import { useSelector, useDispatch } from 'react-redux';
import { selectUserData, setPrivateData } from '../Features/userPrivateDataSlice';
// firebase
import { db, storage } from '../Firebase/firebase';
import { doc, updateDoc } from "firebase/firestore";
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';

export const useEditUserProfile = ()=>{

    const userDispatch = useDispatch();
    const userData  = useSelector(selectUserData);

    const [editProgress, setEditProgress] = useState(false);
    const [error, setError] = useState(null);
    // for username update
    const [usernameProgress, setUsernameProgress] = useState(null);
    // for profile picture update
    const [profilePictureProgress, setProfilePictureProgress] = useState(null);
    const [profilePictureStatus, setProfilePictureStatus] = useState(null);
    // for cover picture update
    const [coverPictureProgress, setCoverPictureProgress] = useState(null);
    const [coverPictureStatus, setCoverPictureStatus] = useState(null);

    const editUserName = async(userID, editName)=>{
        setEditProgress(true);
        const userRef = doc(db, "Users", `${userID}`);
        await updateDoc(userRef, {
            'Name': editName
        }).catch((err)=>{
            setError(err.message);
            setEditProgress(false);
            setUsernameProgress(false);
            return;
        });

        if(userData !== null){
            var tempUserObject = {
                ...userData,
                'Name':editName
            }
            userDispatch(setPrivateData(tempUserObject));
        }
        setEditProgress(false);
        setUsernameProgress(true);
    }

    const editUserProfilePicture = async(userID, profilePic)=>{
        setEditProgress(true);
        setProfilePictureProgress(true);
        // upload the picture to database storage
        const profilePictureRef = ref(storage, `ProfilePictures/${userID}/${userID}.jpg`);
        const metadata = {
            cacheControl:'public,max-age=2678400'
        };

        const uploadProfilePictureTask = uploadBytesResumable(profilePictureRef, 
                                            profilePic, metadata);
        uploadProfilePictureTask.on('state_changed', 
        (snapshot) => {
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            // const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            // console.log('Upload is ' + progress + '% done');
            switch (snapshot.state) {
              case 'paused':
                console.log('Upload is paused');
                break;
              case 'running':
                console.log('Upload is running');
                break;
            }
          }, 
        (error) => {
            // Handle unsuccessful uploads
            console.log(error);
            setProfilePictureProgress(false);
            setProfilePictureStatus('fail');
            setEditProgress(false);
            return;
        }, 
        () => {
            getDownloadURL(uploadProfilePictureTask.snapshot.ref).then(async (downloadURL) => {
                // Use this download url and store it in user's document
                const updateUserDocRef = doc(db, "Users", `${userID}`);
                await updateDoc(updateUserDocRef, {
                    'profile_picture': downloadURL
                }).then(()=>{
                    var tempUserObject = {
                        'profile_picture':downloadURL,
                        ...userData
                    }
                    userDispatch(setPrivateData(tempUserObject));
            
                    setEditProgress(false);
                    setProfilePictureProgress(null);
                    setProfilePictureStatus('success');
                }).catch((err)=>{
                    console.log(err.message);
                    setEditProgress(false);
                    setProfilePictureProgress(false);
                    setProfilePictureStatus('fail');
                    return;
                })
            });
        }
        );
    }

    const editUserCoverImage =  async(userID, coverPic)=>{
        setEditProgress(true);
        setCoverPictureProgress(true);
        // upload the picture to database storage
        const coverPictureRef = ref(storage, `UserCoverImages/${userID}/${userID}.jpg`);
        const metadata = {
            cacheControl:'public,max-age=31622400'
        };

        const uploadCoverPictureTask = uploadBytesResumable(coverPictureRef, 
                                            coverPic, metadata);
        uploadCoverPictureTask.on('state_changed', 
        (snapshot) => {
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            // const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            // console.log('Upload is ' + progress + '% done');
            switch (snapshot.state) {
              case 'paused':
                console.log('Upload is paused');
                break;
              case 'running':
                console.log('Upload is running');
                break;
            }
          }, 
        (error) => {
            // Handle unsuccessful uploads
            console.log(error);
            setCoverPictureProgress(false);
            setCoverPictureStatus('fail');
            setEditProgress(false);
            return;
        }, 
        () => {
            getDownloadURL(uploadCoverPictureTask.snapshot.ref).then(async (downloadURL) => {
                // Use this download url and store it in user's document
                const updateUserDocRef = doc(db, "Users", `${userID}`);
                await updateDoc(updateUserDocRef, {
                    'user_cover_image': downloadURL
                }).then(()=>{
                    var tempUserObject = {
                        ...userData,
                        'user_cover_image':downloadURL,
                    }
                    userDispatch(setPrivateData(tempUserObject));
            
                    setEditProgress(false);
                    setCoverPictureProgress(null);
                    setCoverPictureStatus('success');
                }).catch((err)=>{
                    console.log(err.message);
                    setEditProgress(false);
                    setCoverPictureProgress(false);
                    setCoverPictureStatus('fail');
                    return;
                })
            });
        }
        );
    }

    return { editProgress, error, usernameProgress, editUserName,
             editUserProfilePicture, profilePictureProgress, profilePictureStatus,
             editUserCoverImage, coverPictureProgress, coverPictureStatus
           };
}