// styles
import './notificationSidebar.css';
// react states
import { useEffect, useRef } from 'react';
// muui components
import CloseIcon from '@mui/icons-material/Close';
import { useNotifications } from '../../Hooks/Notifications/useNotifications';
import { CircularProgress } from '@mui/material';
import NotificationTile from './NotificationTile/notificationTile';

export default function NotificationSidebar({ sidebarState, onCloseSidebar }){

    const notificationSidebarRef = useRef(null);

    useEffect(()=>{
        const handleClickOutside = (event) => {
            if (notificationSidebarRef.current && !notificationSidebarRef.current.contains(event.target)) {
                onCloseSidebar && onCloseSidebar();
            }
        };

        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    },[onCloseSidebar]);

    // Get all the notifications here.
    const { fetchingNotifications, allNotifications } = useNotifications();

    if(!sidebarState){
        return null;
    }

    return(
        <div ref={notificationSidebarRef} className={'notification-sidebar-container'}>
            <div className="notification-sidebar-heading">
                <CloseIcon onClick={onCloseSidebar} style={{ color:'#f8f8f8' }}/>
                <p>
                    Notifications
                </p>
            </div>
            <div className="notifications-container">
            {
                allNotifications && allNotifications.length === 0
                && 
                <div className="no-notification-prompt">
                    No notifications yet. Start interacting on the site to fill this place up.
                </div>
            }
            {
                fetchingNotifications===false && allNotifications && allNotifications.length > 0
                &&
                allNotifications.map((notification)=>(
                    <NotificationTile key={notification.id} notificationObj={notification}/>
                ))
            }
            {
                fetchingNotifications && 
                <div className="fetching-notification-prompt">
                    <CircularProgress size={24} style={{ color:'#FFE74C' }} />
                    <p>Loading notifications</p>
                </div>
            }
            </div>
        </div>
    )
}