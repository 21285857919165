/* eslint-disable react-hooks/exhaustive-deps */
// styles
import { useEffect } from 'react';
import './createdForums.css';
import { useFetchUserDiscussions } from '../../../Hooks/Forums/useFetchUserDiscussions';
// Custom components
import PrevDiscussionTile from '../../Forums/AllForums/prev_discussion_tile';
// for generating random key ids
import { v4 as uuid } from 'uuid';
// react-redux
import { useSelector } from 'react-redux';
import { selectUser } from '../../../Features/userSlice';

// mui components
import { CircularProgress } from '@mui/material';
import { useGetUserProfile } from '../../../Hooks/useGetUserProfile';
import { Helmet } from 'react-helmet';

export default function CreatedForums({userid, signedInUser}){

    const signedInUserData = useSelector(selectUser);
    // console.log('signed in user => ',signedInUserData);

    const { isLoading, getUserDiscussions, fetchMoreUserDiscussions, userCreatedDiscussion, 
        lastVisibleDiscussion
    } = useFetchUserDiscussions();

    const { getProfileDetails, userData } = useGetUserProfile();

    useEffect(()=>{
        if(userid !== null){
            getProfileDetails(userid);
        }
    },[userid]);

    useEffect(()=>{
        if(userid !== null){
            getUserDiscussions(userid);
        }
    },[]);

    // console.log('User created discussions are => ', userCreatedDiscussion);

    const loadMoreDiscussions = ()=>{
        fetchMoreUserDiscussions(userid);
    }

    return(
        <div className="user-created-forums">
        {
            userData !== null &&
            <Helmet>
                <title>{`${userData.Name} - Forums`}</title>
                <meta name='description' content={`${userData.Name} created forums or discussions`}/>
                <meta name='keywords' content={`${userData.Name}, ${userData.Name} forums, ${userData.Name} discussions, ${userData.Name} games discussions, game, discussion, forum`}/>
            </Helmet>
        }
        {
           userCreatedDiscussion && userCreatedDiscussion.length > 0
           && userCreatedDiscussion.map((userDiscussion)=>(
            <div className="discussion-settings-container" key={uuid()}>
                <PrevDiscussionTile discussion={userDiscussion} currentUser={signedInUserData}/>
            </div>
           )) 
        }
            <div className="load-more-user-discussion">
            {
                isLoading ? <CircularProgress size={24} style={{ color:'#FFE74C', marginLeft:'40%' }}/> :
                lastVisibleDiscussion && <button onClick={()=>loadMoreDiscussions()}>
                    Load more
                </button>
            }
            </div>
            {
                userCreatedDiscussion && userCreatedDiscussion.length === 0
                && <div className="no-discussion-prompt">
                {
                    userid === signedInUser
                    && <p>Go on, try the Forum area, create discussions, we believe you are full of interesting gaming topics.</p>
                }
                {
                    userid !== signedInUser
                    && userData && <p>{`${userData.Name} hasn't created any forums yet.`}</p>
                }
                </div>
            }
        </div>
    )
}