// react states
import { useState, useEffect } from 'react';
// react-redux
import { useSelector } from 'react-redux';
// slice
import { selectUserData } from '../Features/userPrivateDataSlice';

export const useAuthContext = ()=>{
    const [loadingUser, setLoadingUser] = useState(true);
    let userValues = useSelector(selectUserData);

    useEffect(()=>{
        setLoadingUser(false);
    },[userValues])

    return { userValues, loadingUser };
}