import { collection, getDocs, where, query } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { db } from '../Firebase/firebase';

export var allUsers = [];

export const useGetAllUsers = ()=>{
    const [ loadingUsers, setLoadingUsers ] = useState(false);

    const getUsers = async()=>{

        if(allUsers.length > 0){
            return allUsers;
        }

        setLoadingUsers(true);
        allUsers=[];
        const userCollectionSnapshot = await getDocs(
            query(collection(db,'Users'),where('isInDatabase','==',true),
                  where('Name','!=','admin')
            )
        );
        for(const user of userCollectionSnapshot.docs){
            var tempUserObject = {
                'id':user.id,
                ...user.data()
            }
            allUsers.push(tempUserObject);
        }
        setLoadingUsers(false);
    }

    useEffect(()=>{
        getUsers();
    },[])

    return { loadingUsers };
}