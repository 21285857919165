/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
// react states
import { useState, useEffect } from 'react';

// firebase
import { collection, addDoc } from 'firebase/firestore';
import { db } from '../../Firebase/firebase';

// email template creating utility
// import { email_template } from '../../NotificationTemplates/emailNotificationTemplate';

// custom hooks
import { useGetUserProfile } from '../../Hooks/useGetUserProfile';

// react redux
import { useSelector } from 'react-redux';
import { selectUserData } from '../../Features/userPrivateDataSlice';

export const useSendEmailNotifications = ()=>{
    // const [sendingMail, setSendingMail] = useState(false);
    const [mailError, setMailError] = useState(null);
    const [emailTemp, setEmailTemp] = useState('');

    // temporary fields
    const [tempAction, setTempAction] = useState('');
    const [tempActionOn, setTempActionOn] = useState('');
    const [tempTopic, setTempTopic] = useState('');

    const sendingUser = useSelector(selectUserData);
    // console.log(sendingUser);

    // to get the recipient info
    const { loadingUser, userData, getProfileDetails } = useGetUserProfile();

    const makeEmailHTML = async(action, actionOn, game, topicLink, topic)=>{
        // console.log('topic => ',topic);

        if(actionOn === 'comment'){
            getProfileDetails(topic.user_id);
        }
        else{
            getProfileDetails(topic.userID);
        }
        
        setTempAction(action);
        setTempActionOn(actionOn);
        setTempTopic(topic);
        setEmailTemp(emailTemplate(action, actionOn, game, sendingUser, topicLink, topic));
    }

    useEffect(()=>{
        if(loadingUser === false){
            if(userData && userData !== null && userData !== undefined){
                // console.log(userData);
                if(emailTemp !== '' && emailTemp !== undefined && emailTemp !== null){
                    sendMailToRecepient(tempAction, tempActionOn, userData.email)
                    // console.log(emailTemp);
                }
            }
        }
    },[emailTemp, userData])

    const sendMailToRecepient = async(action, actionOn, recipientMail)=>{
        // Create the email document in the mail collection
        // Rest the fb extension will take care by firing a function
        await addDoc(collection(db, 'mail'),{
            to:[`${recipientMail}`],
            message:{
                subject:`Your ${actionOn} generated some ${action}`,
                html: `${emailTemp}`,
                text:''
            }
        }).catch((err)=>{
            console.log(err);
            setMailError(err.message);
        });
    }

    return { makeEmailHTML, mailError };
}

function emailTemplate(action, actionOn, game, sender, topic_link, topic){
    
    var notification_message = '';

    if(action === 'like' && actionOn === 'review'){
        notification_message = `${sender.Name} liked your review`;
    }else if(action === 'like' && actionOn === 'comment'){
        notification_message = `${sender.Name} liked your comment`;
    }else if(action === 'like' && actionOn === 'discussion'){
        notification_message = `${sender.Name} upvoted your discussion`;
    }else if(action === 'like' && actionOn === 'game_collection'){
        notification_message = `${sender.Name} liked your game collection`;
    }else if(action === 'comment' && actionOn === 'review'){
        notification_message = `${sender.Name} commented on your review`;
    }else if(action === 'comment' && actionOn === 'discussion'){
        notification_message = `${sender.Name} commented on your discussion`;
    }else if(action === 'comment' && actionOn === 'comment'){
        notification_message = `${sender.Name} commented on your comment`;
    }
    
    var sender_pfp_html = ``;
    if(sender && (sender.profile_picture !== null && sender.profile_picture !== undefined)){
        sender_pfp_html = `
            <div class="sender-pfp-container">
                <img src='${sender.profile_picture}' alt='${sender.name}'/>
            </div>
        `;
    }else if(sender && (sender.profile_picture === null || sender.profile_picture === undefined)){
        sender_pfp_html = `
            <div class="sender-pfp-container">
                <p class="pfp-text-style">${sender.Name.substring(0,1)}</p>
            </div>
        `;
    }

    var topic_text = '';
    if(actionOn === 'review'){
        topic_text = `${topic.review_text}`;
    }else if(actionOn === 'discussion'){
        topic_text = `${topic.title}`;
    }else if(actionOn === 'comment'){
        topic_text = `${topic.comment}`;
    }

    const html_code = `<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
    <html>
    <head>
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8">
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta name="viewport" content="width=device-width, initial-scale=1">
        <title>The Gamer Bros</title>
        <style type="text/css">
            body{
                font-family: sans-serif;
            }
            table{
                height: 100vh;
                width: 100%;
            }
            .company-info-row{
                height: 10vh;
                width: 100%;
            }
            .company-logo-container{
                height: auto;
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
            }

            .company-logo-bg{
                height: 75px;
                width: 75px;
                border-radius: 50% 50% 50% 50%;
                background-color: #080808;
                display: flex;
                justify-content: center;
                align-items: center;
            }

            .company-logo-bg img{
                height: 100%;
                width: 100%;
                aspect-ratio: 2/3;
                object-fit: contain;
            }

            .sender-info-row{
                height: 10vh;
                width: 100%;
            }

            .sender-pfp-container{
                height: 35px;
                width: 35px;
                margin-right: 7px;
                border-radius: 50% 50% 50% 50%;
                background-color: #080808;
                display: flex;
                justify-content: center;
                align-items: center;
            }

            .sender-pfp-container img{
                height: 100%;
                width: 100%;
                border-radius: 50% 50% 50% 50%;
                object-fit: cover;
            }

            .notification-topic-row{
                height: 10vh;
                width: 100%;
            }

            .pfp-text-style{
                padding: 0;
                margin: 0;
                font-size: 0.95rem;
                font-weight: 500;
                color: #FFE74C;
            }

            .normal-text-style{
                padding: 0;
                margin: 0;
                margin-right: 5px;
                font-size: 0.95rem;
                font-weight: 500;
                color: #080808;
            }
            .secondary-text-style{
                padding: 0;
                margin: 0;
                margin-right: 10px;
                font-size: 0.95rem;
                font-weight: 500;
                color: #888888;
            }

            .notification-topic-link{
                text-decoration: none;
                color: #080808;
                font-size: 1.5rem;
                text-align: left;
                font-weight: 800;
                cursor: pointer;
            }

            .game-cta-button{
                text-decoration: none;
                margin-top: 10px;
                height: auto;
                width: auto;
                padding-top: 1%;
                padding-bottom: 1%;
                padding-right: 2%;
                border-radius: 25px 25px 25px 25px;
                outline: none;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                color: #191917;
                font-size: 1rem;
                font-weight: 400;
                cursor: pointer;
            }
            @media only screen and (max-width: 600px) {

            .sender-pfp-container{
                margin-bottom: 10px;
            }

            .normal-container{
                width: 100%;
                justify-content: center;
            }

            .normal-text-style{
                text-align: left;
            }
            .secondary-text-style{
                text-align: left;
            }
            }
        </style>
    </head>
    <body>
        <table>
            <tr class="company-info-row" align="center">
                <td width="auto">
                    <div class="company-logo-container">
                        <div class="company-logo-bg">
                            <img src='https://firebasestorage.googleapis.com/v0/b/thegamerbros-9204e.appspot.com/o/TheGamerBros%2FTGB-LOGO.png?alt=media&token=e9bd65d1-4a33-4ef5-8a0e-e76d9e8d1b29' 
                                alt="The Gamer Bros"
                            />
                        </div>
                    </div>
                </td>
            </tr>
            <tr class="sender-info-row" align="center">
                <td width="auto">
                    ${sender_pfp_html}
                </td>
                <td width="auto" align="left">
                    <p class='normal-text-style'>
                        ${sender.Name}
                    </p>
                    <p class='secondary-text-style'
                    >
                        ${notification_message}
                    </p>
                    <p class='secondary-text-style'>
                    </p>
                </td>
            </tr>
            <tr class="notification-topic-row" align="center">
                <td width="auto"></td>
                <td width="100%" align="left">
                    <a href="${topic_link}"
                        target="_blank"
                        rel="noreferrer"
                        class="notification-topic-link"
                    >
                        <div dangerouslySetInnerHTML={{__html:'<p>${topic_text}</p>'}}></div>
                    </a>
                </td>
            </tr>
            <tr></tr>
        </table>
    </body>
    </html>
    `;

    return html_code;
}