// styles
import './externalAuthenticationComponent.css';
// brand icons
import gauthIcon from '../../../assets/Logo/Google-Logo/Web (mobile + desktop)/png@4x/dark/web_dark_rd_na@4x.png';
import { useLogin } from '../../../Hooks/useLogin';


export default function ExternalAuthenticationComponent(){

    // custom hook for logging in
    const { logInWithGoogle } = useLogin();

    // Google login
    const handleGoogleLogin = ()=>{
        logInWithGoogle();
    }

    return(
        <div className="auth-providers-container">
            <button className="auth-provider"
                    onClick={()=>handleGoogleLogin()}
            >
                <img src={gauthIcon} alt="google authentication"/>
            </button>
        </div>
    );
}