// styles
import { useState } from 'react';
import './userForums.css';
// react-router-dom
import { HashLink as HLink } from 'react-router-hash-link';
import CreatedForums from './UserForums/createdForums';
import UserCreatedComments from './UserForums/createdComments';

export default function UserForums({ userProfileID, loggedInUserID }){

    // Create tabs to show what all forums did the user create
    // and what all comments user commented on the forums.
    const [loadedForumTab, setLoadForumTab] = useState(0);
    const userForumTabChange = (i) => {
        setLoadForumTab(i);
    }

    return(
        <div className='forum-workspace-container'>
            <div className="forum-workspace-tabs">
                <HLink to={'#forums_created'} style={{ 'textDecoration':'none' }} smooth>
                    <button onClick={()=>userForumTabChange(0)} className={loadedForumTab===0 ? 'user-forums-tab selected':'user-forums-tab'}>Created</button>
                </HLink>
                <HLink to={'#forum_comments'} style={{ 'textDecoration':'none' }} smooth>
                    <button onClick={()=>userForumTabChange(1)} className={loadedForumTab===1 ? 'user-forums-tab selected':'user-forums-tab'}>Commented</button>
                </HLink>
            </div>
            {
                loadedForumTab === 0 && <CreatedForums userid={userProfileID} signedInUser={loggedInUserID}/>
            }
            {
                loadedForumTab === 1 && <UserCreatedComments userid={userProfileID} signedInUser={loggedInUserID}/>
            }
        </div>
    )
}