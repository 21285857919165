// react states
import { useState, useEffect } from 'react';
// database
import { collection, getDocs, query, orderBy } from 'firebase/firestore';
import { db } from '../../Firebase/firebase';

export const useGetAllConsoles = ()=>{
    const [allConsoleData, setAllConsoleData] = useState([]);
    const [loadingAllConsoles, setLoadingAllConsoles] = useState(false);
    const [errorLoadingConsoles, setErrorLoadingConsoles] = useState(null);

    const consoleKey = 'consoleKey';

    const getAllConsolesData = async()=>{
        const consoleData = sessionStorage.getItem(consoleKey);
        if(consoleData){
            setAllConsoleData(JSON.parse(consoleData));
        }else{
            setLoadingAllConsoles(true);
            let tempConsolesArray = [];
            const consolesQuerySnapshot = await getDocs(
                query(collection(db, "Consoles"), orderBy('name','desc'))
            )
            .catch((err)=>{
                setErrorLoadingConsoles(err.message);
                setLoadingAllConsoles(false);
                return;
            });

            for(const doc of consolesQuerySnapshot.docs){
                var tempConsoleObject = {
                    'id':doc.id,
                    ...doc.data()
                }
                tempConsolesArray.push(tempConsoleObject);
            }
            if(tempConsolesArray.length > 0){
                sessionStorage.setItem(consoleKey, JSON.stringify(tempConsolesArray));
            }
            setAllConsoleData(tempConsolesArray);
            setLoadingAllConsoles(false);
        }
    }

    useEffect(()=>{
        getAllConsolesData();
    },[])

    return { allConsoleData, loadingAllConsoles, errorLoadingConsoles };
}