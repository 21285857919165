// react states
import { useState, useEffect } from 'react';
// firebase/database
import { httpsCallable } from 'firebase/functions';
import { fbFunctions } from '../../Firebase/firebase';

export const useGetTrendingForums = ()=>{
    const [trendingForums, setTrendingForums] = useState([]);
    const [loadingTrendingForums, setLoadingTrendingForums] = useState(false);
    const [trendingForumsError, setTrendingForumsError] = useState(null);

    const forumCacheKey = 'trendingForums';

    const fetchTopForums = async () => {
        // Get forum data from cache, if not the first time of the call.
        const forumChachedData = sessionStorage.getItem(forumCacheKey);
        if(forumChachedData){
            setTrendingForums(JSON.parse(forumChachedData));
        }else{
            setLoadingTrendingForums(true);
            setTrendingForumsError(null);
            
            const getTopGameForums = httpsCallable(fbFunctions, "fetchTopForums");
            try {
                const result = await getTopGameForums();
                setTrendingForums(result.data.topForums);
                sessionStorage.setItem(forumCacheKey, JSON.stringify(result.data.topForums));
            } catch (error) {
                setLoadingTrendingForums(false);
                setTrendingForumsError(error);
                console.error("Error fetching top reviews: ", error);
                return;
            }
    
            setLoadingTrendingForums(false);
            setTrendingForumsError(null);
        }
    };

    useEffect(()=>{
        fetchTopForums();
    },[]);

    return { trendingForums, loadingTrendingForums, trendingForumsError };
}