// styles
import './mainGameReviewPage.css';
// react-router-dom
import { useParams } from 'react-router-dom';
// custom hooks
import { useGetGameReviews } from '../../Hooks/GameReviews/useGetGameReviews';
import { useFetchGameDetails } from '../../Hooks/GameData/useFetchGameDetails';
import GameReviewBox from '../../Components/GamepageComponents/GameReviewComponent/gameReviewBox';
import { useSelector } from 'react-redux';
import { selectUser } from '../../Features/userSlice';


export default function MainGameReviewPage(){

    // To get the currently signed in user
    const currentUser = useSelector(selectUser);

    const { gameid } = useParams();

    // To get info about the game
    const { data: gameData, isLoading, error: gameError } = useFetchGameDetails(gameid);

    // To get the game review
    const { reviews, isFetching, error: reviewError, fetchMoreReviews, hasMore } = useGetGameReviews(gameid);

    if(isFetching || isLoading){
        return <p>Loading reviews...</p>
    }

    return(
        <div className="main-game-review-page">
        {
            gameError === null &&
            <header className='main-game-reviews-header'>
                <div className="main-game-review-about-container">
                    <div className="main-game-profile-picture">
                        <img src={gameData.thumbnail_image} alt={gameData.name} />
                    </div>
                    <div className="main-game-about">
                        <h1 className='main-game-review-heading'>{gameData.name}</h1>
                        <h4 className='main-game-review-subheading'>{gameData.studio_name}</h4>
                        <h4 className='main-game-review-subheading'>{gameData.publisher_name}</h4>
                        <h2 className='main-game-review-subheading-1'>User reviews</h2>
                    </div>
                </div>
            </header>
        }
        <main className='main-game-review-main'>
            <section className='main-game-rev-ad-slot-section-1'>
                {/* Space to put ads. */}
            </section>
            <section className='main-game-review-section'>
            {
                reviews && reviews.map((review)=>(
                    <GameReviewBox key={review.id}
                                   review={review}
                                   currentUser={(currentUser !== undefined && currentUser !== null) ? currentUser.uid : undefined}
                                   gameName={gameData.name}
                    />
                ))
            }
            {
                reviewError !== null &&
                <p className='main-game-review-heading'>There was a problem fetching the reviews. Sorry!! we are working on it...</p>
            }
            {
                hasMore && 
                <button className='main-game-review-btn'
                        onClick={()=>fetchMoreReviews()}
                        disabled={isFetching}
                >
                    Load More Reviews
                </button>
            }
            </section>
            <section className='main-game-rev-ad-slot-section-2'>
                {/* Space to put ads */}
            </section>
        </main>
        </div>
    )
}