// react states
import { useState } from 'react';
// firebase
import { db, auth, googleAuthProvider } from '../Firebase/firebase';
import { signInWithEmailAndPassword, signInWithPopup } from 'firebase/auth';
import { doc, getDoc } from "firebase/firestore";
// react-redux
import { useDispatch } from 'react-redux';
// react-router-dom
// import { useNavigate } from 'react-router-dom';
// react slices
import { login } from '../Features/userSlice';
// custom hook for onboarding the user straightaway in case of external provider login.
import { useSignup } from './useSignup';

export const useLogin = ()=>{

    const [error, setError] = useState(null);
    const [loggingIn, setLoggingIn] = useState(null);
    
    const userLoginDispatch = useDispatch();

    // custom hook to signup the user first.
    const { createAccountWithGoogleAuth } = useSignup();

    const logUserIn = (email, password)=>{
        setLoggingIn(true);
        // console.log('Log in successfully');
        signInWithEmailAndPassword(auth, email, password)
        .then((userCredentials) => {
            // console.log('Logged in successfully');
            userLoginDispatch(login({
                // 'uid': userCredentials.user.uid
                'uid':userCredentials.user.uid,
                'email':email,
                'isEmailVerified':userCredentials.user.emailVerified,
            }))
            setLoggingIn(false);
            // userLoginNavigate(`/email_verification`);
        }).catch((err)=>{
            setError(err.message);
            setLoggingIn(false);
        })
    }

    const logInWithGoogle = ()=>{
        setLoggingIn(true);
        signInWithPopup(auth, googleAuthProvider).then(async(userGoogleCredentials)=>{
            // Check if the user is in the database or not.
            const authUserRef = doc(db, "Users", `${userGoogleCredentials.user.uid}`);
            const authUserSnap = await getDoc(authUserRef);
            if(authUserSnap.exists()){
                // console.log("User exists, sign them in");
                userLoginDispatch(login({
                    // 'uid': userCredentials.user.uid
                    'uid':userGoogleCredentials.user.uid,
                    'email':userGoogleCredentials.user.email,
                    'isEmailVerified':userGoogleCredentials.user.emailVerified,
                }))
                setLoggingIn(false);
            }else{
                // console.log("User doesn't exist, sign them up");
                createAccountWithGoogleAuth(userGoogleCredentials);
            }
        })
        .catch((googleAuthError)=>{
            setError(googleAuthError.message);
            setLoggingIn(false);
        })
    }

    return { error, loggingIn, logUserIn, logInWithGoogle };

}