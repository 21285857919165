/* eslint-disable react-hooks/exhaustive-deps */
// react states
import { useState, useEffect } from 'react';
// firebase
import { db } from '../../Firebase/firebase';
import { collection, getDocs, query, limit, startAfter, orderBy } from 'firebase/firestore';

export const useGetAllArticles = ()=>{
    const [allArticles, setAllArticles] = useState([]);
    const [loadingAllArticles, setLoadingAllArticles] = useState(false);
    const [allArticleError, setAllArticleError] = useState(null);
    const [hasMoreArticles, setHasMoreArticles] = useState(true);
    const [lastVisibleArticle, setLastVisibleArticle] = useState(null);

    const getFirstBatchArticles = async(startAfterDoc = null, fetchLimit = 25)=>{
        setLoadingAllArticles(true);

        let articlesQuery = query(collection(db, 'Articles'), orderBy('createdAt','desc'), limit(fetchLimit));
        
        if(startAfterDoc){
            articlesQuery = query(articlesQuery, startAfter(startAfterDoc))
        }
        
        const articleDocsSnap = await getDocs(articlesQuery).catch((err)=>{
            setAllArticleError(err.message);
            setLoadingAllArticles(false);
            return;
        });

        const tempArticleArr = [];
        for(const article of articleDocsSnap.docs){
            var articleObject = {
                'id':article.id,
                ...article.data()
            }

            tempArticleArr.push(articleObject);
        }

        if(articleDocsSnap.docs.length < fetchLimit){
            setHasMoreArticles(false);
        }

        if (startAfterDoc) {
            setAllArticles(prevArticles => [...prevArticles, ...tempArticleArr]);
        } else {
            setAllArticles(tempArticleArr);
        }

        setLastVisibleArticle(articleDocsSnap.docs[articleDocsSnap.docs.length - 1]);

        setLoadingAllArticles(false);
        setAllArticleError(null);
    }

    const getNextBatchArticles = async()=>{
        if(lastVisibleArticle){
            getFirstBatchArticles(lastVisibleArticle, 15);
        }
    }

    useEffect(()=>{
        getFirstBatchArticles();
    },[])

    return { allArticles, loadingAllArticles, allArticleError, hasMoreArticles, getNextBatchArticles };
}