/* eslint-disable react-hooks/exhaustive-deps */
// styles
import { useEffect, useState } from 'react';
import { useGetUserProfile } from '../../Hooks/useGetUserProfile';
import './forumComment.css';
import CommentUser from './commentUserProfile';
import { useCommentsFetch } from '../../Hooks/Forums/ForumComments/useCommentsFetch';
import { useCommentsPost } from '../../Hooks/Forums/ForumComments/useCommentsPost';
import TGBTextInput from '../../PaywallFeatures/PaywallInputs/TGBtextInput';
import { CircularProgress } from '@mui/material';
import { useSelector } from 'react-redux';
import { selectUser } from '../../Features/userSlice';

// custom hooks
import { useCommentsOperations } from '../../Hooks/Forums/useCommentsOperations';
import { useSendEmailNotifications } from '../../Hooks/EmailNotifications/useSendEmailNotifications';
// import { useGiveReactions } from '../../Hooks/Reactions/useGiveReactions';
// to send notifications
import { sendNotifications } from '../../Firebase/fb-messaging';
import { selectUserData } from '../../Features/userPrivateDataSlice';

// mui icons
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CommentDeletionConfirmation from './commentDeletionConfirmation';

export default function ForumComment({ section, commentObject }){

    // console.log('commentObject => ',commentObject);

    // To send email notifications
    const { makeEmailHTML } = useSendEmailNotifications();

    // To fetch the user details of the current comment
    const { userData, getProfileDetails } = useGetUserProfile();
    useEffect(()=>{
        getProfileDetails(commentObject.user_id, null);
    },[commentObject])

    // console.log('user details of comment => ',userData);

    // To fetch the comments if any in current commment
    const thisComment = useCommentsFetch(section);

    // To get the number of comments on the current comment
    useEffect(()=>{
        if(JSON.stringify(commentObject)!=='{}'){
            if(section === 'forum')
                thisComment.fetchComments(commentObject.forumID, commentObject.id);
            else if(section === 'review')
                thisComment.fetchComments(commentObject.reviewID, commentObject.id);    
        }
    },[commentObject])

    // To post comments on this comment
    const { posting, postingStatus, postingError, setPostingError, postComment } = useCommentsPost(section);

    // To toggle the replying state
    const [reply, setReply] = useState(false);
    const toggleCommentReply = ()=>{
        if(reply === true){
            setReply(false);
        }else{
            setReply(true);
        }
    }

    // To toggle to show replies on a comment.
    const [showReply, setShowReply] = useState(false);
    const toggleReplyState = ()=>{
        if(showReply === true){
            setShowReply(false);
        }else{
            setShowReply(true);
        }
    }

    // To get the text reply from the user for this comment
    const [commentReply, setCommentReply] = useState('');

    // Whenever the showReply state becomes true, fetch and show all the comments.
    useEffect(()=>{
        if(showReply === true){
            if(section === 'forum')
                thisComment.fetchComments(commentObject.forumID, commentObject.id);
            else if(section === 'review')
                thisComment.fetchComments(commentObject.reviewID, commentObject.id);    
        }
    },[showReply, setShowReply])

    // console.log(thisComment.fetchedComments);

    // To post the reply to this comment
    const postReplyToThisComment = ()=>{
        if(commentReply === '' || commentReply.length === 0){
            setPostingError('Please write something to post');
            return;
        }else{
            // console.log(`<p>${commentReply}</p>`)
            setPostingError(null);
            const userReply = `<p>${commentReply}</p>`;
            var replyObject = {};

            if(section === 'forum'){
                replyObject = {
                    'parent': commentObject.id,
                    'commentText': userReply,
                    'commentType': 'T',
                    'forumID':commentObject.forumID,
                    'taggedGames': commentObject.taggedGames,
                    'parentCommentUserID':commentObject.user_id
                    // 'gameID':commentObject.game_id,
                };
            }
            else if(section === 'review'){
                replyObject = {
                    'parent': commentObject.id,
                    'commentText': userReply,
                    'commentType': 'T',
                    'reviewID':commentObject.reviewID,
                    'taggedGames': commentObject.taggedGames,
                    'parentCommentUserID':commentObject.user_id
                    // 'gameID':commentObject.game_id,
                };
            }

            postComment(replyObject);

            setCommentReply('');
        }
    }

    useEffect(()=>{
        if(postingStatus === true){
            setShowReply(true);
            makeEmailHTML('comment','comment',commentObject.taggedGames[0], 
            (section === 'forum')?`https://thegamerbroz.com/discussion/${commentObject.forumID}`:`https://thegamerbroz.com/full_review/${commentObject.reviewID}`,
                commentObject
            )
        }
    },[postingStatus])

    const currentUser = useSelector(selectUser);
    const currentUserData = useSelector(selectUserData);
    
    // to delete the comment
    // custom hook to remove the comments
    // const { deleteUserComments, deletingCommentStatus, isCommentDeleting } = useFetchUserDiscussions();
    const [openCommentDeletionBox, setOpenCommentDeletionBox] = useState(false);
    // const [commentIdToDelete, setCommentIdToDelete] = useState(null);
    const handleOpenCommentDeletionBox = ()=>{
        setOpenCommentDeletionBox(true);
    }
    // const handleCloseDeletionBox = ()=>{
    //     setOpenCommentDeletionBox(false);
    // }

    // const startCommentDeletion = ()=>{
    //     // console.log('Comment to delete is : ',commentIdToDelete);
    //     deleteUserComments(commentIdToDelete);
    // }

    // To get the comment reactions
    const { currentCommentLikes, currentCommentDislikes, userCommentsReaction
        , likeComment, dislikeComment
      } = useCommentsOperations(section, commentObject.id, currentUser!==null ? currentUser.uid : null);
    // A stack to mantain the last reactions of the user
    const [commentPrevReaction, setCommentPrevReaction] = useState([]);
    const [tempCommentLikes, setTempCommentLikes] = useState(currentCommentLikes);
    const [tempCommentDislikes, setTempCommentDislikes] = useState(currentCommentDislikes);

    useEffect(()=>{
        setCommentPrevReaction(userCommentsReaction);
        setTempCommentLikes(currentCommentLikes);
        setTempCommentDislikes(currentCommentDislikes);
    },[userCommentsReaction, currentCommentLikes, currentCommentDislikes]);


    // To like the comment
    const handleClickLikeComment = ()=>{
        if(currentUser === null)
            return;
        else{
            likeComment(commentObject.id, currentUser.uid);
            var tempStack = commentPrevReaction;
            if (commentPrevReaction.length === 0) {
                let currentLike = tempCommentLikes;
                currentLike += 1;
                setTempCommentLikes(currentLike);
                tempStack.unshift("L");
                setCommentPrevReaction(tempStack);

                sendNotifications(
                    currentUser.uid,
                    commentObject.user_id,
                    'discussion',
                    (section === 'forum')?`https://thegamerbroz.com/discussion/${commentObject.forumID}`:`https://thegamerbroz.com/full_review/${commentObject.reviewID}`,
                    'like','comment',
                    commentObject.id
                );
                makeEmailHTML('like','comment',commentObject.taggedGames[0],
                        (section === 'forum')?`https://thegamerbroz.com/discussion/${commentObject.forumID}`:`https://thegamerbroz.com/full_review/${commentObject.reviewID}`, 
                        commentObject);
            } else if (commentPrevReaction.length > 0 && commentPrevReaction[0] === "L") {
                // console.log("You have already liked");
                tempStack.shift();
                let currentLikes = tempCommentLikes;
                currentLikes -= 1;
                setTempCommentLikes(currentLikes);
                setCommentPrevReaction(tempStack);
            } else if (commentPrevReaction.length > 0 && commentPrevReaction[0] === "D") {
                // console.log("You previously disliked");
                tempStack.shift();
                let currentLikes = tempCommentLikes;
                let currentDislikes = tempCommentDislikes;
                currentDislikes -= 1;
                currentLikes += 1;
                setTempCommentLikes(currentLikes);
                setTempCommentDislikes(currentDislikes);
                tempStack.push("L");
                setCommentPrevReaction(tempStack);

                sendNotifications(
                    currentUser.uid,
                    commentObject.user_id,
                    'discussion',
                    (section === 'forum')?`https://thegamerbroz.com/discussion/${commentObject.forumID}`:`https://thegamerbroz.com/full_review/${commentObject.reviewID}`,
                    'like','comment',
                    commentObject.id
                );

                makeEmailHTML('like','comment',commentObject.taggedGames[0],
                        (section === 'forum')?`https://thegamerbroz.com/discussion/${commentObject.forumID}`:`https://thegamerbroz.com/full_review/${commentObject.reviewID}`, 
                        commentObject);

            }
        }
    }

    // To dislike the comment
    const handleClickDislikeComment = ()=>{
        if(currentUser === null)
            return;
        else{
            dislikeComment(commentObject.id, currentUser.uid);
            var tempStack = commentPrevReaction;
            if (commentPrevReaction.length === 0) {
                let currentDislike = tempCommentDislikes;
                currentDislike += 1;
                setTempCommentDislikes(currentDislike);
                tempStack.unshift("D");
                setCommentPrevReaction(tempStack);
            } else if (commentPrevReaction.length > 0 && commentPrevReaction[0] === "L") {
                // console.log("You previously liked");
                tempStack.shift();
                let currentLikes = tempCommentLikes;
                let currentDislikes = tempCommentDislikes;
                currentLikes -= 1;
                currentDislikes += 1;
                setTempCommentDislikes(currentDislikes);
                setTempCommentLikes(currentLikes);
                tempStack.push("D");
                setCommentPrevReaction(tempStack);
            } else if (commentPrevReaction.length > 0 && commentPrevReaction[0] === "D") {
                // console.log("You have already disliked");
                tempStack.shift();
                let currentDislikes = tempCommentDislikes;
                currentDislikes -= 1;
                setTempCommentDislikes(currentDislikes);
                setCommentPrevReaction(tempStack);
            }
        }
    }

    return(
        <div className='forum-comment'>
            <CommentDeletionConfirmation section={section} comment_id={commentObject.id}
                                        openCommentDeletionBox={openCommentDeletionBox}
                                        setOpenCommentDeletionBox={setOpenCommentDeletionBox}
            />
            {
                commentObject.deleted === false &&
                <>
                { userData && userData !== null && <CommentUser userObject={{ 'id': commentObject.user_id, ...userData}}/>}
                { commentObject && commentObject.createdAt && <p className='forum-comment-date'>{commentObject.createdAt.toDate().toDateString()}</p> }
                {
                    commentObject.commentType === 'T' &&
                    <div className='forum-comment-content' dangerouslySetInnerHTML={{
                        __html:commentObject.comment
                    }}></div>
                }
                </>
            }
            {
                commentObject.deleted === true &&
                <div className="forum-comment-content">
                    {'[Deleted by user]'}
                </div>
            }
            {
                commentObject.deleted === false && 
                <div className="forum-comment-actions">
                {
                    thisComment.fetchedComments.length > 0 &&
                    <p className='comment-show-replies'
                    onClick={()=>toggleReplyState()}
                    onKeyDown={()=>toggleReplyState()}
                    >
                    {
                        showReply ? `Hide replies (${thisComment.fetchedCommentsCount})` : `Show replies (${thisComment.fetchedCommentsCount})`
                    }
                    </p>
                }
                <div className="comment-reaction-box"
                     onClick={()=>handleClickLikeComment()}
                     onKeyDown={()=>handleClickLikeComment()}
                >
                    <KeyboardArrowUpIcon size={16} style={{ color: (commentPrevReaction.length > 0 && commentPrevReaction[0]==='L') ? '#FFE74C' : '#F8F8F8' }}/>
                    <p style={{ color:(commentPrevReaction.length > 0 && commentPrevReaction[0]==='L') ? '#FFE74C' : '#F8F8F8' }}>{tempCommentLikes}</p>
                </div>
                <div className="comment-reaction-box"
                     onClick={()=>handleClickDislikeComment()}
                     onKeyDown={()=>handleClickDislikeComment()}
                >
                    <KeyboardArrowDownIcon size={16} style={{ color: (commentPrevReaction.length > 0 && commentPrevReaction[0]==='D') ? '#FFE74C' : '#F8F8F8' }}/>
                    <p style={{ color:(commentPrevReaction.length > 0 && commentPrevReaction[0]==='D') ? '#FFE74C' : '#F8F8F8' }}>{tempCommentDislikes}</p>
                </div>
                <p className='comment-replying'
                   onClick={()=>toggleCommentReply()}
                   onKeyDown={()=>toggleCommentReply()}
                >
                {
                    reply === false ? 'Reply' : 'Cancel'
                }
                </p>
                {
                    currentUser && 
                    currentUser.uid === commentObject.user_id &&
                    <p className='comment-reporting'
                       onClick={()=>handleOpenCommentDeletionBox()}
                       onKeyDown={()=>handleOpenCommentDeletionBox()}
                    >
                        Delete
                    </p>
                }
                </div>
            }
            {
                reply === true &&
                <div className="comment-text-input-area">
                    { currentUser && JSON.stringify(currentUserData)!=='{}' &&
                        <div className="text-input-user">
                            <p className='text-input-user-txt'>Comment as </p>
                            { JSON.stringify(currentUserData) !== '{}' && <CommentUser userObject={{'id':currentUser.uid, ...currentUserData}}/>}
                        </div>
                    }
                    <TGBTextInput key={`${commentObject.id}a`} 
                                placeholderText={'Write here...'}
                                textInput={commentReply}
                                setTextInput={setCommentReply}
                    />
                    { postingError && <p>{ postingError }</p> }
                    <div className="forum-cta-btn"
                         onClick={()=>postReplyToThisComment()}
                         onKeyDown={()=>postReplyToThisComment()}
                    >
                    {
                        posting === true && <CircularProgress size={18} style={{ color:'#080808' }}/>
                    }
                    {
                        posting === false && 'Post'
                    }
                    </div>
                </div>
            }
            {
                showReply === true && thisComment.fetchedComments.length > 0 
                && thisComment.fetchedComments.map((commentReply)=>(
                    <ForumComment key={commentReply.id} commentObject={commentReply} section={section}/>
                ))
            }
        </div>
    )
}