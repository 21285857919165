/* eslint-disable react-hooks/exhaustive-deps */
// styles
import './userWishlistTile.css';
// react states
import { useState, useEffect } from 'react';
// custom hooks
import { useFetchGameData } from '../../../Hooks/GameData/useFetchGameData';
import UserWishlistOptions from './userWishlistOptions';

export default function UserWishlistTile({ gameID, userProfileID, loggedInUserID }){
    
    // custom hook to get game details of a game
    const { fetching, fetchGameData, gameData } = useFetchGameData();

    useEffect(()=>{
        if(gameID !== null && gameID !== undefined)
            fetchGameData(gameID);
    },[])

    const [openWishlistOptionsDialog, setOpenWishlistOptionsDialog] = useState(false);
    const handleOpenWishlistOptionsDialog = ()=>{
        setOpenWishlistOptionsDialog(true);
    }
    
    return(
        <>
            <UserWishlistOptions
                gameID={gameID}
                openOptions={openWishlistOptionsDialog}
                setOpenOptions={setOpenWishlistOptionsDialog}
                userProfileID={userProfileID}
                loggedInUserID={loggedInUserID}
            />
            <div className="user-wishlist-tile">
            {
                fetching === true &&
                <div className="user-wishlist-tile-loader">

                </div>
            }
            {
                gameData !== undefined && gameData !== null &&
                <div className="user-wishlist-tile-image-container"
                     onClick={()=>handleOpenWishlistOptionsDialog()}
                >
                    <img src={gameData.thumbnail_image} alt=""/>
                </div>  
            }
            </div>
        </>
    );
}