// styles
import './trendingReviewCarousel.css';
// mui components
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
// custom hooks
import { useGetTrendingReviews } from '../../../../Hooks/Homepage/useGetTrendingReviews';
import { useState } from 'react';
import TrendingReviewCarouselTile from './trendingReviewCarouselTile';
import TrendingReviewLoading from '../TrendingReviewLoadingScreens/trendingReviewLoading';

export default function TrendingReviewCarousel(){

    // custom hook to get the trending reviews
    const { trendingReview, loadingTrendingRevs } = useGetTrendingReviews();

    // For the movement of carousel
    const [currentIndex, setCurrentIndex] = useState(0);
    // To go to the previous review
    const goToPreviousSlide = ()=>{
        if(trendingReview && trendingReview.length > 0){
            setCurrentIndex((prevIndex) => (prevIndex - 1 + trendingReview.length) % trendingReview.length);
        }
    }
    // To go to the next review
    const goToNextSlide = ()=>{
        if(trendingReview && trendingReview.length > 0){
            setCurrentIndex((prevIndex) => (prevIndex + 1) % trendingReview.length);
        }
    }

    return(
        <div className="trending-review-carousel">
            <button className='trending-review-carousel-nav-btns left'
                    onClick={()=>goToPreviousSlide()}
            >
                <ArrowBackIosIcon className='arrow-backward-slide' style={{ 'color':'#FFE74C', fontSize:'3rem' }}/>
            </button>

            <div className="review-carousel-content">
            {
                loadingTrendingRevs === true &&
                <TrendingReviewLoading/>
            }
            {
                loadingTrendingRevs === false &&
                trendingReview && trendingReview.length > 0 &&
                trendingReview.map((rev, index)=>(
                    <div key={index+1} className={(currentIndex === index) ? "review-carousel-item active":"review-carousel-item"}>
                        <TrendingReviewCarouselTile review={rev}/>
                    </div>
                ))
            }
            </div>

            <button className='trending-review-carousel-nav-btns right'
                    onClick={()=>goToNextSlide()}
            >
                <ArrowForwardIosIcon className='arrow-forward-slide' style={{ 'color':'#FFE74C', fontSize:'3rem' }}/>
            </button>
        </div>
    )
}