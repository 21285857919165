// firebase
import { collection, doc, getDoc, getDocs, query, where } from 'firebase/firestore';
import { db, auth } from '../Firebase/firebase';

export const getUserDetails = async(userID, setData)=>{
    // Get the game collection here.
    // console.log('user id in this fn is ', userID);
    // Check if user is present in the database then go forward.
    const userDataDocRef = doc(db, "Users", `${userID}`);
    const userDataDocSnap = await getDoc(userDataDocRef);
    if(userDataDocSnap.exists()){
        const userDocRef = doc(db, `Users/${userID}/PrivateInfo`, "userInfo");
        const userDocSnap = await getDoc(userDocRef);
        // console.log(userDocSnap.data());

        const userProfileRef = doc(db, "Users",`${userID}`);
        const userProfileSnap = await getDoc(userProfileRef);
        // console.log(userProfileSnap.data()); 

        var userReviews = [];
        const usersReviewData = query(collection(db, 'GameReviews'), where('userID','==',`${userID}`));
        const userReviesSnapshot = await getDocs(usersReviewData);
        userReviesSnapshot.forEach((userReview) => {
            var temporaryReviewObject = {
                'date':(userReview.data().date.toDate().toDateString()),
                'dislikes':userReview.data().dislikes,
                'likes':userReview.data().likes,
                'gameID':userReview.data().gameID,
                'userID':userReview.data().userID,
                'review_text':userReview.data().review_text,
                'score':userReview.data().score,
                'spoilers':userReview.data().spoilers,
            }
            var tempRevObject = {
                'id':userReview.id,
                ...temporaryReviewObject
            }
            userReviews.push(tempRevObject);
        })

        var finalUserObject = {
            ...userProfileSnap.data(),
            'isEmailVerified': auth.currentUser.emailVerified,
            // 'inDatabase': true,
            ...userDocSnap.data(),
            userReviews
        }
        setData(finalUserObject);
    }
    // else{
    //     var notInDatabaseUserObject = {
    //         'id':userID,
    //         'inDatabase':false,
    //         'isEmailVerified':auth.currentUser.emailVerified ? true : false,
    //     }

    //     setData(notInDatabaseUserObject);
    // }
    
}