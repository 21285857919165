/* eslint-disable react-hooks/exhaustive-deps */
// styles
import './gameSearchList.css';
// react states
import { useState, useEffect } from 'react';
// custom components
import { useGetAllGames } from '../../../Hooks/useGetAllGames';
import { matchStrings } from '../../SearchpageComponents/SearchingFunction/searchFunctionality';

export default function GameSearchList({ resultList, setResultList, setSearchGames }){
    
    const [dupResultList, setDupResultList] = useState([]);

    const [searchVal, setSearchVal] = useState('');
    const handletextInputChange = (txtEvent) => {
        setSearchVal(txtEvent.target.value);
    }

    // Custom hook to get all games at once
    const { loadingGames, allGamesID } = useGetAllGames();

    useEffect(()=>{
        if(loadingGames === false && allGamesID.length > 0){
            setSearchGames(allGamesID);
            setDupResultList(allGamesID);
        }else{
            setSearchGames([]);
            setDupResultList([]);
        }
    },[allGamesID])

    const [searchedArray, setSearchedArray] = useState([]);
    useEffect(()=>{
        if(loadingGames === true)
            return;
        else if(loadingGames === false && allGamesID.length > 0){
            setSearchedArray([]);
            var searchedKeys = [];
            var searchedGames = [];
            if(searchVal===''){
                setSearchedArray([]);
                searchedKeys = [];
                searchedGames = [];
            }
            for(var i=0;i<allGamesID.length;i++){
                var currentGame = allGamesID[i];
                if(searchVal===''){
                    setSearchedArray([]);
                    searchedKeys = [];
                    searchedGames = [];
                }
                // if(currentGame.name.toUpperCase().includes(searchText.toUpperCase())){
                if(matchStrings(currentGame.name, searchVal) === true){
                    if(searchedKeys.includes(currentGame.name.toUpperCase())){
                        continue;
                    }else{
                        searchedKeys.push(currentGame.name.toUpperCase());
                        // console.log("Adding the game to searched array is = ",currentGame.name);
                        searchedGames.push(currentGame);
                        // console.log('Game added to searched array ',searchedGames);
                    }
                }
            }
            setSearchedArray(searchedGames);
        }
    },[searchVal, setSearchVal])

    // And if searched array changes, change the search list
    useEffect(()=>{
        if(searchedArray.length === 0){
            setSearchGames(dupResultList);
        }else if(searchedArray.length > 0){
            setSearchGames(searchedArray);
        }
    },[searchedArray, searchedArray])

    return(
        <input type="text"
               className='game-search-txt-input'
               placeholder='Search game'
               value={searchVal}
               onChange={(e)=>handletextInputChange(e)}
        />
    )
}