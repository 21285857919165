// styles
import './gameRequestPage.css';
// react states
import { useEffect, useState } from 'react';
// react-redux
import { useSelector } from 'react-redux';
// react slices
import { selectUser } from '../../Features/userSlice';
import { useGameRequest } from '../../Hooks/Requests/useGameRequest';
import { CircularProgress } from '@mui/material';

export default function GameRequestPage(){

    const [requestedGame, setRequestedGame] = useState('');
    const handleTextChange = (e)=>{
        setRequestedGame(e.target.value);
    }

    const currentUser = useSelector(selectUser);

    // custom component to lodge requests to database.
    const { requesting, setRequestError, requestError, requestStatus, requestTicket,
        requestGame } = useGameRequest();

    const handleRequestSubmit = ()=>{
        if(currentUser === null){
            setRequestError('Looks like you are not signed in. Please do so first.')
            return;
        }else{
            if(requestedGame === ''){
                setRequestError('Please enter a game name to request.');
                return;
            }else{
                // console.log(currentUser);
                requestGame(requestedGame, currentUser.uid);
            }
        }
    }

    useEffect(()=>{
        if(requestStatus !== null){
            setRequestedGame('');
        }
    },[requestStatus])

    return(
        <div className='game-request-page'>
            <p className='game-request-heading'>
                We are sorry for the inconvenience. Please tell us the name of the game
                which we weren't able to provide.
            </p>
            <input type="text" 
                   className='game-request-input'
                   placeholder='Enter the name of the game you want us to add'
                   autoFocus={true}
                   value={requestedGame}
                   onChange={(textEvent)=>handleTextChange(textEvent)}
            />
            {
                requesting === false &&
                <button className='game-request-cta'
                        onClick={()=>handleRequestSubmit()}
                >
                    Submit
                </button>
            }
            {
                requesting === true && <CircularProgress size={24} style={{ color:'#FFE74C' }}/>
            }
            {
                (requestError || requestStatus === 'fail' ) && <p className='game-request-error'>{requestError}</p>
            }
            {
                requestStatus === 'success' && 
                    <div className='successful-request-prompt'>
                        <p className='successful-request-prompt-text'>
                            Thank you for helping us figure out more new and exciting games and helping the community grow.
                            We have received your request with ticket no.:
                        </p>
                        <p className='successful-request-prompt-ticket'>{requestTicket}</p>
                        <p className='successful-request-prompt-text'>
                            Please save this ticket number for any future query and references.
                            For now sit back and relax we will be pursuing your request as soon as possible.
                        </p>
                    </div>
            }
        </div>
    )
}