// react states
import { useEffect, useState } from 'react';
// firebase
import { db } from '../../Firebase/firebase';
import { onSnapshot, query, collection, where, doc, updateDoc } from 'firebase/firestore';
import { useSelector } from 'react-redux';

// react redux
import { selectUser } from '../../Features/userSlice';

export const useNotifications = ()=>{
    const [fetchingNotifications, setFetchingNotifications] = useState(false);
    const [unreadNotificationCount, setUnreadNotificationCount] = useState(0);
    const [allNotifications, setAllNotifications] = useState([]);

    const signedInUserID = useSelector(selectUser);

    useEffect(()=>{
        if(signedInUserID !== null){
            getNotifications(signedInUserID.uid);
        }
    },[signedInUserID])

    const getNotifications = async(userid)=>{
        // console.log('Getting notifications');
        const notificationQuery = query(collection(db,`Users/${userid}/Notifications`), where('read','==',false));
        const unsub = onSnapshot(notificationQuery, (notificationSnapshot)=>{
            // console.log(notificationSnapshot.docs);
            setFetchingNotifications(true);
            setUnreadNotificationCount(notificationSnapshot.size);
            
            // Now attach new notifications to the array
            setAllNotifications([]);
            var tempNotificationsArr = [];
            for(const notification of notificationSnapshot.docs){
                const tempNotifyObject = {
                    'id':notification.id,
                    ...notification.data()
                }
                // console.log(tempNotifyObject);
                tempNotificationsArr.push(tempNotifyObject);
            }

            setAllNotifications(allNotifications => [...tempNotificationsArr, ...allNotifications]);
            setFetchingNotifications(false);
        })

        // return unsub();
        return ()=>{unsub()}
    }

    // To mark a given notification as read
    const [marking, setMarking] = useState(false);
    const markNotificationAsRead = async(notificationID)=>{
        setMarking(true);
        const notificationRef = doc(db,`Users/${signedInUserID.uid}/Notifications`,`${notificationID}`);
        await updateDoc(notificationRef,{
            'read' : true
        }).catch((err)=>{
            console.log(err.message);
            setMarking(false);
            return;
        }).then(()=>{
            setMarking(false);
            return;
        });
    }

    return { fetchingNotifications, allNotifications, unreadNotificationCount,
             marking, markNotificationAsRead
    };
}