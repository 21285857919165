// styles
import './password_reset.css';
// react states
import { useState, useEffect } from 'react';
// react-router-dom
import { Link, useLocation } from 'react-router-dom';
// custom hook
import { usePasswordReset } from '../../../Hooks/usePasswordReset';
// mui components
import { CircularProgress } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

export default function PasswordReset(){
    
    const secretInfo = useLocation();
    // custom hook to reset the password
    const { error, status, resetting, resetUserPassword } = usePasswordReset();

    const [newPass, setNewPass] = useState('');
    const [newConfirmPass, setNewConfirmPass] = useState('');

    // to give user feedback regarding the status of mail sent or not.
    const [openStatus, setOpenStatus] = useState(false);
    const handleOpenStatus = ()=>{
        setOpenStatus(true);
    }
    const handleCloseStatus = ()=>{
        setOpenStatus(false);
    }

    useEffect(()=>{
        handleOpenStatus();
    },[status, resetting])

    const resetPassword = ()=>{
        if(newPass.length === 0 || newConfirmPass.length === 0){
            return;
        }
        else if(newConfirmPass !== newPass){
            return;
        }
        else{
            resetUserPassword(secretInfo.state.oobCode, newPass);
        }
    }

    return(
        <div className='password-reset-container'>
            {
                status !== null && 
                <Snackbar open={openStatus} autoHideDuration={4000} onClose={handleCloseStatus}>
                    <Alert onClose={handleCloseStatus} severity={`${status}`} sx={{ width: '100%' }}>
                    {
                        status === 'success' && <p className='pass-reset-status-text'>Password reset successfully.</p>
                    }
                    {
                        status === 'error' && <p className='pass-reset-status-text'>Password reset failed. Try again in a few minutes</p>
                    }
                    </Alert>
                </Snackbar>
            }
            <div className="password-reset-inputs-container">
                <p className='password-reset-message-prompt'>Create new password</p>
                <input 
                    className='password-reset-input'
                    type='text'
                    required
                    placeholder='Enter new password'
                    onChange={(e)=>setNewPass(e.target.value)}
                    value={newPass}
                />
                <input 
                    className='password-reset-input'
                    type='text'
                    required
                    placeholder='Re-enter new password'
                    onChange={(e)=>setNewConfirmPass(e.target.value)}
                    value={newConfirmPass}
                />
                {
                    resetting !== true &&
                    <>
                        <button 
                            className='reset-password-cta'
                            onClick={()=>resetPassword()}
                        >
                            Reset password
                        </button>
                        <Link to={`/user_auth`} style={{'textDecoration':'none'}}>
                            <p className='pass-reset-login'>Login?</p>
                        </Link>
                    </> 
                    
                }
                {
                    resetting === true && <CircularProgress size={24} style={{'color':'#FFE74C'}} />
                }
                {
                    error !== null && <p>{error}</p>
                }
            </div>
        </div>
    );
}