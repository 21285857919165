// styles
import './likingGameCollection.css';

export default function LikingGameCollectionScreen(){
    return(
        <div className="liking-screen-overlay">
            <div className="liking-collection-prompt-area">
                <p className='liking-collection-text'>You liked the game collection</p>
            </div>
        </div>
    )
}