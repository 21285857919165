// styles
import './articleHomepage.css';
// custom hook(s)
import ArticleTile from '../../Components/ArticleComponents/articleTile';
import { useGetAllArticles } from '../../Hooks/Articles/useGetAllArticles';

// For SEO
import { Helmet } from 'react-helmet';

export default function ArticleHomepage(){

    const { allArticles, hasMoreArticles, loadingAllArticles, getNextBatchArticles } = useGetAllArticles();
    // console.log(allArticles);
    // console.log('Has more article ? ',hasMoreArticles);

    const canonical_article_homepage_url = 'https://thegamerbroz.com/articles/';

    const handleLoadMoreArticles = ()=>{
        getNextBatchArticles();
    }


    return(
        <div className="articles-home-container">
            <Helmet>
                <title>Articles</title>
                <link rel="canonical" href={canonical_article_homepage_url}/>
                <meta name='tagline' content={'For the gamers. By the gamers.'}/>
            </Helmet>
            <div className="article-ad-component-1">
            </div>
            <div className="main-article-list-content-area">
                <p className='article-list-heading'>Our Thoughts</p>
                <div className="article-list-container">
                {
                    loadingAllArticles === false && 
                    allArticles && allArticles.length > 0 && 
                    allArticles.map((article)=>(
                        // create the article tile here..
                        <a key={article.id} className='article-link-text' href={`/articles/${article.id}`}>
                            <ArticleTile articleData={article}/>
                        </a>
                    ))
                }
                <div className="article-list-actions">
                {
                    hasMoreArticles && 
                    <button className='article-list-load-more-cta'
                            onClick={()=>handleLoadMoreArticles()}
                    >
                        Load more
                    </button>
                }
                </div>
                </div>
            </div>
            <div className="article-ad-component-1">
            </div>
        </div>
    );
}