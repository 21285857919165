/* eslint-disable react-hooks/exhaustive-deps */
// styles
import './sharing.css';
// react states
import { useEffect, useState } from 'react';
// Social media sharing buttons
import { WhatsappShareButton, WhatsappIcon,
    FacebookShareButton, FacebookIcon,
    EmailShareButton, EmailIcon,
    TwitterShareButton,
    LinkedinShareButton, LinkedinIcon,
    TumblrShareButton, TumblrIcon
  } from 'react-share';
import CopyToClipboard from 'react-copy-to-clipboard';

// official icons
import twittericon from '../../assets/Logo/X-Logo/logo-white.png';

// mui components
import CloseIcon from '@mui/icons-material/Close';
import { Dialog, DialogContent } from '@mui/material';

export default function Sharing({ type, sharingLink, extraText, startSharing, closeSharingDialog }){
    
    // For copying the link to clipboard
    const [isCopied, setIsCopied] = useState(false);

    // To set the body of the sharing content
    const [bodyText, setBodyText] = useState('');
    useEffect(()=>{
        setBodyText(extraText);
    },[])

    // To set the title 
    const [titleText, setTitleText] = useState('');
    useEffect(()=>{
        if(type === 'review'){
            setTitleText('Hey checkout this review at The Gamer Bros');
        }else if(type === 'discussion'){
            setTitleText('Hey checkout this discussion at The Gamer Bros');
        }else if(type === 'article'){
            setTitleText('Hey checkout this article at The Gamer Bros');
        }
    },[type])
    
    return(
        <Dialog open={startSharing} onClose={closeSharingDialog}
                maxWidth={'sm'}
                fullWidth={true}
            >
            <DialogContent
                className='sharing-dialog-container'
            >
                <div className="sharing-header-row">
                    <CloseIcon sx={{ color:'#f8f8f8' }}
                            onClick={()=>{
                                setIsCopied(false);
                                closeSharingDialog()
                            }}
                    />
                    <p>Share the discussion</p>
                </div>
                {
                    <>
                        <div className="sharing-platforms-row">
                            <EmailShareButton
                                url={sharingLink}
                                subject={titleText}
                                // body={discussion.title}
                                body={bodyText}
                                openShareDialogOnClick={true}
                                onClick={()=>{}}
                                formTarget='_blank'
                            >
                                <EmailIcon round={true} style={{ marginRight:'10px' }}/>
                            </EmailShareButton>
                            <WhatsappShareButton
                                url={sharingLink}
                                title={titleText}
                            >
                                <WhatsappIcon round={true} style={{ marginRight:'10px' }}/>
                            </WhatsappShareButton>
                            <FacebookShareButton
                                url={sharingLink}
                                quote={titleText}
                            >
                                <FacebookIcon round={true} style={{ marginRight:'10px' }}/>
                            </FacebookShareButton>
                            <TwitterShareButton
                                url={sharingLink}
                                title={titleText}
                            >
                                <div className="twitter-icon">
                                    <img src={twittericon} alt="" />
                                </div>
                            </TwitterShareButton>
                            <LinkedinShareButton
                                url={sharingLink}
                                title={titleText}
                                // summary={discussion.title}
                                summary={bodyText}
                            >
                                <LinkedinIcon round={true} style={{ marginRight:'10px' }}/>
                            </LinkedinShareButton>
                            <TumblrShareButton
                                url={sharingLink}
                                title={titleText}
                                // caption={discussion.title}
                                caption={bodyText}
                            >
                                <TumblrIcon round={true}/>
                            </TumblrShareButton>
                        </div>
                        <div className="separator">
                            Copy URL
                        </div>
                        <div className="copy-to-clipboard-section">
                            <div className="copy-url-area">
                                {sharingLink}
                            </div>
                            <CopyToClipboard
                                text={sharingLink}
                                onCopy={()=>setIsCopied(true)}
                            >
                                <button className="copy-url-cta">
                                    Copy
                                </button>
                            </CopyToClipboard>
                        </div>
                        {
                            isCopied && <p>Link copied</p>
                        }
                    </>
                }
                {/* <div className="sharing-link-container">

                </div> */}
            </DialogContent>
            </Dialog>
    );
}