export function matchStrings(mainString, text){
    var result = false;

    // console.log('mainString => ',mainString);
    // console.log('text => ',text);
  
    var m = mainString.length;
    var n = text.length;
  
    var dp = new Array(m+1).fill(0)
             .map(()=>new Array(n+1).fill(0));
  
    for (var i = 1; i <= m; i++) {
      for (var j = 1; j <= n; j++) {
        if (mainString[i - 1].toUpperCase() === text[j - 1].toUpperCase()) {
          dp[i][j] = 1 + dp[i - 1][j - 1];
        } else {
          dp[i][j] = Math.max(dp[i - 1][j], dp[i][j - 1]);
        }
      }
    }
  
    if (dp[m][n] === n) {
      result = true;
    }
  
    return result;
}