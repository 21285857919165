// react states
import { useState } from 'react';
// firebase
import { db } from '../../../Firebase/firebase';
import { doc, updateDoc } from "firebase/firestore";

export const useCommentsDelete = (section)=>{
    const [deleting, setDeleting] = useState(false);
    const [deleteStatus, setDeleteStatus] = useState(null);
    const [deletingError, setDeletingError] = useState(null);

    const deleteUserComments = async(commentID) => {
        var pathCollection = '';

        if(section === 'forum'){
            pathCollection = 'ForumComments'
        }
        if(section === 'review'){
            pathCollection = 'ReviewComments'
        }
        
        setDeleting(true);
        const commentRef = doc(db, pathCollection, `${commentID}`);
        await updateDoc(commentRef, {
            deleted: true
        }).catch((err)=>{
            setDeletingError(err.message);
            setDeleting(false);
            setDeleteStatus('fail');
            return;
        }).then(()=>{
            setDeletingError(null);
            setDeleting(false);
            setDeleteStatus('success');
        });
    }

    return { deleting, deleteStatus, deletingError, deleteUserComments };
}