// styles
import './emailVerification.css';

// custom hook
import { useSendEmailVerification } from '../../../Hooks/useSendEmailVerification';
import { CircularProgress } from '@mui/material';

export default function EmailVerification({ userObject }){
    // console.log('userObject => ',userObject);
    // console.log('I am in email verification page');

    const { sendVerificationMail, sendingMail, sentStatus } = useSendEmailVerification();

    const handleVerificationMailSending = ()=>{
        if(userObject === null)
            return;
        else
            sendVerificationMail(userObject);
    }

    return(
        <div className='email-verification-container'>
            { sentStatus === null && <p>Don't worry your email will be verified here...</p> }
            {
                sendingMail === false &&
                sentStatus !== 'success' &&
                <button className='mail-send-cta'
                        onClick={()=>handleVerificationMailSending()}
                >
                    Send verification mail
                </button>
            }
            {
                sendingMail === true && 
                <CircularProgress size={24} style={{ color:'#FFE74C' }}/>
            }
            {
                sentStatus === 'success' && <p>We have sent you a mail on the given mail address</p>
            }
            {
                sentStatus === 'fail' && <p>There seems to be a problem. Try again in a few minutes.</p>
            }
        </div>
    )
}