/* eslint-disable react-hooks/exhaustive-deps */
// styles
import { useEffect, useState } from 'react';
import './userOnboarding.css';
import { useOnboardingUser } from '../../../Hooks/useOnboardingUser';
import { CircularProgress } from '@mui/material';
import { useCheckUsernameAvailibility } from '../../../Hooks/useCheckUsernameAvailibility';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
// import UsernameCreator from '../UsernameCreationComponent/usernameCreator';

import GeneralLoadingPage from '../../../Pages/LoadingPages/generalLoadingPage';
import DateOfBirthField from './DateOfBirthField/dateOfBirthInput';

export default function UserOnboarding({ data }){

    // console.log('data => ',data);

    const [userName, setUserName] = useState('');

    // for birthdate field.
    // const currentDate = new Date().toISOString().split("T")[0];
    const [birthdate, setBirthDate] = useState('');
    // console.log('currentDate => ',currentDate);
    
    // console.log('birthdate = ',birthdate)

    const { onboardingProgress, onboardingError, getUserOnboard } = useOnboardingUser();

    const startOnboarding = ()=>{
        if(userName.length === 0 || birthdate.length === 0){
            return;
        }
        else if(isAvailable === false){
            return;
        }
        else{
            getUserOnboard(userName, birthdate);
        }
    }

    const { isAvailable, checking, checkAvailibility } = useCheckUsernameAvailibility();

    useEffect(()=>{
        checkAvailibility(userName);
    },[userName])

    // const [openUsernameCreator, setOpenUsernameCreator] = useState(false);
    // const handleOpenUsernameCreator = ()=>{
    //     setOpenUsernameCreator(true);
    // }
    // const handleCloseUsernameCreator = ()=>{
    //     setOpenUsernameCreator(false);
        
    // }

    // console.log(birthdate);

    return(
        <>
            {
                data === null &&
                <GeneralLoadingPage/>
            }
            <div className="user-onboarding-container">
                {/* <Dialog
                    open={openUsernameCreator}
                    maxWidth={'md'}
                    fullWidth={true}
                >
                    <UsernameCreator closeDialogPrompt={handleCloseUsernameCreator} 
                                    userNamePrompt={setUserName}
                                    fromPage={'user_onboarding'}
                    />
                </Dialog> */}
                <p>Just a short introduction...</p>
                <div className="onboarding-form">
                    <div className="user-name-section">
                        <input type="text"
                            className='onboarding-username-text-field'
                            placeholder='Create a username for yourself'
                            value={userName}
                            onChange={(e)=>{
                                setUserName(e.target.value)
                                // checkAvailibility(e.target.value)
                            }}
                        />
                        {
                            checking && <CircularProgress size={16} style={{ color:'#FFE74C' }}/>
                        }
                        {
                            isAvailable === true &&
                                <CheckCircleIcon
                                    style={{ 'fontSize':'24px', 'color':'green'}}
                                />
                        }
                        {
                            isAvailable === false && 
                                <p className='not-available-prompt'>{'Not available'}</p>
                        }
                        {
                            isAvailable === null && <></>
                        }
                    </div>
                    {/* <div className="check-availibility-container"> */}
                        {/* <p className='availibility-button'
                            onClick={()=>handleOpenUsernameCreator()}
                        >
                            Try our senseless username creator!
                        </p> */}
                    {/* </div> */}
                    <DateOfBirthField setDOB={setBirthDate}/>
                    {
                        onboardingProgress === false 
                        &&
                        <button
                            className='onboarding-cta'
                            onClick={()=>startOnboarding()}
                        >
                            Continue
                        </button>
                    }
                    {
                        onboardingProgress === true &&
                        <CircularProgress size={24} style={{ color:'#FFE74C' }}/>
                    }
                    {
                        onboardingError && <p style={{ color:'#f8f8f8', fontSize:'0.9rem', fontWeight:'700' }}>{onboardingError}</p>
                    }
                </div>
            </div>
        </>
    )
}